import React, { useState, useEffect } from "react";
import PartnerManagerTemplate from "../PartnerManager/PartnerManagerTemplate/PartnerManagerTemplate.jsx";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utility/common/api";

function PartnerCompDateReq() {
  const params = useParams();
  const [reportGenReqData, setReportGenReqData] = useState(null);
  const ID = sessionStorage.getItem("id");
  useEffect(() => {
    if (ID) {
      const url = `/api/approvaldisplay/${ID}`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data) {
            setReportGenReqData(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <div>
      {reportGenReqData && (
        <PartnerManagerTemplate
          showBlackHeadArrow={true}
          heading={`Request No. ${ID}`}
          subHead={"Report Generation"}
          nav1={"Dashboard"}
          nav2={`Request No. ${ID}`}
          nav1col={"navGreen"}
          nav2col={"navBlack"}
          showPeerSet={true}
          showUploadBtn={true}
          show3Btns={true}
          showSent={true}
          showSampling={true}
          showAnchor={true}
          showVertLine6={true}
          managerTimeline={true}
          showIPField={true}
          showDownloadSampleFile={true}
          data={reportGenReqData}
          reqNo={ID}
          showPeersetDownload={false}
        />
      )}
    </div>
  );
}

export default PartnerCompDateReq;
