import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./peerset4.scss";
import "./Peerset.scss";
import PeerHead from "./PeerHead.jsx";
import { Typography } from "@mui/material";
import ddsicon1 from "./Images/dds-icon1.svg";
import ddsicon2 from "./Images/dds-icon2.svg";
import editButton from "./Images/editButton.svg";
import Peerset2 from "./Peerset2";
import api from "../../utility/common/api";
import moment from "moment";

const getCreatedDate = (d) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
};
function Peerset4() {
  const navigate = useNavigate();
  const navigateToCreatePeer1 = () => {
    navigate("/Peerset2");
  };
  const [peerGroupData, setPeerGroupData] = useState([]);
  useEffect(() => {
    const url = "/api/peerset/list";
    api
      .get({ url })
      .then(({ data }) => {
        if (data && data.length > 0) {
          setPeerGroupData(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onClickGenerateReport = (id, parentId) => {
    sessionStorage.setItem("id", id);
    sessionStorage.setItem("parentID", parentId);
    navigate("/reports");
  };

  return (
    <div>
      <PeerHead peerHeadCont={"Peer Sets"} />
      <div className="peerGroupSets">
        <div id="peerGroupSet1">
          {peerGroupData &&
            peerGroupData.length > 0 &&
            peerGroupData.map((elem, k) => (
              <div className="peerGroups" key={k}>
                <div className="innerPeerGroup">
                  <div className=" ">{elem.peerSetGroupName} Peer Group</div>
                  <div className="peerGroupStatus">
                    {elem.approvalRest.approvalStatus}
                  </div>
                  <div className="peerGroupNum">
                    Peer Set :
                    {elem.peersetComapnyRest.map((e) => (
                      <span key={e.comapanyName}> {e.comapanyName}, </span>
                    ))}
                  </div>
                  <div className="peerGroupNum">
                    Request Created on :{" "}
                    {
                      <span className="peerGroupTime">
                        {getCreatedDate(new Date(elem.createdTime))}
                      </span>
                    }{" "}
                  </div>
                  {elem.approvalRest.approvalStatus == "APPROVED" && (
                    <>
                      <button
                        className="generateRep"
                        onClick={() =>
                          onClickGenerateReport(
                            elem.approvalRest.id,
                            elem.approvalRest.parentId
                          )
                        }
                      >
                        Generate Report
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      <button id="peer4CreatePeer" onClick={navigateToCreatePeer1}>
        Create Peer Sets
      </button>
      <button id="peer4ReturnHome" onClick={() => navigate("/dashboard")}>
        Return Home
      </button>
    </div>
  );
}

export default Peerset4;
