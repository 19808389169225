import { Typography } from "@mui/material";
import React, { useState } from "react";
import FirstContent from "../../../FirstContent/firstContent";
import OnboardFormSteps from "../../OnboardFormSteps/OnboardFormSteps";
import "./incumbentSubmission.scss";
import MasButton from "../../../MasButton/masButton";
import CrossIcon from "../../../../assets/images/blackCrossIcon.png";
import PdfSymbol from "../../../../assets/images/pdfSymbol.png";
import UploadFile from "../../../../assets/images/uploadFile.png";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import api from "../../../../utility/common/api";
import { useLocation, useNavigate } from "react-router-dom";
import SampleFile from "../../../../assets/sample-data/SampleFile.csv";
import { useDispatch } from "react-redux";
import { uploadCompensationAction } from "../../../../store/actions/global";
import { getTranslation } from "../../../../utility/i18n/i18n";

const IncumbentSubmission = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState("");
  const [refName, setRefName] = useState("");
  var title = "";
  var type = "";
  var maxFileLength = 25;
  const navigate = useNavigate();
  if (useLocation().pathname.includes("uploadSalary")) {
    title = "Upload Salary Structure";
    type = "SALARY";
  } else {
    title = "Incumbent Wise Data Submission";
    type = "INCUMBENT";
  }

  const onDropHandler = (ev) => {
    ev.preventDefault();
    let file = "";
    if (ev.dataTransfer.items) {
      file =
        [...ev.dataTransfer.items]
          .find((item) => item.kind === "file")
          .getAsFile() ;
    } else {
      file = ev.dataTransfer.files[0];
    }
    uploadFile(file.name,file)
  };

  const onDragOver = (ev) => ev.preventDefault();

  const uploadFile = (name,file)=>{
    setFile(name);

    const url = "/api/file";
    let data = new FormData();
    data.append("file", file);
    api.post({ url, data })
      .then(({ data }) => {
        if (data) {
          setRefName(data.refName);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const uploadFileHandler = (event) => {
    let input = event.target.value;
    let fileName = input.split("\\");
    uploadFile(fileName[fileName.length - 1],event.target.files[0])
  };
  const handleSubmit = () => {
    if (refName) {
      dispatch(uploadCompensationAction({ open: false }));
      const data = {
        refName: refName,
        type: type,
      };
      const url = "/api/compscale";
      api
        .post({ url, data })
        .then(({ data }) => {
          navigate("/dashboard");
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("File not uploaded. Please wait");
    }
  };
  return (
    <div>
      <div className="incumbent-onboard-Title">
        <FirstContent
          header="Upload Firms Compensation Details"
          description={getTranslation("incumbent")["description1"]}
        />
      </div>
      <div className="incumbent-onboard-form-area">
        <div className="Incumbent-onboard-form">
          <div className="upload-salary-form">
            <Typography
              variant="body1"
              className="upload-salary-form-header font2"
            >
              {title}
            </Typography>
            <Typography variant="caption2" className="font7">
            {getTranslation("incumbent")["description2"]}
            </Typography>
            <a href={SampleFile} download className="download-sample-file">
              <MasButton
                download={true}
                buttonName="Download Sample File"
                type="outlined-button normal greenColor download font8"
              />
            </a>
            <div>
      
      <div style={{marginTop: 20}}></div>
      <div id="drop_zone" className="font4" onDrop={onDropHandler} onDragOver={onDragOver}>
        <div>Drag and drop your file here</div>
        <div> or </div>
        <label htmlFor={"file_picker"} className="fp-label">
          Select file
        </label>
        <input
          id="file_picker"
          name="myfile"
          type="file"
          accept=".csv"
          onChange={(ev) => {uploadFileHandler(ev)
          }}
          style={{display: "none"}}
        ></input>
        <Typography
                variant="caption2"
                style={{ marginLeft: "1.5vw" }}
                className="font4"
              >
                CSV. Max size of 20Mb
              </Typography>
      </div>
    </div>
            {file !== "" ? (
                <>
                  <div className="incumbent-uploaded-file-name">
                    <img
                      src={PdfSymbol}
                      className="incumbent-pdf-image-upload"
                    />
                    <Typography variant="body2" title={file} className="font8">
                      {file.length < maxFileLength ? (
                        <>{file}</>
                      ) : (
                        <>{file.slice(0, maxFileLength) + "....."}</>
                      )}
                    </Typography>
                    <div style={{ marginLeft:"auto", paddingBottom: "0.55vh" }}>
                      <img
                        src={CrossIcon}
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          width: "1.1vw",
                        }}
                        onClick={() => setFile("")}
                      />
                    </div>
                  </div>
                  {refName && (
                    <>
                      <Typography
                        variant="caption2"
                        color={"#26890D"}
                        className="font7"
                      >
                        Successfully uploaded
                      </Typography>
                    </>
                  )}
                </>
              ) : null}

            <div style={{ display: "flex", gap: "1.2vw" }}>
              <div onClick={handleSubmit}>
                <button
                  className="font8 onboarding-submit-help-btns"
                  style={{
                    backgroundColor: "#26890D",
                    border: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncumbentSubmission;
