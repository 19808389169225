import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";

import React, { useState, useEffect } from "react";
import "./mainReportView.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Download from "../../../../assets/images/greenDownload.png";
// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';
import ReactToPrint, { useReactToPrint, html2pdf } from "react-to-print";
import { useRef } from "react";
import api from "../../../../utility/common/api";
import {
  convertFromHTML,
  ContentState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const MainReportView = ({ arrow, reportsArray }) => {
  const elmRef = useRef(null);
  const [commentByManager, setcommentByManager] = useState("");
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
    documentTitle: `${reportsArray[arrow].name}.pdf`,
  });
  const [html, setHtml] = useState("");
  const [commentId, setCommentId] = useState(0);

  const ref = useRef(null);

  const handleBlur = () => {
    ref.current.save();
  };

  const handleSave = (data) => {
    setHtml(RTEtoHtml(data));
  };
  function htmlToRTE(html) {
    const contentHTML = convertFromHTML(html || "");
    const contentState = ContentState.createFromBlockArray(
      contentHTML.contentBlocks,
      contentHTML.entityMap
    );
    return JSON.stringify(convertToRaw(contentState));
  }
  function RTEtoHtml(data) {
    return stateToHTML(convertFromRaw(JSON.parse(data)));
  }
  useEffect(() => {
    if (reportsArray.length > 0) {
      const url = `/api/conversion/${reportsArray[arrow].component.props.primKey}/REPORT/${reportsArray[arrow].key}`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data) {
            setcommentByManager(data.details);
            setHtml(data.details);
            setCommentId(data.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [arrow]);
  //const doc = new jsPDF();
  // const getColor=()=>{
  //     const name = reportsArray && reportsArray.length>0 && reportsArray[arrow].name;
  //     if(name && name === 'CompetitivePositioning'){
  //         return [0, 79, 89];
  //     }
  //     else if(name && name === 'CompensationAnalysis'){
  //         return [183, 221, 226];
  //     }
  //     else if(name && name === 'EmployeeWiseAnalysis'){
  //         return [0, 79, 89];
  //     }
  // };

  //const handleGeneratePdf = () => {
  // autoTable(doc, { html: elmRef.current.querySelector('table:first-child'),
  // styles : { halign : 'center'},
  // headStyles: {fillColor: getColor()},
  // });
  //};
  const onSubmit = () => {
    let url, data;
    if (commentId == 0) {
      url = "/api/conversion";
      data = {
        // "details":commentData,
        details: html,
        type: "REPORT",
        refId: reportsArray[arrow].component.props.primKey,
        chartType: reportsArray[arrow].key,
      };
      api
        .post({ url, data })
        .then(({ data }) => {
          if (data) {
            alert("Comments added");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      url = `/api/conversion/${commentId}`;
      data = {
        details: html,
      };
      api
        .patch({ url, data })
        .then(({ data }) => {
          if (data) {
            alert("Comments added");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div className="mainReport-section">
      <div className="mainReport-download-button">
        <img
          src={Download}
          style={{ width: "3vw", cursor: "pointer" }}
          onClick={handlePrint}
        />
      </div>
      <div className="mainReport-text-data-block">
        <div style={{ width: "100%" }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body3" fontSize={"1.09vw"}>
                Comments:
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {localStorage.getItem("userType") == "MANAGER" ? (
                <>
                  <MUIRichTextEditor
                    label="Start typing..."
                    onBlur={handleBlur}
                    onSave={handleSave}
                    defaultValue={htmlToRTE(html)}
                    ref={ref}
                  />

                  <button id="commentSubmitButton" onClick={onSubmit}>
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <Typography variant="caption2" fontSize={"1.09vw"}>
                    <td
                      fontSize={"1.09vw"}
                      dangerouslySetInnerHTML={{ __html: commentByManager }}
                    />
                  </Typography>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={{ width: "100%" }} ref={elmRef}>
          {reportsArray[arrow].component}
        </div>
      </div>
    </div>
  );
};

export default MainReportView;
