import * as React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { standardDateFormat } from '../../utility/common/index';
import MasTableHead from '../MasTableHead/TableHead';
import usePagination from '../MasPagination/usePagination';
import MasPagination from "../MasPagination/index";
import './styles.scss'
import { getSortData, sortTable } from '../../utility/common/index'
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { paperClasses } from '@mui/material/Paper';
import dw1 from '../../modules/PartnerManager/PartnerManagerRightContent/Images/dw1.svg';
import dw2 from '../../modules/PartnerManager/PartnerManagerRightContent/Images/dw2.svg';
import api from '../../utility/common/api';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Paper,
    Checkbox,
} from '@mui/material';
import { getColorScheme } from '@mui/system/cssVars/useCurrentColorScheme';
import { Link, useNavigate } from "react-router-dom";

const MasTable2 = ({
    mainHeadCell,
    rows,
    headCells,
    isCheckbox,
    colorProperties,
    isPagination,
    showPagination,
    rowCount,
    styledClass,
    returnPageNo,
    showAllWhiteShade,
    stickyHeader,
    maxHeight,
    isManagerDashBoard,
    isPartnerDashBoard
}) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [_page, set_Page] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(7);


    const count = Math.ceil(rowCount / 10);
    const rowsData = usePagination(rows, 10);

    const navigate = useNavigate();

    const StyledPaper = styled(Paper)(({ theme }) => ({
        [`&.${paperClasses.root}`]: {
            boxShadow: 'none',
        }
    }));
    const downloadData = (value) => {
        const url = `/api/file/${value}?storgelocation=CONSUMED`;
        api
            .get({ url })
            .then(({ data }) => {
                if (data) {
                    const temp = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = temp;
                    link.setAttribute("download", value.split(/_(.*)/s)[1]);
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontFamily: "SourceSansProBold",
            fontStyle: "normal",
            fontSize: "1vw",
            lineHeight: "1vw",
            margin: "0",
            fontWeight: "400",
            padding: '0 10px',
            height: "3.5vw",
        },
        [`&.${tableCellClasses.head}:hover`]: {
            color: '#53565A',
        },
        [`&.${tableCellClasses.body}`]: {
            fontFamily: "SourceSansProRegular",
            fontStyle: "normal",
            fontSize: "1.1vw",
            lineHeight: "1.5vw",
            margin: "0",
            fontWeight: "400",
            paddingLeft: '10px',
            borderRadius: '0px',
            borderBottom: "1px solid #E3E4E4",
            height: "2.5vw"
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: !showAllWhiteShade && theme.palette.action.hover,
            cursor: "pointer"
        },
        '&:nth-of-type(even)': {
            cursor: "pointer"
        },
        '&:last-child td, &:last-child th': {
            borderBottom: "1px solid #53565A",
            cursor: "pointer"
        },
    }));

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.applicationName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, applicationName) => {
        const selectedIndex = selected.indexOf(applicationName);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, applicationName);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (applicationName) => selected.indexOf(applicationName) !== -1;

    const handleRowClicked = (row) => {

        const requestType = row.requestType.toLowerCase();
        if (isManagerDashBoard) {
            sessionStorage.setItem("parentID", row.parentId);
            sessionStorage.setItem("id", row.requestNo);

            if (requestType === 'peer set') {
                navigate(`/managerPeerReq/${row.requestNo}`)
            }
            else if (requestType === 'onboarding comp data') {
                navigate(`/managerCompDateReq/${row.requestNo}`);
            }
            else if (requestType === 'report request') {
                navigate(`/managerReportGenReq`);
            }
            else if (requestType === 'dipstick survey') {
                navigate(`/managerDipstickSurvey/${row.parentId}`);
            }
        }

    }

    const createRow = (row, index, headCells) => {
        const columns = headCells;
        const isItemSelected = isSelected(row[columns[0].id]);
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
            isCheckbox ? (
                <StyledTableRow
                    hover
                    onClick={(event) => handleClick(event, row[columns[0].id])}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${row[columns[0].id]}${index}`}
                    selected={isItemSelected}
                >
                    <StyledTableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                        />
                    </StyledTableCell>
                    {createRowCell(row, columns, index)}
                </StyledTableRow>
            ) : (
                <StyledTableRow
                    hover
                    tabIndex={-1}
                    key={`${row[columns[0].id]}${index}`}
                    onClick={() => { handleRowClicked(row) }}
                >
                    {createRowCell(row, columns, index)}
                </StyledTableRow>
            )
        )

    }


    const createRowCell = (row, columns, index) => {
        const labelId = `enhanced-table-checkbox-${index}`;
        const rowAction = columns.map((column, columnIndex) => { })
        const rowSpanValue = row.rowSpan
        const headervalue = Object.values(row)[0]
        row.background && console.log(colorProperties[row.background[4]], row.background[4], "rowColor")
     console.log(row.filename)
        return (
            <>
                {columns.map((column, columnIndex) => {
                    const colCell = column.renderCell
                        ? column.renderCell(row)
                        : (column.isDate && row[column.id])
                            ? standardDateFormat(row[column.id])
                            : row[column.id]?.toString().replace(/,/g, ', ');
                    return columnIndex === 0 ? headervalue ? headervalue === "none" ? (
                        <>
                            <StyledTableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                key={`${labelId + columnIndex}`}
                                align={column.align || 'left'}
                                colSpan={column.colSpan}
                                rowSpan={rowSpanValue}
                                sx={{ background: row.background ? row.background[columnIndex] ? colorProperties[row.background[columnIndex]] : "#FFFFFF" : "#FFFFFF" }}>
                            </StyledTableCell>
                        </>
                    ) : column.id === "requestNo" ? (
                        <>
                            <StyledTableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                key={`${labelId + columnIndex}`}
                                align={column.align || 'left'}
                                colSpan={column.colSpan}
                                rowSpan={rowSpanValue}
                                sx={{ color: "#000000" }}
                            >
                                <a herf="#" onClick={(e) => e.preventDefault()}>{colCell}</a>
                                {/* <Link to="/partnerCompDataReq">{colCell}</Link> */}
                            </StyledTableCell>
                        </>
                    ) : (
                        <>
                            <StyledTableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                key={`${labelId + columnIndex}`}
                                align={column.align || 'left'}
                                colSpan={column.colSpan}
                                rowSpan={rowSpanValue}
                                sx={{ color: "#000000" }}
                            >
                                {colCell}
                            </StyledTableCell>
                        </>
                    ) : null : (
                        <>
                            {row.completed ==true ? (<StyledTableCell
                                sx={{
                                    background: row.background ? row.background[columnIndex] ? colorProperties[row.background[columnIndex]] : "#FFFFFF" : "#FFFFFF",
                                    color: row.color ? row.color[columnIndex] ? colorProperties[row.color[columnIndex]] : "#000000" : "#000000"
                                }}
                                key={`${labelId + columnIndex}`} colSpan={column.colSpan} align={column.align || 'left'}>
                                <div>
                                    <a download className="download-sample-file" onClick={() => downloadData(row.filename)}>
                                        <button id="download-file" >
                                            Download
                                            <div id="download-icon">
                                                <img src={dw1} id="partnerCompDw1" />
                                                <img src={dw2} id="partnerCompDw2" />
                                            </div>
                                        </button>
                                    </a>
                                </div>
                            </StyledTableCell>
                            ) : (
                                <StyledTableCell
                                    sx={{
                                        background: row.background ? row.background[columnIndex] ? colorProperties[row.background[columnIndex]] : "#FFFFFF" : "#FFFFFF",
                                        color: row.color ? row.color[columnIndex] ? colorProperties[row.color[columnIndex]] : "#000000" : "#000000"
                                    }}
                                    key={`${labelId + columnIndex}`} colSpan={column.colSpan} align={column.align || 'left'}>
                                    File Processing
                                </StyledTableCell>
                            )


                            }

                        </>
                    );
                })}
            </>
        );
    }

    const handlePageChange = (e, p) => {
        returnPageNo(p);
        set_Page(p);
    };

    const getRowData = () => {
        if (isPagination) {
            return sortTable(rows, getSortData(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                    return createRow(row, index, headCells);
                })
        }
        else if (showPagination && rowCount > 10) {
            return sortTable(rows, getSortData(order, orderBy))
                .map((row, index) => {
                    return createRow(row, index, headCells);
                })
        }
        else {
            return sortTable(rows, getSortData(order, orderBy))
                .map((row, index) => {
                    return createRow(row, index, headCells);
                })
        }
    };

    return (
        <Box className={`${styledClass} tableContainer`}>
            <StyledPaper sx={{ width: '100%', mb: 1 }}>
                <TableContainer sx={stickyHeader && { maxHeight: maxHeight }}>

                    <Table
                        aria-labelledby="tableTitle"
                        stickyHeader
                    >


                        <MasTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                            mainHeadCell={mainHeadCell}
                            StyledTableCell={StyledTableCell}
                            isCheckbox={isCheckbox}
                            colorProperties={colorProperties}
                        />

                        <TableBody>
                            {getRowData()}
                        </TableBody>
                    </Table>
                </TableContainer>
                {isPagination &&
                    <TablePagination
                        rowsPerPageOptions={10}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />}
            </StyledPaper>
            {showPagination && rowCount > 10 &&
                <div className="pagination">
                    <MasPagination
                        count={count}
                        size="large"
                        page={_page}
                        variant="outlined"
                        onChange={handlePageChange}

                    />
                </div>}
        </Box>
    );
}

MasTable2.defaultProps = {
    isCheckbox: false,
    isPagination: false,
    styledClass: PropTypes.func,
    isManagerDashBoard: false,
    isPartnerDashBoard: false
}
MasTable2.propTypes = {
    isCheckbox: PropTypes.bool,
    isPagination: PropTypes.bool,
    rows: PropTypes.array.isRequired,
    headCells: PropTypes.array.isRequired,
    styledClass: PropTypes.func,
};
export default MasTable2;