import React, { useState } from "react";
import ArrowContent from "../PartnerManagerArrowContent/ArrowContent";
import RightContent from "../PartnerManagerRightContent/RightContent";
import FirstContent from "../PartnerManagerFirstContent/FirstContent";
import LeftContent from "../PartnerManagerLeftContent/LeftContent";
import TimelineContent from "../PartnerManagerTimelineContent/TimelineContent";
import vertLine3 from "./Images/vertLine3.svg";
import bigCircle from "./Images/bigCircle.svg";
import smallCircle from "./Images/smallCircle.svg";
import "../PartnerManagerMisc.scss";

function PartnerManagerTemplate(props) {
  const vert3 = (
    <div className="imgColumn">
      <img src={bigCircle} id="bigCirc" />
      <img src={smallCircle} id="smallCirc" />
      <img src={vertLine3} id="vertLine3" className="vertLine" />
    </div>
  );

  const vert6 = (
    <div className="imgColumn">
      <img src={bigCircle} id="bigCirc" />
      <img src={smallCircle} id="smallCirc" />
      <img src={vertLine3} id="vertLine6" className="vertLine" />
    </div>
  );

  const vert5 = (
    <div className="imgColumn">
      <img src={bigCircle} id="bigCirc" />
      <img src={smallCircle} id="smallCirc" />
      <img src={vertLine3} id="vertLine5" className="vertLine" />
    </div>
  );

  return (
    <div className="first-content-style">
      <FirstContent
        showGreenHeadArrow={props.showGreenHeadArrow}
        showBlackHeadArrow={props.showBlackHeadArrow}
        heading={props.heading}
        nav1={props.nav1}
        nav2={props.nav2}
        nav3={props.nav3}
        nav1col={props.nav1col}
        nav2col={props.nav2col}
        nav3col={props.nav3col}
        nav4col={props.nav4col}
      />
      <div id="partnerManagerCompBox">
        <div id="partnerManagerCompHeading">
          <ArrowContent
            reqNumber={props.heading}
            reqPeersetDate={props.data.userRest.createdTime}
            reqSubHead={props.subHead}
          />
          <RightContent
            showExecuting={props.showExecuting}
            showSent={props.showSent}
            showDownload={props.showDownload}
            data={props.data}
            showDownloadSampleFile={props.showDownloadSampleFile}
            showPeersetDownload={props.showPeersetDownload}
            
          />
        </div>
        <div>
          <hr className="ln" />
        </div>
        <div id="partnerManagerMainContent">
          <LeftContent
            showPeerSet={props.showPeerSet}
            show3Btns={props.show3Btns}
            show2Btns={props.show2Btns}
            showSampling={props.showSampling}
            showAnchor={props.showAnchor}
            showUploadBtn={props.showUploadBtn}
            summaryData={props.data}
            showApprover={props.showApprover}
            reqNo={props.reqNo}
            primKey={props.data.approvalRest.parentId}
          />
        </div>
      </div>
    </div>
  );
}

export default PartnerManagerTemplate;
