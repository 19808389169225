import React, { useState, useEffect } from "react";
import "./ManagerDashboard.scss";
import MasSelect from "../../domain-component/MasSelect";
import MasContainer from "../../domain-component/MasContainer";
import MasTextField from "../../domain-component/MasTextField";
import { Breadcrumbs, Link, Typography, Grid } from "@mui/material";
import MasLinkButton from "../../domain-component/MasLinkButton";
import Table from "../../domain-component/MasTable/Table";
import icon from "../../modules/ManagerPersona/Images/manager-icon.svg";
import api from "../../utility/common/api";
import { useNavigate } from "react-router-dom";

const ManagerDashboard = () => {
  var requestDictionary = {
    DIPSTICK_SURVEY: "Dipstick Survey",
    USER: "Onboarding Request",
    PEERSET: "Peer Set",
    REPORT_GENERATION: "Report Request",
    ONBOARDING_COMP_DATA: "Onboarding Comp Data",
  };
  var statusDictionary = {
    PENDING: "Unassigned",
    EXECUTING: "Executing",
    DECLINED: "Declined",
    APPROVED: "Approved",
  };
  const [rows, setRows] = useState([]);
  const [requestCount, setRequestCount] = useState(0);
  const [reportRequestCount, setReportRequestCount] = useState(0);
  const [executionQueueCount, setExecutionQueueCount] = useState(0);
  const [deliveredCount, setDeliveredCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const getCreatedDate = (d) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear()
    );
  };
  useEffect(() => {
    const url = "/api/dashboard?size=10&offSet=0";
    sessionStorage.removeItem("parentID");
    sessionStorage.removeItem("id");
    api
      .get({ url })
      .then(({ data }) => {
        if (data) {
          var dataApprovals = [];
          setRequestCount(data.totalCount);
          setReportRequestCount(data.totalReportsUnassignedCount);
          setExecutionQueueCount(data.totalUnassignedCount);
          setDeliveredCount(data.totalReportsSentCount);
          setDataCount(data.totalApprovalsCount);
          if (data.approvals.length > 0) {
            data.approvals.forEach((el) => {
              dataApprovals.push(
                createData(
                  el.parentId,
                  el.id,
                  requestDictionary[el.approvalType],
                  el.companyName,
                  getCreatedDate(new Date(el.createdTime)),
                  el.assignedNames,
                  statusDictionary[el.approvalStatus]
                )
              );
            });
          }
          setRows(dataApprovals);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handlePageChange = (p) => {
    const url = `/api/dashboard?size=10&offSet=${p - 1}`;
    api
      .get({ url })
      .then(({ data }) => {
        if (data) {
          var dataApprovals = [];
          if (data.approvals.length > 0) {
            data.approvals.forEach((el) => {
              dataApprovals.push(
                createData(
                  el.parentId,
                  el.id,
                  requestDictionary[el.approvalType],
                  el.companyName,
                  getCreatedDate(new Date(el.createdTime)),
                  el.assignedNames,
                  statusDictionary[el.approvalStatus]
                )
              );
            });
          }
          setRows(dataApprovals);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const colorProperties = {
    headCellsBackground: "#b7dde2",
    headCellsColor: "#53565A",
    overallBackground: "#E3E4E4",
  };
  const navigate = useNavigate();
  const headCells = [
    {
      id: "requestNo",
      label: "Request No.",
      disableSorting: false,
    },
    {
      id: "requestType",
      label: "Request Type",
      disableSorting: false,
    },
    {
      id: "companyName",
      label: "Company Name",
      disableSorting: false,
    },
    {
      id: "requestDate",
      label: "Request Date",
      disableSorting: false,
    },
    {
      id: "requestAssignedTo",
      label: "Request Assigned To",
      disableSorting: true,
    },
    {
      id: "status",
      label: "Status",
      disableSorting: false,
    },
  ];

  function createData(
    parentId,
    requestNo,
    requestType,
    companyName,
    requestDate,
    requestAssignedTo,
    status
  ) {
    return {
      parentId,
      requestNo,
      requestType,
      companyName,
      requestDate,
      requestAssignedTo,
      status,
    };
  }

  return (
    <div className="container-partner-dasboard">
      <div>
        <h1 className="mas-text-h1">Dashboard</h1>
      </div>
      <div className="card-section">
        <div className="card-item">
          <div className="font3 card-header1">Total Requests</div>
          <div className="font1">{requestCount}</div>
        </div>
        <div className="card-item">
          <div className="font3 card-header2">Unassigned requests</div>
          <div className="font1">{executionQueueCount}</div>
        </div>
        <div className="card-item">
          <div className="font3 card-header3">Pending report requests</div>
          <div className="font1">{reportRequestCount}</div>
        </div>
        <div className="card-item">
          <div className="font3 card-header4">Report request sent</div>
          <div className="font1">{deliveredCount}</div>
        </div>
      </div>
      <div>
        <div className="font2">
          Onboarding Data / Report / Peer Set Requests{" "}
        </div>
        <div className="filter-section2">
          <Grid container alignItems="flex-end" justifyContent={"left"}>
            <Grid item xs={12} sm={12} md={12}>
              <Table
                headCells={headCells}
                rows={rows}
                link={true}
                rowCount={dataCount}
                stickyHeader="true"
                colorProperties={colorProperties}
                showAllWhiteShade={false}
                isManagerDashBoard={true}
                returnPageNo={handlePageChange}
                showPagination={true}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ManagerDashboard;
