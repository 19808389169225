import { Component, useState, useEffect } from "react";
import "./loginExt.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import login from "../../assets/images/Login.svg";
import line from "../../assets/images/Line.svg";
import info from "../../assets/images/info.svg";
import Link from "@mui/material/Link";
import welcomeImage from "../../../src/assets/images/Welcome2.svg";
import backarrow from "../../assets/images/BackArrow.svg";
import MasTextField from "../../domain-component/MasTextField";
import api from "../../utility/common/api";
import isFirstDayOfMonth from "date-fns/esm/fp/isFirstDayOfMonth/index";
import { getTranslation } from "../../utility/i18n/i18n";
const RequestLoginAccess = () => {
  const [formValues, setFormValues] = useState({});
  const [messageIndex, setMessageIndex] = useState(0);
  const [errorValues, setErrorValues] = useState({
    username: null,
    email: null,
    contact: null,
    companyname: null,
    designation: null,
    password: null,
  });
  const navigate = useNavigate();
  useEffect(() => {
    const startInterval = setInterval(() => {
        setMessageIndex((t) => {
          if(t>=2){
            return 0
          }
          else{
            return t+1
          }
        });
    }, 5000);
    return () => {
      clearInterval(startInterval);
    };
  }, []);
  const handleLoginRequest = async () => {
    if (
      Object.keys(formValues).length === 7 &&
      !Object.values(formValues).includes("")
    ) {
      if (formValues["password"] != formValues["confirmPassword"]) {
        setErrorValues({
          ...errorValues,
          ["password"]: "*Password must match",
        });
      } else {
        setErrorValues({
          username: null,
          email: null,
          contact: null,
          companyname: null,
          designation: null,
          password: null,
        });
        const data = {
          userName: formValues["username"],
          companyEmailId: formValues["email"],
          companyName: formValues["companyname"],
          organizationDesignation: formValues["designation"],
          contactNumber: formValues["contact"],
          password: formValues["password"],
        };
        const url = "/api/public/signup";
        await api
          .post({ url, data })
          .then(({ data }) => {
            if (data) {
              navigate("/login-request-acknowledged");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      if (
        !Object.keys(formValues).includes("password") ||
        formValues["password"] == ""
      ) {
        setErrorValues({ ...errorValues, ["password"]: "*Enter password" });
      }
      if (
        !Object.keys(formValues).includes("designation") ||
        formValues["designation"] == ""
      ) {
        setErrorValues({
          ...errorValues,
          ["designation"]: "*Enter designation",
        });
      }
      if (
        !Object.keys(formValues).includes("companyname") ||
        formValues["companyname"] == ""
      ) {
        setErrorValues({
          ...errorValues,
          ["companyname"]: "*Enter company name",
        });
      }
      if (
        !Object.keys(formValues).includes("contact") ||
        formValues["contact"] == ""
      ) {
        setErrorValues({
          ...errorValues,
          ["contact"]: "*Enter valid contact details",
        });
      }
      if (
        !Object.keys(formValues).includes("email") ||
        formValues["email"] == ""
      ) {
        setErrorValues({ ...errorValues, ["email"]: "Enter valid email id" });
      }
      if (
        !Object.keys(formValues).includes("username") ||
        formValues["username"] == ""
      ) {
        setErrorValues({ ...errorValues, ["username"]: "*Enter username" });
      }
    }
  };
  const handleLogin = () => {
    navigate("/login-external");
  };
  const getFormValues = (event) => {
    if (event.target.name === "email") {
      const regEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regEmail.test(event.target.value)) {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value,
        });
        setErrorValues({ ...errorValues, ["email"]: null });
      } else {
        setErrorValues({
          ...errorValues,
          [event.target.name]: "*Enter valid email ID",
        });
      }
    } else if (event.target.name == "contact") {
      const regContact = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      if (regContact.test(event.target.value)) {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value,
        });
        setErrorValues({ ...errorValues, ["contact"]: null });
      } else {
        setErrorValues({
          ...errorValues,
          [event.target.name]: "*Enter valid contact number",
        });
      }
    } else {
      setFormValues({ ...formValues, [event.target.name]: event.target.value });
      setErrorValues({ ...errorValues, [event.target.name]: null });
    }
  };

  return (
    <div className="container">
      <div className="login-about-container">
        <div className="font1"> Welcome to CompScale !!</div>
        <div className="login-ext-image">
          <img src={welcomeImage} width="100%" height="100%"></img>
        </div>
        <div className="font3 ">
          {getTranslation("login-external")["description"][messageIndex]}
        </div>
        <div className="login-abt-sl">
          <span className={(messageIndex == 0 ? "login-abt-sl1" : "login-abt-sl2")} onClick={()=>{setMessageIndex(0)}}></span>
          <span className={(messageIndex == 1 ? "login-abt-sl1" : "login-abt-sl2")} onClick={()=>{setMessageIndex(1)}}></span>
          <span className={(messageIndex == 2 ? "login-abt-sl1" : "login-abt-sl2")} onClick={()=>{setMessageIndex(2)}}></span>
        </div>
     
      </div>
      <div className="login-reginput-container">
        <div className="login-reg-input">
          <img
            className="login-ext-arrow"
            onClick={handleLogin}
            src={backarrow}
          ></img>

          <div className="font2"> Request Access To CompScale</div>
          <div>
            <MasTextField
              label="User Name"
              onChange={getFormValues}
              styledClass="login-name-textfield"
              name="username"
              placeholder="User Name"
              type="input"
            />
          </div>
          <div className="error-message">{errorValues.username}</div>
          <div>
            <MasTextField
              label="Company Email Id"
              onChange={getFormValues}
              styledClass="login-name-textfield"
              name="email"
              placeholder="Email id"
              type="input"
            />
          </div>
          <div className="error-message">{errorValues.email}</div>
          <div>
            <MasTextField
              label="Registered Company Name"
              onChange={getFormValues}
              styledClass="login-name-textfield"
              name="companyname"
              placeholder="Acme Corp Ltd."
              type="input"
            />
          </div>
          <div className="error-message">{errorValues.companyname}</div>
          <div>
            <MasTextField
              label="Current Organization Designation"
              onChange={getFormValues}
              styledClass="login-name-textfield"
              name="designation"
              placeholder="Consultant"
              type="input"
            />
          </div>
          <div className="error-message">{errorValues.designation}</div>
          <div>
            <MasTextField
              label="Contact No."
              onChange={getFormValues}
              styledClass="login-name-textfield"
              name="contact"
              placeholder="10 Digit No."
            />
          </div>
          <div className="error-message">{errorValues.contact}</div>
          <div>
            <MasTextField
              label="Password"
              onChange={getFormValues}
              styledClass="login-name-textfield"
              name="password"
              placeholder="Password"
              type="Password"
            />
          </div>
          <div>
            <MasTextField
              label="Confirm Password"
              onChange={getFormValues}
              styledClass="login-name-textfield"
              name="confirmPassword"
              placeholder="Password"
              type="Password"
            />
          </div>
          <div className="error-message">{errorValues.password}</div>
          <div className="login-ext-external">
            <Button
              variant="primary"
              size="large"
              sx={{
                textTransform: "none",
                color: "white",
                width: "25vw",
                height: "3vw",
                fontWeight: "400",
                fontSize: "1.09vw",
                lineHeight: "2.7vh",
              }}
              onClick={handleLoginRequest}
            >
              Submit
            </Button>
          </div>
          <div className="login-ext-register">
            <p className="font3"> Existing user?</p>
            <p className="font3">
              <Link
                sx={{ color: "#26890D", cursor: "pointer" }}
                underline="none"
                onClick={handleLogin}
              >
                Login Now
              </Link>
            </p>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default RequestLoginAccess;
