import React, { useState, useEffect } from "react";
import MasTable from "../../../domain-component/MasTable/Table";
import TableLegends from "../TableLegends/tableLegends";
import api from "../../../utility/common/api";
import { showLoader } from "../../../store/actions/global";
import { useDispatch } from "react-redux";

const ReportTables = (props) => {

  const dispatch = useDispatch();
  const page_size = 10;
  const colorProperties = {
    mainHeadCellBackground: "#004F59",
    mainHeadCellColor: "#FFFFFF",
    headCellsBackground: "#b7dde2",
    headCellsColor: "#000000",
    overallBackground: "#E3E4E4",
    belowP25: "#DA291C",
  };

  const [mainHeadCellData, setMainHeadCellData] = useState([
    {
      id: "competitiveness",
      label: "Competitiveness on Total Cost To Company",
      disableSorting: true,
    },
  ]);

  const [headCellsData, setHeadCellsData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [totalRowsData,setTotalRowsData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const paginate = (array, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const handlePageChange = (p) => {
    setRowData(paginate(totalRowsData,p));
  };

  useEffect(() => {
    if (props.primKey) {
      dispatch(showLoader(true));
      const url = `/api/report/${props.primKey}?chartType=rawdata`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data && data.rows.length > 0) {
            // let head = [];
            // Object.keys(data.rows[0].cellValues).forEach((el) => {
            //   head.push({
            //     id: el,
            //     label: el,
            //     disableSorting: true,
            //     edit: true,
            //   });
            // });
            const head = Object.keys(data.rows[0].cellValues).map((el) => {
              return {
                id: el,
                label: el,
                disableSorting: true,
                edit: true,
              }
            });
            setHeadCellsData(head);

            // let rows = [];
            // data.rows.forEach((el) => {
            //   rows.push(el.cellValues);
            // });

            const rows = data.rows.map((el) => el.cellValues);
            setTotalRowsData(rows);
            setRowCount(rows.length);
            setRowData(paginate(rows,1));
            dispatch(showLoader(false));
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(showLoader(false));
        });
    }
  }, []);

  // function createData(
  //   unit,
  //   func,
  //   budget,
  //   fiftyPayout,
  //   eightyFivePayout,
  //   rowSpan,
  //   color,
  //   background
  // ) {
  //   return {
  //     unit,
  //     func,
  //     budget,
  //     fiftyPayout,
  //     eightyFivePayout,
  //     rowSpan,
  //     color,
  //     background,
  //   };
  // }

  // const rows = [
  //   createData(
  //     "Infrastructure & Solutions",
  //     "Sales & BD",
  //     "P32",
  //     "P24",
  //     "P30",
  //     4,
  //     { 3: "belowP25" }
  //   ),
  //   createData(false, "Projects", "P32", "P26", "P30", 1, {}),
  //   createData(false, "Procurement", "P32", "P26", "P30", 1, {}),
  //   createData(false, "Engineering", "P32", "P24", "P25", 1, {
  //     3: "belowP25",
  //     4: "belowP25",
  //   }),
  //   createData(
  //     "none",
  //     "Overall",
  //     "P32",
  //     "P25(8)",
  //     "P30(3)",
  //     1,
  //     { 3: "belowP25" },
  //     {
  //       0: "overallBackground",
  //       1: "overallBackground",
  //       2: "overallBackground",
  //       3: "overallBackground",
  //       4: "overallBackground",
  //     }
  //   ),
  //   createData("Textile Machinery", "Sales & BD", "P32", "P24", "P30", 2, {
  //     3: "belowP25",
  //   }),
  //   createData(false, "Projects", "P32", "P24", "P30", 1, { 3: "belowP25" }),
  //   createData(
  //     "none",
  //     "Overall",
  //     "P32",
  //     "P25(8)",
  //     "P30(3)",
  //     1,
  //     { 3: "belowP25" },
  //     {
  //       0: "overallBackground",
  //       1: "overallBackground",
  //       2: "overallBackground",
  //       3: "overallBackground",
  //       4: "overallBackground",
  //     }
  //   ),
  //   createData("Textile Machinery", "Sales & BD", "P32", "P24", "P30", 2, {
  //     3: "belowP25",
  //   }),
  //   createData(false, "Projects", "P32", "P26", "P30", 1, {}),
  //   createData(
  //     "none",
  //     "Overall",
  //     "P32",
  //     "P25(8)",
  //     "P30(3)",
  //     1,
  //     { 3: "belowP25" },
  //     {
  //       0: "overallBackground",
  //       1: "overallBackground",
  //       2: "overallBackground",
  //       3: "overallBackground",
  //       4: "overallBackground",
  //     }
  //   ),
  // ];
 
  return (
    <div>
      {rowData.length > 0 && (
        <>
          <TableLegends
            legendsArray={[
              { name: "Below P25 of the market", background: "#DA291C" },
            ]}
          />
          <MasTable
            mainHeadCell={mainHeadCellData}
            rows={rowData}
            headCells={headCellsData}
            colorProperties={colorProperties}
            showAllWhiteShade={true}
            stickyHeader="true"
            maxHeight={props.maxHeight}
            // isPagination={true}
            returnPageNo={handlePageChange}
            showPagination={true}
            rowCount={rowCount }
          />
        </>
      )}
    </div>
  );
};

export default ReportTables;
