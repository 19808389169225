import React, { useState, useEffect, useContext, useCallback } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "../assets/styles/navbar.css";

import { useParams, useLocation, useNavigate } from "react-router-dom";

import LoginOption from "../modules/Login/LoginOption";

import { useSelector } from "react-redux";

const NavBar = () => {
  let params = useParams();
  // const Currentlocation = useLocation();
  let { page } = params;
  // const currentPage = (Currentlocation.pathname).replace('/', '');
  // page = page ? page : currentPage;
  const navigate = useNavigate();
  console.log("page", page);
  const login = sessionStorage.getItem("Login");

  if (login === null) {
    sessionStorage.setItem("Login", "NotLoggedIn");
  }
  useEffect(() => {
    if (page === null || page === undefined || login === "NoLoggedIn") {
      navigate("/login-external");
    }
  }, []);

  const tabNameToIndexEn = {
    0: "undefined",
    0: "login-option",
    4: "help-center",
    5: "manage-announcements",
  };

  const IndexToTabNameEn = {
    undefined: 0,
    "login-option": 0,
    "api-catalog-details": 0,
    "my-subscriptions": 2,
    "help-center": 4,
    "manage-announcements": 5,
  };

  const tabNameToIndexEl = {
    0: "home",
    2: "login-option",
    4: "my-subscriptions",
    6: "help-center",
    7: "manage-announcements",
  };

  const IndexToTabNameEl = {
    home: 0,
    "login-option": 2,
    "api-catalog-details": 2,
    "my-subscriptions": 4,
    "subscription-details": 4,
    "subscribe-to-api-acknowledgement": 4,
    "help-center": 6,
    "manage-announcements": 7,
  };

  const tabNameToIndexIn = {
    0: "login-option",
    2: "help-center",
    3: "manage-announcements",
  };

  const IndexToTabNameIn = {
    undefined: 0,
    "help-center": 2,
    "login-option": 0,
    "api-catalog-details": 0,
    "manage-announcements": 3,
  };

  const tabNameToIndexIl = {
    0: "home",
    2: "login-option",
    4: "my-api-products",
    6: "my-subscriptions",
    8: "my-projects",
    10: "reports",
    12: "help-center",
    13: "manage-announcements",
  };

  const IndexToTabNameIl = {
    "login-option": 2,
    "my-api-products": 4,
    "api-catalog-details": 2,
    subscribers: 4,
    "subscriber-details": 4,
    "my-subscriptions": 6,
    "my-projects": 8,
    "subscription-details": 6,
    "subscribe-to-api-acknowledgement": 6,
    "my-project-details": 8,
    "api-product-details": 4,
    "api-product-acknowledgement": 4,
    "api-product-version-acknowledgement": 4,
    "add-project-acknowledgement": 8,
    "edit-project-acknowledgement": 8,
    reports: 10,
    "help-center": 12,
    "manage-announcements": 13,
  };

  useEffect(
    () => {
      if (
        page === "login-option" ||
        page === "subscription-details" ||
        page === "api-catalog-details" ||
        page === "api-product-details" ||
        page === "add-project-acknowledgement" ||
        page === "edit-project-acknowledgement" ||
        page === "api-product-acknowledgement" ||
        page === "api-product-version-acknowledgement" ||
        page === "subscribe-to-api-acknowledgement"
      ) {
        setSelectedIlTab(IndexToTabNameIl[page]);
      }
      setSelectedInTab(IndexToTabNameIn[page]);
      setSelectedIlTab(IndexToTabNameIl[page]);
      setSelectedEnTab(IndexToTabNameEn[page]);
      setSelectedElTab(IndexToTabNameEl[page]);
    },
    [page, IndexToTabNameIn[page]],
    [IndexToTabNameIl[page]],
    [IndexToTabNameEn[page]],
    [IndexToTabNameEl[page]]
  );

  const location = useLocation();

  const [selectedEnTab, setSelectedEnTab] = React.useState(
    IndexToTabNameEn[page]
  );
  const [selectedElTab, setSelectedElTab] = React.useState(
    IndexToTabNameEl[page]
  );
  const [selectedInTab, setSelectedInTab] = React.useState(
    IndexToTabNameIn[page]
  );
  const [selectedIlTab, setSelectedIlTab] = React.useState(
    IndexToTabNameIl[page]
  );
  const [isShowAnnouncement, setIsShowAnnouncement] = React.useState(false);

  const handleEnChange = (event, newValue) => {
    if (tabNameToIndexEn[newValue] === "manage-announcements") {
      setIsShowAnnouncement(!isShowAnnouncement);
    } else {
      navigate(`/${tabNameToIndexEn[newValue]}`);
      setSelectedEnTab(newValue);
    }
  };
  const handleElChange = (event, newValue) => {
    if (tabNameToIndexEl[newValue] === "manage-announcements") {
      setIsShowAnnouncement(!isShowAnnouncement);
    } else {
      navigate(`/${tabNameToIndexEl[newValue]}`);
      setSelectedElTab(newValue);
    }
  };
  const handleInChange = (event, newValue) => {
    if (tabNameToIndexIn[newValue] === "manage-announcements") {
      setIsShowAnnouncement(!isShowAnnouncement);
    } else {
      navigate(`/${tabNameToIndexIn[newValue]}`);
      setSelectedInTab(newValue);
    }
  };

  const handleIlChange = (event, newValue) => {
    if (tabNameToIndexIl[newValue] === "manage-announcements") {
      setIsShowAnnouncement(!isShowAnnouncement);
    } else {
      navigate(`/${tabNameToIndexIl[newValue]}`);
      setSelectedIlTab(newValue);
    }
  };

  const [isNewAnnouncement, setIsnewannouncement] = React.useState([]);

  const getNewAnnouncement = (newAnnouncement) => {
    setIsnewannouncement(newAnnouncement);
  };

  const displayAnnouncementStatus = useCallback((announcementStatus) => {
    setIsShowAnnouncement(announcementStatus);
  });

  return (
    <>
      <div className="mas-main-nav">
        <div class="tabs-section">
          {login === "NotLoggedIn" ? (
            <>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", height: "56px" }}
              >
                <Tabs
                  sx={{ height: "56px" }}
                  value={selectedEnTab}
                  onChange={handleEnChange}
                  TabIndicatorProps={{
                    style: { background: "#D9B173", height: "5px" },
                  }}
                >
                  <Tab className="label-style" label="API Catalog" />
                  <span className="tab-space" />
                  <Tab className="label-style" label="My Subscriptions" />
                  <span className="tab-space" />
                  <Tab className="label-style" label="Help Center" />
                </Tabs>
              </Box>
            </>
          ) : (
            <>
              {login !== "NotLoggedIn" ? (
                <>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      height: "56px",
                    }}
                  >
                    <Tabs
                      sx={{ height: "56px" }}
                      value={selectedElTab}
                      onChange={handleElChange}
                      TabIndicatorProps={{
                        style: { background: "#D9B173", height: "5px" },
                      }}
                    >
                      <Tab className="label-style" label="Home" />
                      <span className="tab-space" />
                      <Tab className="label-style" label="API Catalog" />
                      <span className="tab-space" />
                      <Tab className="label-style" label="My Subscriptions" />
                      <span className="tab-space" />
                      <Tab className="label-style" label="Help Center" />
                    </Tabs>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {login === "NotLoggedIn" ? (
            <>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", height: "56px" }}
              >
                <Tabs
                  sx={{ height: "56px" }}
                  value={selectedInTab}
                  onChange={handleInChange}
                  TabIndicatorProps={{
                    style: { background: "#D9B173", height: "5px" },
                  }}
                >
                  <Tab className="label-style" label="Deloitte" />
                  <span className="tab-space" />
                  <Tab className="label-style" label="CompScale" />
                </Tabs>
              </Box>
            </>
          ) : (
            <>
              {login !== "NotLoggedIn" ? (
                <>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      height: "56px",
                    }}
                  >
                    <Tabs
                      sx={{ height: "56px" }}
                      value={selectedIlTab}
                      onChange={handleIlChange}
                      TabIndicatorProps={{
                        style: { background: "#D9B173", height: "5px" },
                      }}
                    >
                      <Tab className="label-style" label="Home" />
                      <span className="tab-space" />
                      <Tab className="label-style" label="API Catalog" />
                      <span className="tab-space" />
                      <Tab className="label-style" label="My API Products" />
                      <span className="tab-space" />
                      <Tab className="label-style" label="My Subscriptions" />
                      <span className="tab-space" />
                      <Tab className="label-style" label="My Projects" />
                      <span className="tab-space" />
                      <Tab className="label-style" label="Reports" />
                      <span className="tab-space" />
                      <Tab className="label-style" label="Help Center" />
                    </Tabs>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>

      {login === "NotLoggedIn" ? (
        <>
          {page === "login-option" ? (
            <>{selectedEnTab === 0}</>
          ) : (
            <>{selectedEnTab === 0}</>
          )}

          {selectedEnTab === 2}

          {selectedEnTab === 5}
        </>
      ) : (
        <>
          {login !== "NotLoggedIn" ? (
            <>
              {selectedElTab === 0}

              {page === "api-catalog-details" ? (
                <>{selectedElTab === 2}</>
              ) : (
                <>{selectedElTab === 2}</>
              )}

              {page === "subscription-details" ? (
                <>{selectedElTab === 4}</>
              ) : page === "subscribe-to-api-acknowledgement" ? (
                <>{selectedElTab === 4}</>
              ) : (
                <>{selectedElTab === 4}</>
              )}

              {selectedElTab === 7}
            </>
          ) : null}
        </>
      )}

      {login === "NotLoggedIn" ? (
        <>
          {page === "api-catalog-details" ? (
            <>{selectedInTab === 0}</>
          ) : (
            <>{selectedInTab === 0}</>
          )}

          {selectedInTab === 3}
        </>
      ) : (
        <>
          {login !== "NotLoggedIn" ? (
            <>
              {selectedIlTab === 0}

              {page === "api-catalog-details" ? (
                <>{selectedIlTab === 2}</>
              ) : (
                <>{selectedIlTab === 2}</>
              )}

              {page === "subscribers" ? (
                <>{selectedIlTab === 4}</>
              ) : (
                <>
                  {page === "subscriber-details" ? (
                    <>{selectedIlTab === 4}</>
                  ) : null}
                  {page === "my-api-products" ? (
                    <>{selectedIlTab === 4}</>
                  ) : null}

                  {page === "api-product-details" ? (
                    <>{selectedIlTab === 4}</>
                  ) : null}
                </>
              )}

              {page === "api-product-acknowledgement" ? (
                <>{selectedIlTab === 4}</>
              ) : null}
              {page === "api-product-version-acknowledgement" ? (
                <>{selectedIlTab === 4}</>
              ) : null}

              {page === "subscription-details" ? (
                <>{selectedIlTab === 6}</>
              ) : (
                <>
                  {page !== "subscribe-to-api-acknowledgement" &&
                    selectedIlTab === 6}
                </>
              )}

              {page === "subscribe-to-api-acknowledgement" ? (
                <>{selectedIlTab === 6}</>
              ) : null}

              {page === "my-project-details" ? (
                <>{selectedIlTab === 8}</>
              ) : null}
              {page === "my-projects" ? <>{selectedIlTab === 8}</> : null}
              {page === "add-project-acknowledgement" ? (
                <>{selectedIlTab === 8}</>
              ) : null}
              {page === "edit-project-acknowledgement" ? (
                <>{selectedIlTab === 8}</>
              ) : null}

              {selectedIlTab === 10}

              {selectedIlTab === 13}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default NavBar;
