import React, { useEffect, useState } from "react";
import "./peerset1.scss";
import peer1 from "./Images/peer1.svg";
import { useNavigate } from "react-router-dom";
import api from "../../utility/common/api";
import PeerHead from "./PeerHead";
import { getTranslation } from "../../utility/i18n/i18n";

function Peerset1() {
  const [flag, setFlag] = useState(false);
  const navigate = useNavigate();
  const tObj = getTranslation("peerset1");
  const navigateToCreatePeer2 = () => {
    navigate("/Peerset2");
  };
  useEffect(() => {
    const url = "/api/peerset/list";
    api
      .get({ url })
      .then(({ data }) => {
        console.log(data);
        if (data && data.length > 0) {
          navigate("/Peerset4");
        } else {
          setFlag(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setFlag(true);
      });
  }, []);
  return (
    <div>
      {flag && (
        <>
          <PeerHead peerHeadCont={"Peer Sets"} />
          <img src={peer1} id="peer1img1" />
          <div id="noPeer" className="font10">
            {tObj.noPeer}
          </div>
          <div id="noPeerDesc" className="font8">
            {tObj.noPeerDesc}
          </div>
          <button
            id="createPeer"
            className="font8"
            onClick={navigateToCreatePeer2}
          >
            Create Peer Sets
          </button>
        </>
      )}
    </div>
  );
}

export default Peerset1;
