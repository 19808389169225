import React from "react";
import Link from "@mui/material/Link";

const MasLinkButton = ({
  color,
  onClick,
  label,
  width,
  startIcon,
  endIcon,
}) => {
  return (
    <Link
      component="button"
      color={color}
      onClick={onClick}
      sx={{ fontFamily: "SourceSansProRegular" }}
      width={width}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        {startIcon && <div>{startIcon}</div>}
        {label}
        {endIcon && <div>{endIcon}</div>}
      </div>
    </Link>
  );
};

MasLinkButton.defaultProps = {
  color: "secondary",
  width: "auto",
  onClick: () => {},
};

export default MasLinkButton;
