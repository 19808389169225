import React from "react";
import "./compensation.scss";
import pdfIcon from "./Images/pdf.svg";
import dw1 from "./Images/dw1.svg";
import dw2 from "./Images/dw2.svg";
import BigCirc from "./Images/bigCirc.svg";
import SmallCirc from "./Images/smallCirc.svg";
import VertLine from "./Images/vertLine.svg";
import CompensationHead from "./CompensationHead";
import CompensationRightCont from "./CompensationRightCont";
import CompensationThreeBtns from "./CompensationThreeBtns";

const Compensation1 = () => {
  const desc =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam...";

  const pdf = (
    <div className="flexRow">
      <img id="comp-pdfIcon" src={pdfIcon} />
      <span id="comp-pdfName">Firm Compensation_files...xls</span>
      <div className="flexCol">
        <img id="comp-dw1" src={dw1} />
        <img id="comp-dw2" src={dw2} />
      </div>
    </div>
  );

  const empName = (names, times) => {
    return (
      <div className="comp-emp">
        {names}
        <br></br>
        11 Aug'22 @ <p className="time">{times}</p>
      </div>
    );
  };

  return (
    <div>
      <CompensationHead />
      <div className="flexRow">
        <div id="comp-box">
          <div className="flexRow">
            <div className="flexCol">
              <img src={BigCirc} id="compBigCirc1" />
              <img src={SmallCirc} id="compSmallCirc" />
              <img src={VertLine} id="compVertLine" />
              <img src={BigCirc} id="compBigCirc2" />
              <img src={SmallCirc} id="compSmallCirc" />
              <img src={VertLine} id="compVertLine" />
              <img src={BigCirc} id="compBigCirc2" />
              <img src={SmallCirc} id="compSmallCirc" />
              <img src={VertLine} id="compVertLine" />
              <img src={BigCirc} id="compBigCirc2" />
              <img src={SmallCirc} id="compSmallCirc" />
              <img src={VertLine} id="compVertLine" />
              <img src={BigCirc} id="compBigCirc2" />
              <img src={SmallCirc} id="compSmallCirc" />
              <img src={VertLine} id="compVertLine" />
              <img src={BigCirc} id="compBigCirc2" />
              <img src={SmallCirc} id="compSmallCirc" />
            </div>
            <div className="flexCol">
              <div id="compEmp1">{empName("You", "14:45AM")}</div>
              <div id="compEmps">{empName("Sunita Marwah", "16:45AM")}</div>
              <div id="compEmps">{empName("You", "14:45AM")}</div>
              <div id="compEmps">{empName("You", "14:45AM")}</div>
              <div id="compEmps">{empName("Sunita Marwah", "16:45AM")}</div>
              <div id="compEmps">{empName("You", "14:45AM")}</div>
            </div>
            <div className="flexCol">
              <div id="comp-pdfDownload">{pdf}</div>
              <div className="comp-para">{desc}</div>
              <div className="comp-para">{desc}</div>
              <div id="comp-pdfDownload" style={{ "margin-top": "38.5px" }}>
                {pdf}
              </div>
              <div className="comp-para">{desc}</div>
              <div className="comp-para">{desc}</div>
            </div>
          </div>
          <div id="comp1-inputBox">
            <form>
              <input
                type="text"
                placeholder="Input text here to send sunita marwah"
                id="comp1-inp"
              />
              <input type="submit" value="Send Comments" id="comp1-sub" />
            </form>
          </div>
          <CompensationThreeBtns />
        </div>
        <CompensationRightCont />
      </div>
    </div>
  );
};

export default Compensation1;
