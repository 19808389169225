import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
export default function DropdownIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="-2 -2 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.98226 14.9999C9.56872 15.0005 9.17498 14.8164 8.90168 14.4948L2.77008 7.27326C2.36702 6.77556 2.42114 6.03547 2.89198 5.60638C3.36282 5.17728 4.07816 5.21613 4.50313 5.69387L9.86477 12.0089C9.89443 12.0439 9.93725 12.064 9.98226 12.064C10.0273 12.064 10.0701 12.0439 10.0998 12.0089L15.4614 5.69387C15.7306 5.36145 16.1524 5.20619 16.5637 5.28817C16.975 5.37014 17.3111 5.67649 17.4421 6.08866C17.5731 6.50084 17.4783 6.95418 17.1944 7.27326L11.0647 14.4922C10.7909 14.8146 10.3967 14.9995 9.98226 14.9999Z"
          fill="#8D7249"
        />
      </svg>
    </SvgIcon>
  );
}
