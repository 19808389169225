import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";

export default (props) => {
  const { sx = {}, size = 40, thickness = 3.6, color = "primary" } = props;

  return (
    <div style={{ margin: "2rem" }}>
      <CircularProgress
        sx={sx}
        size={size}
        thickness={thickness}
        variant="indeterminate"
        disableShrink={false}
        color={color}
      />
    </div>
  );
};
