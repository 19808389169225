import React, { useState, useEffect } from "react";
import "./dipstick.scss";
import MasContainer from "../../domain-component/MasContainer";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel } from "@mui/material";

const Dipstickq3 = () => {
  return (
    <div className="my-subscription-container">
      <MasContainer>
        <div>
          <h1 class="mas-text-h1 header-font">Dipsticks Survey</h1>
        </div>
        <div className="hdn-text font8">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
          purus sit amet luctus venenatis, lectus magna fringilla urna,
          porttitor rhoncus dolor purus non enim praesent elementum facilisis
          leo, vel fringilla est ullamcorper.
        </div>

        <div className="survey-msg font10">
          Thank You for participating in the survey!
        </div>
        <div>
          <button className="survey-btn font8">Return Home</button>
        </div>
      </MasContainer>
    </div>
  );
};

export default Dipstickq3;
