import React, { useState, useEffect } from "react";
import "./dipstick.scss";
import MasContainer from "../../domain-component/MasContainer";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel } from "@mui/material";
import api from "../../utility/common/api";
import { useParams, useNavigate } from "react-router-dom";
import { getTranslation } from "../../utility/i18n/i18n";

let surveyAns = [];
const Dipstickq1 = () => {
  const navigate = useNavigate();
  const survey = {
    quest1: {
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ?",
      options: ["Answer 1", "Answer 2", "Answer 3", "Answer 4"],
    },
    quest2: {
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ?",
      options: ["Answer 1", "Answer 2", "Answer 3", "Answer 4"],
    },
    quest3: {
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ?",
      options: ["Answer 1", "Answer 2", "Answer 3", "Answer 4"],
    },
  };
  const [questNo, setquestNo] = useState(1);
  const [data, setData] = useState(survey[Object.keys(survey)[questNo - 1]]);
  const [checked, setChecked] = useState([]);
  const [checkedState, setCheckedState] = useState(new Array(4).fill(false));

  const handleOnChange = (position, event) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };
  const onNextButtonClick = () => {
    let copy = [];
    checked.forEach((e) => {
      copy.push(e);
    });
    surveyAns.push(checked);
    if (questNo >= 3) {
      let data = {
        answerOne: surveyAns[0],
        answerTwo: surveyAns[1],
        answerThree: surveyAns[2],
      };

      const url = "/api/survey";
      api
        .post({ url, data })
        .then(({ data }) => {
          if (data) {
            navigate("/dashboard");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCheckedState(new Array(4).fill(false));
      setquestNo(questNo + 1);
      setData(survey[Object.keys(survey)[questNo]]);
      setChecked([]);
    }
  };
  return (
    <div>
      {data && (
        <div className="my-subscription-container">
          <MasContainer>
            <div>
              <h1 className="mas-text-h1 header-font">Dipsticks Survey</h1>
            </div>
            <div className="hdn-text font8">
              {getTranslation("dashboard")["description2"]}
            </div>

            <div className="dipstickFrame">
              <div className="q1-index font8">{questNo}/3</div>
              <div className="q1 font8">{data["question"]}</div>
              <div className="ans1 font8">
                {data["options"].map((item, index) => (
                  <div key={index}>
                    <Checkbox
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name={item}
                      value={item}
                      checked={checkedState[index]}
                      onChange={(e) => handleOnChange(index, e)}
                      sx={{
                        color: "#000000;",
                        "&.Mui-checked": {
                          color: "#26890D",
                        },
                      }}
                    />
                    {item}
                  </div>
                ))}
              </div>
              {checkedState.includes(true) && (
                <button
                  className="dipsticks-next-button font8"
                  onClick={onNextButtonClick}
                >
                  {questNo != 3 ? <p>Next Question</p> : <p>Finish</p>}
                </button>
              )}
            </div>
          </MasContainer>
        </div>
      )}
    </div>
  );
};

export default Dipstickq1;
