import React from "react";
import CommRightContent from "./CommRightContent";
import CommLeftContent from "./CommLeftContent";
import CommHead from "./CommHead";

function Comm1() {
  return (
    <div>
      <CommHead
        commBtnClr={"commBtnWhite"}
        commMyActBtnClr={"commMyActBtnBlack"}
      />
      <div className="commFlexRow">
        <CommLeftContent showMyActLeftCont={true} />
        <CommRightContent showMyActSearch={true} />
      </div>
    </div>
  );
}

export default Comm1;
