import React from "react";

const MasContainer = ({ breadcrumb, children, centerContent }) => {
  const centerContentStyle = {
    maxWidth: "71.5rem",
    marginLeft: "auto",
    marginRight: "auto",
  };
  return (
    <div style={centerContent && centerContentStyle}>
      <div
        style={{
          ...centerContentStyle,
          marginTop: "2rem",
        }}
      >
        {breadcrumb}
      </div>
      {children}
    </div>
  );
};

export default MasContainer;
