import { Typography } from "@mui/material";
import React from "react";
import "./breadcrum.scss";
import { useNavigate, useParams } from "react-router-dom";
const Breadcrum = () => {
  const navigate = useNavigate();
  const managerBread = {
    Home: "/manager-dashboard",
    Reports: "/managerReportGenReq",
    View: "/reportsView",
  };

  const userBread = {
    Home: "/dashboard",
    Reports: `/reports3/${sessionStorage.getItem("id")}`,
    View: "/reports",
  };

  const onBreadcrumClick = (elem) => {
    if (localStorage.getItem("userType") == "USER") {
      navigate(userBread[elem]);
    } else {
      navigate(managerBread[elem]);
    }
  };

  return (
    <div className="breadcrum-section">
      {Object.keys(managerBread).map((elem, key) => {
        return (
          <div key={elem + key} className="breadcrum-section">
            <Typography
              variant="body2"
              className="breadcrum-text"
              color={key !== managerBread.length - 1 ? "#26890D" : "#000000"}
              onClick={() => {
                onBreadcrumClick(elem);
              }}
            >
              {elem}
            </Typography>
            {key !== managerBread.length - 1 && (
              <div>
                <Typography variant="body2" className="breadcrum-text">
                  /
                </Typography>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrum;
