import { Typography } from "@mui/material";
import React from "react";
import "./tableLegends.scss";

const TableLegends = ({ legendsArray }) => {
  return (
    <div className="legends-box">
      {legendsArray.map((elem, key) => {
        return (
          <div
            style={{
              margin: key === 0 ? "2px 5px 2px auto" : "2px 5px 2px 5px",
            }}
            className="single-legends-box"
          >
            <div
              style={{ background: elem.background }}
              className="legends-color"
            />
            <Typography
              variant="caption2"
              fontSize={"0.93vw"}
              lineHeight={"100%"}
            >
              {elem.name}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default TableLegends;
