import { Avatar, Typography } from "@mui/material";
import React, { useState } from "react";
import "./NewsBox.scss";
import RightArrowHead from "../../../assets/images/rightArrowHead.png";
import NotificationImage from "../../../assets/images/notificationImage.png";
import Userpic from "../../../assets/images/Userpic.png";

const News = () => {
  const [newsData, setNewsData] = useState([
    {
      authorImage: Userpic,
      authorName: "Author Name",
      newsImage: NotificationImage,
      description:
        "You have a reply on your basic report generated on 23rd August 2022",
      timStamps: "2hrs days ago",
    },
    {
      authorImage: Userpic,
      authorName: "Author Name",
      newsImage: NotificationImage,
      description:
        "You have a reply on your basic report generated on 23rd August 2022",
      timStamps: "2hrs days ago",
    },
    {
      authorImage: Userpic,
      authorName: "Author Name",
      newsImage: NotificationImage,
      description:
        "You have a reply on your basic report generated on 23rd August 2022",
      timStamps: "2hrs days ago",
    },
    {
      authorImage: Userpic,
      authorName: "Author Name",
      newsImage: NotificationImage,
      description:
        "You have a reply on your basic report generated on 23rd August 2022",
      timStamps: "2hrs days ago",
    },
  ]);

  return (
    <div className="news">
      <div className="news-header">
        <Typography variant="subtitle3" className="news-header-text font13">
          News & Notifications
        </Typography>
        <div className="news-arrow">
          <img src={RightArrowHead} />
        </div>
      </div>
      <div>
        {newsData.map((elem, index) => {
          return (
            <div className="news-box" key={index}>
              <div className="news-box-author">
                <div style={{ width: "1.25vw", height: "2.2vh" }}>
                  <Avatar
                    variant="square"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <img src={elem.authorImage} />
                  </Avatar>
                </div>

                <Typography
                  variant="caption2"
                  className="news-box-author-name footer-font"
                >
                  {elem.authorName}
                </Typography>
              </div>
              <div className="news-box-data">
                <img src={elem.newsImage} className="news-box-data-image" />
                <Typography
                  variant="body3"
                  className="news-box-data-text font7"
                >
                  {elem.description}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="caption2"
                  className="news-box-data-timestamp footer-font"
                >
                  {elem.timStamps}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default News;
