import { createGlobalStyle } from "styled-components";
import { fontFamily } from "./assets/fonts";

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: ${fontFamily.main.name};
    src: local("Open Sans"),
      url(${fontFamily.main.url}) format("truetype");
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: ${fontFamily.bold.name};
    src: local("SourceSansProBold"),
      url(${fontFamily.bold.url}) format("truetype");
  }
@font-face {
  font-family: ${fontFamily.semibold.name};
  src: local("SourceSansProSemiBold"),
    url(${fontFamily.semibold.url}) format("truetype");
}

@font-face {
    font-family:${fontFamily.light.name};
    src: local("SourceSansProLight"),
      url(${fontFamily.light.name}) format("truetype");
  }
  @font-face {
    font-family:${fontFamily.italic.name};
    src: local("SourceSansProItalic"),
      url(${fontFamily.italic.url}) format("truetype");
    font-style: italic;
  }

  ::-webkit-input-placeholder {
    font-style: italic;
    font-family: "Open Sans";
  }
  :-moz-placeholder {
    font-style: italic; 
    font-family: "Open Sans";
  }
  }
  :-ms-input-placeholder {  
    font-style: italic; 
    font-family: "Open Sans";
 }
`;

export default GlobalStyle;
