import React from "react";
import FirstContent from "../PartnerManager/PartnerManagerFirstContent/FirstContent";
import "./CommHead.scss";
import { Routes, Route, useNavigate } from "react-router-dom";

function CommHead(props) {
  const navigate = useNavigate();
  const navigateToComm1 = () => {
    navigate("/comm1");
  };
  const navigateToComm4 = () => {
    navigate("/comm4");
  };
  return (
    <div>
      <FirstContent
        heading={"Community"}
        nav1={"Home"}
        nav2={"Community"}
        nav1col={"navGreen"}
        nav2col={"navBlack"}
      />
      <div id="commDesc">
        A Compscale community to discuss thoughts and share the upcoming trend
        in resource nurturing and management
      </div>
      <button id={props.commBtnClr} onClick={navigateToComm4}>
        Community
      </button>
      <button id={props.commMyActBtnClr} onClick={navigateToComm1}>
        My Activities
      </button>
    </div>
  );
}

export default CommHead;
