import React, { useState } from "react";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import dw1 from "./Images/dw1.svg";
import dw2 from "./Images/dw2.svg";
import "./RightContent.scss";
import api from "../../../utility/common/api";
import SampleFile from "../../../assets/sample-data/SampleFile.csv";

const getCreatedDate = (d) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    monthNames[d.getMonth()] +
    " " +
    d.getDate() +
    ", " +
    d.getFullYear() +
    " @ " +
    d.getHours() +
    ":" +
    d.getMinutes()
  );
};
function RightContent(props) {
  const downloadData = () => {
    const url = `/api/file/${props.data.compscaleRest.refName}?storgelocation=CONSUMED`;
    api
      .get({ url })
      .then(({ data }) => {
        if (data) {
          const temp = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = temp;
          link.setAttribute("download", props.data.compscaleRest.refName.split(/_(.*)/s)[1]);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const downloadPeerSetData = () => {
    const url = `${window.location.origin}/api/peerset/downloadpeersetdata/${props.data.peersetRest.id}`;
    const userToken = localStorage.getItem("authentication-token");
    if (userToken) {
      const link = document.createElement("a");
      link.href = `${url}?mid=${userToken}`;
      document.body.appendChild(link);
      link.click();
    }

    // api.get({ url }).then(({ data }) => {
    //     if (data) {
    //       const temp = window.URL.createObjectURL(new Blob([data]));
    //       const link = document.createElement("a");
    //       link.href = temp;
    //       link.setAttribute("download", "PeerSet Data.csv");
    //       document.body.appendChild(link);
    //       link.click();
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }
  return (
    <div style={{ display: "flex", marginTop: "15px", alignItems: "center" }}>
      {props.showExecuting ? (
        <>
          <TurnedInNotIcon id="partnerManagerLabel" />
          <div id="partnerManagerExec">Executing</div>
          {props.showPeersetDownload ?
            (<button id="peerSetDownload" onClick={downloadPeerSetData}>
              Download Peerset Data
              <div id="dwIcon">
                <img src={dw1} id="partnerCompDw1" />
                <img src={dw2} id="partnerCompDw2" />
              </div>
            </button>) : null}
        </>
      ) : null}
      {props.showSent ? (
        <>
          <TurnedInNotIcon id="PartnerManagerLabel2" />
          <div id="partnerManagerSent">Sent to Client</div>
        </>
      ) : null}
      {props.showDownload ? (
        <div>
          <button id="partnerCompDownload" onClick={downloadData}>
            Download Data
            <div id="dwIcon">
              <img src={dw1} id="partnerCompDw1" />
              <img src={dw2} id="partnerCompDw2" />
            </div>
          </button>
          <div id="partnerCompLastUpdate">
            Last Updated on{" "}
            {getCreatedDate(new Date(props.data.compscaleRest.updatedTime))}
          </div>
        </div>
      ) : null}
      {props.showDownloadSampleFile ? (
        <div>
          <a href={SampleFile} download className="download-sample-file">
            <button id="partnerCompDownload">
              Download Sample File
              <div id="dwIcon">
                <img src={dw1} id="partnerCompDw1" />
                <img src={dw2} id="partnerCompDw2" />
              </div>
            </button>
          </a>
          <div id="partnerCompLastUpdate">
            Last Updated on {getCreatedDate(new Date())}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default RightContent;
