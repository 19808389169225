import React from "react";
import { connect } from "react-redux";
import { isLoaderOpen } from "./store/selector/global";
import { setLoading } from "./store/actions/global";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import RoutedContent from "./modules/routes";
import GlobalStyle from "./global-styles";
import NavBar from "./modules/navbar";
import ScrollToTop from "./scrollToTop";

function App(props) {
  const basename =
    process.env && process.env.REACT_APP_ROUTER_BASE_PATH
      ? process.env.REACT_APP_ROUTER_BASE_PATH
      : "/";
  const { isLoading, openLoader, closeLoader } = props;
  console.log(sessionStorage, "sessionStorage.reload");
  return (
    <>
      <Router basename={basename}>
        <ScrollToTop />
        <RoutedContent />
      </Router>
      <GlobalStyle />
    </>
  );
}

// function onLoader(dispatch) {
//   dispatch.openLoader()
//   setTimeout(() => {
//     dispatch.closeLoader()
//   },2000)
// }

// const mapStateToProps = (state) => {
//   return {
//     isLoading: isLoaderOpen(state)
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     openLoader: () => {
//       dispatch(setLoading(true,'App/test'))
//     },
//     closeLoader: () => {
//       dispatch(setLoading(false,'App/test'))
//     }
//   }
// }
// export default connect(mapStateToProps,mapDispatchToProps)(App);
export default App;
