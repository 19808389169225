import React, { useState, useEffect } from "react";
import MasTable from "../../../domain-component/MasTable/Table";
import TableLegends from "../TableLegends/tableLegends";
import api from "../../../utility/common/api";
import { showLoader } from "../../../store/actions/global";
import { useDispatch } from "react-redux";

const CompensationAnalysisTable = (props) => {

  const dispatch = useDispatch();
  const page_size = 10;
  const colorProperties = {
    headCellsBackground: "#b7dde2",
    headCellsColor: "#53565A",
    overallBackground: "#E3E4E4",
    aboveP25: "#BBBCBC",
    aboveP75: "#00ABAB",
    belowP25: "rgba(218, 41, 28, 0.5)",
    aboveP50: "#FFCD00",
  };

  const [headCellsData, setHeadCellsData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [totalRowsData, setTotalRowsData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const paginate = (array, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const handlePageChange = (p) => {
    setRowData(paginate(totalRowsData, p));
  };

  // const [headCellsData, setHeadCellsData] = useState([
  //     {
  //         id: 'count',
  //         label: 'Head Count',
  //         disableSorting:true,
  //         edit:false,
  //     },
  //     {
  //         id: 'grade',
  //         label: 'Grades',
  //         disableSorting: true,
  //         edit:false,
  //     },
  //     {
  //         id: 'P10',
  //         label: 'P10',
  //         disableSorting: true,
  //         edit:true,
  //     },
  //     {
  //         id: 'P25',
  //         label: 'P25',
  //         disableSorting: true,
  //         edit:true,
  //     },
  //     {
  //         id: 'Median',
  //         label: 'Median',
  //         disableSorting: true,
  //         edit:false,
  //     },
  //     {
  //         id: 'Average',
  //         label: 'Average',
  //         disableSorting: true,
  //         edit:false,
  //     },
  //     {
  //         id: 'P66',
  //         label: 'P66',
  //         disableSorting: true,
  //         edit:true,
  //     },
  //     {
  //         id: 'P75',
  //         label: 'P75',
  //         disableSorting: true,
  //         edit:true,
  //     },
  //     {
  //         id: 'P90',
  //         label: 'P90',
  //         disableSorting: true,
  //         edit:true,
  //     },
  //     {
  //         id: 'Median2',
  //         label: 'Median',
  //         disableSorting: true,
  //         edit:false,
  //     },
  //     {
  //         id: 'Positioning',
  //         label: 'Positioning',
  //         disableSorting: true,
  //         edit:false,
  //     },
  // ])

  // function createData(count, grade, P10, P25, Median, Average, P66, P75, P90, Median2, Positioning, background) {
  //     console.log("Arguments",arguments)
  //     return {
  //         count,
  //         grade,
  //         P10,
  //         P25,
  //         Median,
  //         Average,
  //         P66,
  //         P75,
  //         P90,
  //         Median2,
  //         Positioning,
  //         background
  //     };
  // }
  function createData(...args) {
    let d = {};
    headCellsData.forEach((el, i) => {
      d[el.id] = args[0][i];
    });
    return d;
  }
  // const rows = [
  // createData("09", "Head D1", "7426", "1426","8691", "8918", "1", "2","3","4","54%",{4:"aboveP25",9:"aboveP25",10:"aboveP75"}),
  // createData("39", "Jr. Engineer/ Executive", "4226", "4567", "6545", "6456", "1", "2","3","4","18%",{4:"aboveP25",9:"aboveP25",10:"belowP25"}),
  // createData("09", "Head D1", "7426", "1426", "8691", "8918", "1", "2","3","4","54%",{4:"aboveP25",9:"aboveP25",10:"belowP25"}),
  // createData("39", "Jr. Engineer/ Executive", "4226", "4567", "6545", "6456", "1", "2","3","4","18%",{4:"aboveP25",9:"aboveP25",10:"aboveP50"}),
  // createData("09", "Head D1", "7426", "1426", "8691", "8918", "1", "2","3","4","54%",{4:"aboveP25",9:"aboveP25",10:"belowP25"}),
  // createData("09", "Jr. Engineer/ Executive", "4226", "4567","6545", "6456", "1", "2","3","4","18%",{4:"aboveP25",9:"aboveP25",10:"belowP25"}),
  // createData("39", "Head D1", "7426", "1426", "8691", "8918", "1", "2","3","4","54%",{4:"aboveP25",9:"aboveP25",10:"belowP25"}),
  // createData("09", "Jr. Engineer/ Executive", "4226", "4567", "6545", "6456", "1", "2","3","4","18%",{4:"aboveP25",9:"aboveP25",10:"belowP25"}),
  // createData("39", "Head D1", "7426", "1426", "8691", "8918", "1", "2","3","4","54%",{4:"aboveP25",9:"aboveP25",10:"belowP25"}),
  // createData("09", "Jr. Engineer/ Executive", "4226", "4567", "6545", "6456", "1", "2","3","4","18%",{4:"aboveP25",9:"aboveP25",10:"belowP25"}),
  // createData("39", "Head D1", "7426", "1426", "8691","8918", "1", "2","3","4","54%",{4:"aboveP25",9:"aboveP25",10:"belowP25"}),
  // ]

  useEffect(() => {
    if (props.primKey) {
      dispatch(showLoader(true));
      const url = `/api/report/${props.primKey}?chartType=table`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data && data.rows.length > 0) {
            // let head = [];
            // Object.keys(data.rows[0].cellValues).forEach((el) => {
            //   head.push({
            //     id: el,
            //     label: el,
            //     disableSorting: true,
            //     edit: true,
            //   });
            // });
            const head = Object.keys(data.rows[0].cellValues).map((el) => {
              return {
                id: el,
                label: el,
                disableSorting: true,
                edit: true,
              }
            });

            setHeadCellsData(head);
            // let rows = [];
            // data.rows.forEach((el) => {
            //   rows.push(el.cellValues);
            // });
            const rows = data.rows.map((el) => el.cellValues);
            setTotalRowsData(rows);
            setRowCount(rows.length);
            setRowData(paginate(rows, 1));
            dispatch(showLoader(false));
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(showLoader(false));
        });
    }
  }, []);
  return (
    <div>
      {rowData.length > 0 && (
        <>
          <TableLegends
            legendsArray={[
              { name: "P= Percentile" },
              { name: "Below P25", background: "rgba(218, 41, 28, 0.5)" },
              { name: "P25-P50", background: "#BBBCBC" },
              { name: "P50-P75", background: "#FFCD00" },
              { name: "Above P75", background: "#00ABAB" },
            ]}
          />
          <MasTable
            // mainHeadCell={mainHeadCellData}
            rows={rowData}
            headCells={headCellsData}
            colorProperties={colorProperties}
            showAllWhiteShade={true}
            stickyHeader="true"
            // isPagination={true}
            returnPageNo={handlePageChange}
            showPagination={true}
            rowCount={rowCount}
          />
        </>
      )}
    </div>
  );
};

export default CompensationAnalysisTable;
