import React, { useState, useEffect } from "react";
import "./requests.scss";
import MasSelect from "../../domain-component/MasSelect";
import MasContainer from "../../domain-component/MasContainer";
import MasTextField from "../../domain-component/MasTextField";
import { Breadcrumbs, Link, Typography, Grid } from "@mui/material";
import MasLinkButton from "../../domain-component/MasLinkButton";
import MasButton from "../../domain-component/MasButton";
import Table from "../../domain-component/MasTable/Table";
import icon from "../../modules/ManagerPersona/Images/manager-icon.svg";

import { useNavigate } from "react-router-dom";

const Requests = () => {
  const Breadcrumb = () => {
    return (
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ fontFamily: "SourceSansProRegular" }}
      >
        <Link
          underline="hover"
          onClick={() => {
            navigate("/home");
          }}
        >
          Home
        </Link>
        <Typography sx={{ fontWeight: "600", fontFamily: "SourceSansProBold" }}>
          Requests
        </Typography>
      </Breadcrumbs>
    );
  };
  const colorProperties = {
    headCellsBackground: "#b7dde2",
    headCellsColor: "#53565A",
    overallBackground: "#E3E4E4",
  };
  const navigate = useNavigate();
  const headCells = [
    {
      id: "requestNo",
      label: "Request No.",
      disableSorting: false,
    },
    {
      id: "userName",
      label: "User Name",
      disableSorting: false,
    },
    {
      id: "contactNo",
      label: "Contact No.",
      disableSorting: false,
    },
    {
      id: "requestDate",
      label: "Request Date",
      disableSorting: false,
    },
    {
      id: "email",
      label: "Email Address",
      disableSorting: false,
    },
    {
      id: "companyName",
      label: "Company Name",
      disableSorting: true,
    },
    {
      id: "status",
      label: "Status",
      disableSorting: false,
    },
  ];

  function createData(
    requestNo,
    userName,
    contactNo,
    requestDate,
    email,
    companyName,
    status
  ) {
    return {
      requestNo,
      userName,
      contactNo,
      requestDate,
      email,
      companyName,
      status,
    };
  }
  const rows = [
    createData(
      "223",
      "Sofia Carson",
      "223456789",
      "August 1,2022",
      "Sofia@amazon.com",
      "Amazon",
      "Approved"
    ),
    createData(
      "441",
      "Corcodel,Razvan",
      "667890900112",
      "August 12,2022",
      "Corcodel.razvan@oracle.in",
      "Oracle",
      "Rejected"
    ),
    createData(
      "223",
      "Sofia Carson",
      "223456789",
      "August 1,2022",
      "Sofia@amazon.com",
      "Amazon",
      "Approved"
    ),
    createData(
      "441",
      "Corcodel,Razvan",
      "667890900112",
      "August 12,2022",
      "Corcodel.razvan@oracle.in",
      "Oracle",
      "Rejected"
    ),
    createData(
      "223",
      "Sofia Carson",
      "223456789",
      "August 1,2022",
      "Sofia@amazon.com",
      "Amazon",
      "Approved"
    ),
    createData(
      "441",
      "Corcodel,Razvan",
      "667890900112",
      "August 12,2022",
      "Corcodel.razvan@oracle.in",
      "Oracle",
      "Rejected"
    ),
    createData(
      "223",
      "Sofia Carson",
      "223456789",
      "August 1,2022",
      "Sofia@amazon.com",
      "Amazon",
      "Approved"
    ),
    createData(
      "441",
      "Corcodel,Razvan",
      "667890900112",
      "August 12,2022",
      "Corcodel.razvan@oracle.in",
      "Oracle",
      "Rejected"
    ),
    createData(
      "223",
      "Sofia Carson",
      "223456789",
      "August 1,2022",
      "Sofia@amazon.com",
      "Amazon",
      "Approved"
    ),
    createData(
      "441",
      "Corcodel,Razvan",
      "667890900112",
      "August 12,2022",
      "Corcodel.razvan@oracle.in",
      "Oracle",
      "Rejected"
    ),
    createData(
      "223",
      "Sofia Carson",
      "223456789",
      "August 1,2022",
      "Sofia@amazon.com",
      "Amazon",
      "Approved"
    ),
    createData(
      "441",
      "Corcodel,Razvan",
      "667890900112",
      "August 12,2022",
      "Corcodel.razvan@oracle.in",
      "Oracle",
      "Rejected"
    ),
  ];

  return (
    <div className="my-subscription-container">
      <MasContainer>
        <div>
          <h1 class="mas-text-h1">Requests</h1>
          <Breadcrumb />
        </div>

        <div className="request-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
          purus sit amet luctus venenatis, lectus magna fringilla urna,
          porttitor rhoncus dolor purus non enim praesent elementum facilisis
          leo, vel fringilla est ullamcorper.
        </div>

        <div className="filter-section">
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={12} md={0}></Grid>
            <div className="act-table">
              <div className="all">
                All <span className="all-num">32</span>
              </div>
              <div className="pending">
                Pending <span className="pending-num">12</span>
              </div>
              <div className="approved">
                Approved <span className="approved-num">12</span>
              </div>
              <div className="rejected">
                Rejected <span className="rejected-num">10</span>
              </div>
            </div>
          </Grid>
        </div>

        <div className="filter-section">
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={12} md={3}>
              <MasButton variant="outlined" label={"Reject"} />
              <MasButton variant="outlined" label={"Approve"} />
            </Grid>

            <Grid item xs={12} sm={12} md={8}>
              <div>
                <MasTextField height="0px" placeholder={"Search"} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <div className="">
                <img src={icon}></img>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="filter-section">
          <Grid container alignItems="flex-end" justifyContent={"left"}>
            <Grid item xs={12} sm={12} md={12}>
              {/* <Table headCells={mySubscriptionsCols} rows={viewSubscriptionData} returnPageNo={handlePageChange} showPagination /> */}
              <Table
                headCells={headCells}
                rows={rows}
                link={true}
                stickyHeader="true"
                colorProperties={colorProperties}
                showAllWhiteShade={false}
              />
            </Grid>
          </Grid>
        </div>
      </MasContainer>
    </div>
  );
};

export default Requests;
