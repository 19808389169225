import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrum from "../Breadcrum/breadcrum";
import FirstContent from "../FirstContent/firstContent";
import MasButton from "../MasButton/masButton";
import AllReportsTemplate from "./AllReportsTemplate/allReportsTemplate";
import "./reportsPage.scss";
import Download from "../../assets/images/Download.png";
import { Link, useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../utility/common/api";

const ReportsPage = () => {
  // const params=useParams();
  const navigate = useNavigate();
  const [reportGenReqData, setReportGenReqData] = useState(null);
  const id = sessionStorage.getItem("id");
  useEffect(() => {
    if (id) {
      const url = `/api/approvaldisplay/${id}`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data) {
            setReportGenReqData(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <div>
      {reportGenReqData && (
        <div className="report-first-content">
          <FirstContent showBack="true" header="Files Attached" />
          <div className="breadcrum-div">
            <Breadcrum />
            <div className="report-first-subheader">
              <Typography variant="body2" className="font8">
                Click on the guide to understand terms and abbrevations used in
                the reports.
              </Typography>
              <button className="font8 green-outlined-btn">
                Deloitte Guide
              </button>
              {/* <div className='report-first-subheader-button'>
                        <Typography variant='body2'>Select Files</Typography>
                        <div className='report-download-button'>
                            <img src={Download} style={{width:"30px"}}/>
                        </div>
                    </div> */}
            </div>
          </div>
          <div className="report-first-main-section">
            <AllReportsTemplate
              showManagerPersona={false}
              data={{
                id: id,
                primaryKey: reportGenReqData.approvalRest.parentId,
                chartTypes: reportGenReqData.peersetRest.chartTypes,
              }}
            />
            <div className="reports-button-section">
              <Link to="/dashboard">
                <button className="font8 green-outlined-btn">
                  Back to Dashboard
                </button>
              </Link>
              <div
                onClick={() => {
                  navigate(`/onereport`);
                }}
              >
                <button className="font8 green-btn">Proceed</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportsPage;
