import React, { useState, useEffect } from "react";
import "../PartnerManagerMisc.scss";
import "./LeftContent.scss";
import up1 from "./Images/up1.svg";
import up2 from "./Images/up2.svg";
import edit from "./Images/edit.svg";
import { useNavigate } from "react-router-dom";
import api from "../../../utility/common/api";

function LeftContent(props) {
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [showProceedToGenerateReport, setShowProceedToGenerateReport] =
    useState(false);

  useEffect(() => {
    let d = [];
    if (props.summaryData.peersetCompanyRests) {
      props.summaryData.peersetCompanyRests.forEach((el) => {
        d.push(el.comapanyName);
      });
      setHistory(d);
      setShowProceedToGenerateReport(
        props.summaryData.peersetRest.isFileUploaded
      );
    }
  }, []);

  const saveStatus = () => {
    const url = `/api/peerset/${props.primKey}`;
    const data = { isFileUploaded: true };
    api
      .patch({ url, data })
      .then(({ data }) => {
        if (data.isFileUploaded) {
          setShowProceedToGenerateReport(data.isFileUploaded);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const uploadFileHandler = (event) => {
    const url = `/api/masterdata/upload/${props.primKey}`;
    let data = new FormData();
    data.append("file", event.target.files[0]);
    api
      .post({ url, data })
      .then(({ data }) => {
        if (data == true) {
          saveStatus();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onDeclineRequest = () => {
    const url = "/api/approval/" + props.reqNo;
    const data = { approvalStatus: "DECLINED" };
    api
      .patch({ url, data })
      .then(({ data }) => {
        if (data.approvalStatus == "DECLINED") {
          navigate("/manager-dashboard");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onApproveRequest = () => {
    const url = "/api/approval/" + props.reqNo;
    const data = { approvalStatus: "APPROVED" };
    api
      .patch({ url, data })
      .then(({ data }) => {
        if (data.approvalStatus == "APPROVED") {
          navigate("/manager-dashboard");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <div id="partnerManagerLeftContent">
        <div id="partnerManagerCompSummary">Summary</div>
        <div className="left-content-details">
          <div className="left-content-details-row">
            <div id="username" className="left-content-details-item ">
              User Name
              <div id="usernameText" className="label-text2">
                {props.summaryData.userRest.userName}
              </div>
            </div>
            <div id="companyName" className="left-content-details-item">
              Company Name
              <div id="companyNameText" className="label-text2">
                {props.summaryData.userRest.companyName}
              </div>
            </div>
          </div>
          <div className="left-content-details-row">
            <div id="number" className="left-content-details-item">
              Contact No.
              <div id="numberText" className="label-text2">
                {props.summaryData.userRest.contactNumber}
              </div>
            </div>
            <div id="mail" className="left-content-details-item">
              Email id
              <div id="mailText" className="label-text2">
                {props.summaryData.userRest.companyEmailId}
              </div>
            </div>
          </div>
          <div className="left-content-details-row">
            <div id="sector" className="left-content-details-item">
              Sector
              <div id="sectorText" className="label-text2">
                Bank Non Restricted
              </div>
            </div>
            {props.showSampling ? (
              <>
                <div id="sampling" className="left-content-details-item">
                  Sampling Required
                  <div id="samplingText" className="label-text2">
                    Not Required
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="left-content-details-row">
            {props.showAnchor ? (
              <>
                <div id="anchor" className="left-content-details-item">
                  Main Anchor for Review <img src={edit} id="anchorEdit" />
                  <div id="anchorText" className="label-text2">
                    Total Fixed Pay
                  </div>
                </div>
              </>
            ) : null}

            {props.showApprover ? (
              <>
                <div id="approver" className="left-content-details-item">
                  Approver
                  <div id="approverText" className="label-text2">
                    Karan, Anand
                  </div>
                </div>
                <div id="assigned" className="left-content-details-item">
                  Assigned To
                  <div id="assignedText" className="label-text2">
                    Vijay, Saumya, Kuldeep
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="left-content-details-row">
            {props.showPeerSet ? (
              <>
                <div id="peerSet" className="left-content-details-item">
                  Peer Set
                  <div id="peerSetText" className="label-text2">
                    {history.map((e) => (
                      <span key={e}> {e}, </span>
                    ))}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {props.showUploadBtn && !showProceedToGenerateReport ? (
          <>
            <input
              type="file"
              name="myfile"
              multiple
              required
              className="incumbent-upload-file"
              id="file-upload-id"
              onChange={(event) => uploadFileHandler(event)}
            />
            <button id="uploadCompFiles">
              <label htmlFor="file-upload-id">
                Upload Companies Compensation File
              </label>
              <div id="upIcon">
                <img src={up1} id="up1" />
                <img src={up2} id="up2" />
              </div>
            </button>
          </>
        ) : null}
      </div>
      <div id="bottomBtns">
        <button
          id="dashboard"
          onClick={() => {
            navigate("/manager-dashboard");
          }}
        >
          Back to Dashboard
        </button>
        {props.show3Btns && showProceedToGenerateReport ? (
          <>
            <button
              id="genReport"
              onClick={() => {
                navigate(`/reportsView`);
              }}
            >
              Proceed to Generate Report
            </button>
          </>
        ) : null}
        {props.show2Btns ? (
          <>
            <button id="declineReq" onClick={onDeclineRequest}>
              Decline Request
            </button>
            <button id="approvedGreen" onClick={onApproveRequest}>
              Approved
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default LeftContent;
