import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Outlet,
  Routes,
  Route,
  redirect,
  useLocation,
  Navigate,
} from "react-router-dom";
import NavBar from "./navbar";
import { useNavigate } from "react-router-dom";
import LoginExternal from "./Login/LoginExternal";
import ForgotPassword from "./Login/ForgotPassword";
import RequestLoginAccess from "./Login/RequestLoginAccess";
import RequestLoginAcknowledge from "./Login/RequestLoginAcknowledge";
import ManagerDashboard from "./ManagerPersona/ManagerDashboard";
import DataTeamDashboard from "./DataTeamPersona/DataTeamDashboard";
import Requests from "./Admin/Requests";
import PartnerDashboard from "./PartnerPersona/PartnerDashboard";
import Dashboard from "./Dashboard/dashboard";
import Onboarding from "./Onboarding/onboarding";
import IncumbentSubmission from "./Onboarding/OnboardingSecondPage/IncumbentSubmission/incumbentSubmission";
import UploadSalary from "./Onboarding/OnboardingSecondPage/UploadSalary/uploadSalary";
import ReportTables from "./Reports/ReportTables/reportTables";
import BarChart from "./Reports/BarChart/barChart";
import StackedBarChart from "./Reports/StackedBarChart/stackedBarChart";
import MixedBarLineChart from "./Reports/MixedBarLineChart/mixedBarLineChart";
import ScatterPlot from "./Reports/ScatterPlot/scatterPlot";
import LineChart from "./Reports/LineChart/lineChart";
import ReportsPage from "./Reports/reportsPage";
import OneReportTemplate from "./Reports/OneReportTemplate/oneReportTemplate";
import CompensationAnalysisTable from "./Reports/CompensationAnalysisTable/compensationAnalysisTable";
import CollapsibleTable from "./Reports/CollapsibleTable/CollapsibleTable";
import Header from "./Header/header";
import ReportsView from "./ManagerPersona/ReportsView/ReportsView";
import FinalReports from "./ManagerPersona/FinalReports/FinalReports";
import Compensation1 from "./Compensation/Compensation1";
import Compensation from "./Compensation/Compensation";
import Compensation2 from "./Compensation/Compensation2";
import Peerset1 from "./PeerSet/Peerset1";
import Peerset2 from "./PeerSet/Peerset2";
import Peerset3 from "./PeerSet/Peerset3";
import Peerset4 from "./PeerSet/Peerset4";
import PartnerCompDateReq from "./PartnerPersona/PartnerCompDateReq";
import ManagerCompDateReq from "./ManagerPersona/ManagerCompDateReq";
import ReportGenReq from "./ManagerPersona/ReportGenReq";
import PartnerPeerReq from "./PartnerPersona/PartnerPeerReq";
import PartnerReportGenReq from "./PartnerPersona/PartnerReportGenReq";
import ManagerPeerReq from "./ManagerPersona/ManagerPeerReq";
import ReportGenReq2 from "./ManagerPersona/ReportGenReq2";
import ManagerDipstick from "./ManagerPersona/ManagerDipstick";
import Reports1 from "./Reports/Reports1";
import Reports3 from "./Reports/Reports3";
import Comm1 from "./Community/Comm1";
import Comm2 from "./Community/Comm2";
import Comm3 from "./Community/Comm3";
import Comm4 from "./Community/Comm4";
import Dipstickq1 from "./DipsticksSurvey/DipstickSurvey";
import DipstickAckn from "./DipsticksSurvey/DipstickAckn";
import DataAssign from "./AssignData/AssignData";
import Final from "./ManagerPersona/MultiFileUpload/Final";
import DocumentHistory from "./ManagerPersona/DocumentHistory/DocumentHistory";
import Autoreview from "./ManagerPersona/Autoreview/Autoreview";

const RoutedContent = () => {
  const navigate = useNavigate();
  const login = sessionStorage.getItem("Login");
  const tier = process.env.REACT_APP_TIER;

  const location = useLocation();
  const navWithoutAuthURL = ["/login-request-acknowledged"];
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = localStorage.getItem("authentication-token");
    if (
      (!userToken || userToken === "undefined") &&
      !navWithoutAuthURL.includes(location.pathname)
    ) {
      setIsLoggedIn(false);
      return navigate("/login-external");
    }
    setIsLoggedIn(true);
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return (
    <>
      {location.pathname === "/login-option" ||
      location.pathname === "/login-external" ||
      location.pathname === "/forgot-password" ||
      location.pathname === "/login-request" ||
      location.pathname === "/login-request-acknowledged" ? (
        <Routes>
          <Route path="/:page" element={<NavBar />} />
          <Route path="/:page/:id" element={<NavBar />} />
          <Route path="/login-external" element={<LoginExternal />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/login-request" element={<RequestLoginAccess />} />
          <Route
            path="/login-request-acknowledged"
            element={<RequestLoginAcknowledge />}
          />
        </Routes>
      ) : (
        <>
          <Header />
          <Routes>
            {tier === "External" && <Route path="/" element={<NavBar />} />}
            <Route path="" element={<NavBar />} />
            <Route path="/:page" element={<NavBar />} />
            <Route path="/:page/:id" element={<NavBar />} />

            <Route path="/onboarding" element={<Onboarding />} />

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/manager-dashboard" element={<ManagerDashboard />} />
            <Route path="/datateam-dashboard" element={<DataTeamDashboard />} />
            <Route path="/partner-dashboard" element={<PartnerDashboard />} />
            <Route path="/incumbent" element={<IncumbentSubmission />} />
            <Route path="/uploadSalary" element={<IncumbentSubmission />} />
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/requests" element={<Requests />} />
            <Route path="/reportTables" element={<ReportTables />} />
            <Route path="/barChart" element={<BarChart />} />
            <Route path="/stackedBarChart" element={<StackedBarChart />} />
            <Route path="/mixedBarLineChart" element={<MixedBarLineChart />} />
            <Route path="/scatterPlot" element={<ScatterPlot />} />
            <Route path="/lineChart" element={<LineChart />} />
            <Route path="/onereport" element={<OneReportTemplate />} />
            <Route
              path="/compensationAnalysisTable"
              element={<CompensationAnalysisTable />}
            />
            <Route path="/collapsibleTable" element={<CollapsibleTable />} />
            <Route path="/reportsView" element={<ReportsView />} />
            <Route path="/finalReports" element={<FinalReports />} />
            <Route path="/compDataUpload1" element={<Compensation />} />
            <Route path="/compDataUpload2" element={<Compensation1 />} />
            <Route path="/compDataUpload3" element={<Compensation2 />} />
            <Route path="/peerset1" element={<Peerset1 />} />
            <Route path="/peerset2" element={<Peerset2 />} />
            <Route path="/peerset3/:id" element={<Peerset3 />} />
            <Route path="/peerset4" element={<Peerset4 />} />
            <Route path="/dipstick-surveyq1" element={<Dipstickq1 />} />
            <Route path="/survey-ack" element={<DipstickAckn />} />
            <Route path="/assign-data" element={<DataAssign />} />
            <Route
              path="/partnerCompDataReq/:id"
              element={<PartnerCompDateReq />}
            />
            <Route
              path="/managerCompDateReq/:id"
              element={<ManagerCompDateReq />}
            />
            <Route
              path="/managerDipstickSurvey/:id"
              element={<ManagerDipstick />}
            />
            <Route path="/managerReportGenReq" element={<ReportGenReq />} />
            <Route path="/partnerPeerReq/:id" element={<PartnerPeerReq />} />
            <Route
              path="/partnerReportGenReq/:id"
              element={<PartnerReportGenReq />}
            />
            <Route path="/managerPeerReq/:id" element={<ManagerPeerReq />} />
            <Route path="/managerReportGenReq2" element={<ReportGenReq2 />} />
            <Route path="/reports1" element={<Reports1 />} />
            <Route path="/reports3/:id" element={<Reports3 />} />
            <Route path="/comm1" element={<Comm1 />} />
            <Route path="/comm2" element={<Comm2 />} />
            <Route path="/comm3" element={<Comm3 />} />
            <Route path="/comm4" element={<Comm4 />} />
            <Route path="/multiFileUpload" element={<Final />} />
            <Route
              path="/multiFileUploadHistory"
              element={<DocumentHistory />}
            />
             <Route
              path="/multiFileUploadreview"
              element={<Autoreview/>}
            />
          </Routes>

          <Outlet />
        </>
      )}
    </>
  );
};

export default RoutedContent;
