import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/lab/Pagination";

export default function MasPagination({
  count,
  size = "large",
  page = 1,
  variant = "outlined",
  shape = "rounded",
  onChange,
  ...otherProps
}) {

    const StyledPagination = styled(Pagination)(({ theme }) => ({
        '& ul > li:not(:first-of-type):not(:last-child) > button:not(.Mui-selected)': {
            borderRadius: "0px",
            backgroundColor: '#FFF',
            color: '#000000',
            borderRadius: '0px 2px 2px 0px'
        },
        '& .Mui-selected': {
            backgroundColor: '#000000!important',
            borderRadius: "0px",
            color: "#FFF",
        },
        '& .MuiPaginationItem-root': {
            borderRadius: "0px",
            margin: "0px",
            fontFamily: "SourceSansProSemiBold",
        },
        '& .MuiPaginationItem-previousNext': {
            borderRadius: "0px",
            borderRadius: '0px 2px 2px 0px',
            color: '#000000',
        }
    }));
    return (
        <StyledPagination count={count}
            size={size}
            page={page}
            variant={variant}
            onChange={onChange}
            {...otherProps}
        />
    )
}
