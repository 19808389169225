import React from "react";
import {
  FormControl,
  TextField,
  TextareaAutosize,
  InputAdornment,
  styled,
} from "@mui/material";
import { textFieldClasses } from "@mui/material/TextField";
import "./styles.scss";

const MasTextField = ({
  label,
  value,
  defaultValue,
  placeholder,
  isWidth,
  width,
  height,
  type,
  maxLength,
  inputProps,
  isRequiredLabel,
  subLabel,
  isTextArea,
  disabled,
  onChange,
  styledClass,
  name,
  handleKeyDown,
  errorMessage,
  maxWidth,
  isMaxWidth,
  onClick,
  autoComplete,
  icon,
  InputProps,
}) => {
  const errStatus = errorMessage ? true : false;
  return (
    <div className={`${styledClass} mas-textfield`}>
      {label && !isRequiredLabel && (
        <span className="mas-textlabel">{label}</span>
      )}
      {label && isRequiredLabel && (
        <span className="mas-textlabel">
          {label}
          <span className="mas-required">*</span>
        </span>
      )}
      {subLabel && <span className="mas-text-sub-label">{subLabel}</span>}
      <FormControl className="form-control" sx={{ m: 1, width: "100%" }}>
        {!isTextArea ? (
          <TextField
            onKeyDown={handleKeyDown}
            type={type}
            value={value}
            // id="mas-textfield"
            error={errStatus}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onClick={onClick}
            autoComplete={autoComplete}
            size="small"
            sx={{
              ...(isWidth
                ? { width: width, background: "#FFF", maxWidth: width }
                : {}),
              fontFamily: "SourceSansProRegular !important",
            }}
            maxLength={maxLength}
            inputProps={{ maxLength: maxLength }}
            disabled={disabled}
            onChange={onChange}
            name={name}
            className={disabled ? "disabled-input" : "mas-textfield"}
            InputProps={
              InputProps
                ? InputProps
                : {
                    endAdornment: (
                      <InputAdornment position="end">{icon}</InputAdornment>
                    ),
                  }
            }
          />
        ) : (
          <TextareaAutosize
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            style={{
              width: isMaxWidth ? maxWidth : width,
              minHeight: height,
              padding: "8.5px 14px",
              m: 1,
              font: "inherit",
              fontSize: "16px",
              color: "currentColor",
              background: disabled ? "#F2F2F2" : "#FFF",
              pointerEvents: disabled ? "none" : "auto",
            }}
            onKeyDown={handleKeyDown}
            // disabled={disabled}
            onChange={onChange}
            name={name}
          />
        )}
      </FormControl>
      {errorMessage && <span className="error">{errorMessage}</span>}
    </div>
  );
};

MasTextField.defaultProps = {
  label: "",
  defaultValue: "",
  place: "Enter Value",
  isRequiredLabel: false,
  subLabel: "",
  placeholder: "",
  isTextArea: false,
  height: 117,
  width: 240,
  styledClass: "",
  onChange: () => {},
  handleKeyDown: () => {},
  name: "",
  maxLength: 5000,
  maxWidth: "",
  disabled: false,
  autoComplete: "off",
};

export default MasTextField;
