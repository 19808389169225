import { Typography } from "@mui/material";
import React, { useState } from "react";
import "./ReportsBox.scss";
import RightArrowHead from "../../../assets/images/rightArrowHead.png";
import { useNavigate } from "react-router-dom";
import { getTranslation } from "../../../utility/i18n/i18n";
const ReportsBox = () => {
  const navigate = useNavigate();


  return (
    <div className="reports">
      <div className="reports-header">
        <Typography variant="subtitle3" className="reports-header-text font13">
          View Generated Reports
        </Typography>
        <div className="reports-number">
          <Typography variant="caption" className="reports-number-text font7">
            01
          </Typography>
        </div>
        <img src={RightArrowHead} className="reports-image" />
      </div>
      <div className="reports-box">
        <Typography variant="body2" className="font3">
        {getTranslation("dashboard")["description4"]}
        </Typography>

      </div>
      <div className="reports-button">
        <Typography
          variant="body2"
          className="green-button-font"
          onClick={() => {
            navigate("/reports1");
          }}
        >
          View Generated Reports
        </Typography>
      </div>
    </div>
  );
};

export default ReportsBox;
