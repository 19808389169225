import React, { useState, useEffect } from "react";
import "./peerset3.scss";
import reviewIcon from "./Images/review.svg";
import BigCirc from "./Images/bigCirc.svg";
import SmallCirc from "./Images/smallCirc.svg";
import VertLine from "./Images/vertLine.svg";
import PeerHead from "./PeerHead";
import PeersetRC from "./PeersetRC";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utility/common/api";

function Peerset3() {
  const navigate = useNavigate();
  let params = useParams();
  const [peerRequestData, setPeerRequestData] = useState([]);
  const getCreatedDate = (d) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return (
      monthNames[d.getMonth()] +
      " " +
      d.getDate() +
      ", " +
      d.getFullYear() +
      " @ " +
      d.getHours() +
      ":" +
      d.getMinutes()
    );
  };
  useEffect(() => {
    if (params.id) {
      const url = `/api/peerset/${params.id}`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data) {
            setPeerRequestData(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const empName = (names, time) => {
    return (
      <div className="comp-emp">
        {names}
        <br></br>
        <p className="time">{time}</p>
      </div>
    );
  };

  return (
    <div>
      <PeerHead peerHeadCont={"Create Peer Sets"} />
      <div className="flexRow">
        <div id="peer3LC">
          <div id="peer3submit" className="font10">
            Your Peer Set Request Is Submitted
          </div>
          <div id="peer3submitdesc" className="font12">
            Your custom peer set request is submitted and send ahead to our{" "}
            <br></br>team, we will get intouch with you shortly for any further
            actions
          </div>
          <div className="flexRow">
            <div className="flexCol">
              <img src={BigCirc} id="peerBigCirc1" />
              <img src={SmallCirc} id="peerSmallCirc" />
              <img src={VertLine} id="peerVertLine" />
              <img src={BigCirc} id="peerBigCirc2" />
              <img src={SmallCirc} id="peerSmallCirc" />
            </div>
            <div>
              <div id="peerEmp1">
                {empName(
                  peerRequestData.username,
                  getCreatedDate(new Date(peerRequestData.time))
                )}
              </div>
              <div id="peerEmps">
                {empName(
                  peerRequestData.managerName,
                  getCreatedDate(new Date(peerRequestData.mangerTime))
                )}
              </div>
            </div>
            <div>
              <div id="peer3Req">
                <div id="peer3ReqGreen">{peerRequestData.peersetGropuName}</div>
                <div id="peer3ReqGrey">request submitted successfully</div>
              </div>
              <div id="peer3review" className="flexRow">
                <img id="peer3reviewIcon" src={reviewIcon} />
                <span id="peer3reviewText">Under Review</span>
              </div>
            </div>
          </div>

          <button id="peer3return" onClick={() => navigate("/dashboard")}>
            Return Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default Peerset3;
