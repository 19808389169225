import React from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableLegends from "../TableLegends/tableLegends";

function createData(group, q1, q2, q3, q4, background) {
  return {
    group,
    q1,
    q2,
    q3,
    q4,
    background,
  };
}

const internalRow = [
  createData("Services", "33%", "44%", "21%", "55%"),
  createData("Sales", "33%", "44%", "21%", "55%"),
  createData("Supply Chain", "33%", "44%", "21%", "55%"),
  createData("R&D", "33%", "44%", "21%", "55%"),
  createData("Manufacturing", "33%", "44%", "21%", "55%"),
  createData("Marketing", "33%", "44%", "21%", "55%"),
  createData("", "33%", "44%", "21%", "55%", "#F2F6C9"),
  createData("", "33%", "44%", "21%", "55%", "#BBBCBC"),
];

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(true);
  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" }, background: "#D9EAE4" }}
      >
        <TableCell component="th" scope="row" sx={{ color: "#53565A" }}>
          {row.group}
        </TableCell>
        <TableCell component="th" scope="row" colSpan={4} align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon sx={{ color: "#000000" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "#000000" }} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box z>
              <Table aria-label="data">
                <TableBody>
                  {internalRow.map((elem, key) => (
                    <TableRow
                      key={key}
                      sx={{
                        background: elem.background
                          ? elem.background
                          : "#FFFFFF",
                      }}
                    >
                      <TableCell sx={{ width: "170px", color: "#000000" }}>
                        {" "}
                        {elem.group}
                      </TableCell>
                      <TableCell sx={{ width: "170px", color: "#000000" }}>
                        {" "}
                        {elem.q1}
                      </TableCell>
                      <TableCell sx={{ width: "170px", color: "#000000" }}>
                        {" "}
                        {elem.q2}
                      </TableCell>
                      <TableCell sx={{ width: "170px", color: "#000000" }}>
                        {" "}
                        {elem.q3}
                      </TableCell>
                      <TableCell sx={{ width: "170px", color: "#000000" }}>
                        {" "}
                        {elem.q4}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const rows = [
  createData("Unitary Products"),
  createData("Commercial Air Conditioning"),
];

export default function CollapsibleTable() {
  return (
    <>
      <TableLegends
        legendsArray={[
          { name: "Grand Total", background: "#F3F6C9" },
          { name: "Grand Total(2020)", background: "#BBBCBC" },
        ]}
      />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{ background: "#004F59" }}>
              <TableCell sx={{ width: "170px", color: "#FFFFFF" }}>
                Group Name
              </TableCell>
              <TableCell sx={{ width: "170px", color: "#FFFFFF" }}>
                Q1(Below 25)
              </TableCell>
              <TableCell sx={{ width: "170px", color: "#FFFFFF" }}>
                Q2 (Between P25 & P50)
              </TableCell>
              <TableCell sx={{ width: "170px", color: "#FFFFFF" }}>
                Q3 (Between P50 & P75)
              </TableCell>
              <TableCell sx={{ width: "170px", color: "#FFFFFF" }}>
                Q4 (Above P75)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

// export default CollapsibleTable;
