import React, { useState, useEffect } from "react";
import PartnerManagerTemplate from "../PartnerManager/PartnerManagerTemplate/PartnerManagerTemplate.jsx";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utility/common/api";
import MasContainer from "../../domain-component/MasContainer";
import "./ManagerDipstick.scss";

function ManagerDipstick() {
  const params = useParams();
  const navigate = useNavigate();
  const [survey, setSurvey] = useState({
    quest1: {
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ?",
      options: [],
    },
    quest2: {
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ?",
      options: [],
    },
    quest3: {
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ?",
      options: [],
    },
  });

  let ar = [1, 2, 3];
  useEffect(() => {
    if (params.id) {
      const url = `/api/survey/${params.id}`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data) {
            setSurvey((prevState) => ({
              ...prevState,
              quest1: {
                ...prevState.quest1,
                options: data.answerOne,
              },
              quest2: {
                ...prevState.quest2,
                options: data.answerTwo,
              },
              quest3: {
                ...prevState.quest3,
                options: data.answerThree,
              },
            }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <div>
      <div className="my-subscription-container">
        <MasContainer>
          <div>
            <h1 className="mas-text-h1">Dipsticks Survey Response</h1>
          </div>
          <div className="hed-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus magna fringilla urna,
            porttitor rhoncus dolor purus non enim praesent elementum facilisis
            leo, vel fringilla est ullamcorper.
          </div>

          <div className="survey-frame">
            {ar.map((item, index) => (
              <div key={index} className="survey-qa">
                {/* <div >{index}/3</div> */}
                <div className="surveyQuest">
                  {index + 1} : {survey[Object.keys(survey)[index]]["question"]}
                </div>
                <div>
                  <ul>
                    {survey[Object.keys(survey)[index]]["options"].map(
                      (item, i) => (
                        <li key={i} className="surveyAns">
                          {item}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            ))}
            <button
              id="dashboard"
              onClick={() => {
                navigate("/manager-dashboard");
              }}
            >
              Back to Dashboard
            </button>
          </div>
        </MasContainer>
      </div>
    </div>
  );
}

export default ManagerDipstick;
