import React from "react";
import './Final.scss';
import FormUpload_new from "./Form/FormUpload_new";
const Final = () => {
  return (
    <>
      <div className="main_container">
          <div className="font2">Upload Your File For Auto Review</div>
          
          <FormUpload_new/>
      </div>
    </>
  );
};

export default Final;
