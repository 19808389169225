import React from "react";
import PartnerManagerTemplate from "../PartnerManager/PartnerManagerTemplate/PartnerManagerTemplate.jsx";

function PartnerReportGenReq() {
  return (
    <div>
      <PartnerManagerTemplate
        showBlackHeadArrow={true}
        heading={"Request No. 223"}
        nav1={"Dashboard"}
        nav2={"Request No. 223"}
        nav1col={"navGreen"}
        nav2col={"navBlack"}
        showPeerSet={true}
        showSent={true}
        showSampling={true}
        showAnchor={true}
        showVertLine6={true}
        managerTimeline={true}
        vertLineMargin={"116px"}
        showPeersetDownload={false}
      />
    </div>
  );
}

export default PartnerReportGenReq;
