import React from "react";
import { useNavigate } from "react-router-dom";

function CompensationTwoBtns(props) {
  const navigate = useNavigate();
  const navigateToCompUpdate = () => {
    navigate("/compDataUpload2");
  };
  return (
    <div>
      <div className="flexRow">
        <div>
          <div id="comp-u">Upload Updated File</div>
          <div id="comp-u2">JPG, PDF or PNG. Max size of 20Mb</div>
        </div>
        <button id="comp-chooseFile">Choose file</button>
      </div>
      <div className="flexRow">
        <button
          id="comp-submit"
          className="flex-container"
          onClick={navigateToCompUpdate}
        >
          Submit
        </button>
        <button id="comp-help">Need Help? Contact us</button>
      </div>
    </div>
  );
}

export default CompensationTwoBtns;
