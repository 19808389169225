import Backdrop from "@mui/material/Backdrop";
import MasSpinner from "../MasSpinner";

export default (props) => {
  const {
    open = false,
    sx = { backgroundColor: "rbga(0,0,0,0.35}!important" },
  } = props;
  return (
    <Backdrop open={open} sx={{ ...sx }}>
      <MasSpinner />
    </Backdrop>
  );
};
