import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import api from "../../../utility/common/api";
const BarChart = () => {
  // const apiRes = {"xAxis":"Count","yAxis":"Role or Grades","rows":[{"name":"M1","cellValues":{"Sales":45,"No Sales":76}},{"name":"M2","cellValues":{"Sales":9,"No Sales":12}},{"name":"M3","cellValues":{"Sales":72,"No Sales":16}},{"name":"M4","cellValues":{"Sales":98,"No Sales":12}},{"name":"M5","cellValues":{"Sales":6,"No Sales":8}}]};
  const [barChartOptions, setBarChartOptions] = useState(null);
  const setSeriesOptions = (seriesArr, categoriesArr) => {
    const seriesOpt = {};
    seriesOpt.options = {
      chart: {
        foreColor: "#000000",
      },
      title: {
        text: "Sales & Business Operations Market Positioning – Total Cost to Company",
        style: {
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0px",
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#000000", "#FFFFFF"],
        },
      },
      xaxis: {
        categories: categoriesArr,
        title: {
          text: "Roles or grades",
          offsetY: 90,
          style: {
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0px",
          },
        },
      },
      yaxis: [{}],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "right",
        markers: {},
        itemMargin: {},
        labels: {},
      },
    };
    seriesOpt.series = seriesArr;
    setBarChartOptions(seriesOpt);
  };
  const getSeries = (apiRes) => {
    const series1 = { name: "", data: [], color: "" };
    const series2 = { name: "", data: [], color: "" };
    const seriesArr = [];
    const categoriesArr = [];
    for (var i = 0; i < apiRes.rows.length; i++) {
      series1.name = Object.keys(apiRes.rows[i].cellValues)[0];
      series1.color = "#C4D600";
      series1.data.push(apiRes.rows[i].cellValues["Sales"]);

      series2.name = Object.keys(apiRes.rows[i].cellValues)[1];
      series2.color = "#00ABAB";
      series2.data.push(apiRes.rows[i].cellValues["No Sales"]);
      categoriesArr.push(apiRes.rows[i].name);
      if (i === apiRes.rows.length - 1) {
        seriesArr.push(series1, series2);
        setSeriesOptions(seriesArr, categoriesArr);
      }
    }
  };
  useEffect(() => {
    const url = "/api/report/bar";
    api
      .get({ url })
      .then(({ data }) => {
        console.log(data);
        if (data) {
          getSeries(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div>
        <div>
          {barChartOptions &&
            barChartOptions.options &&
            barChartOptions.series && (
              <Chart
                options={barChartOptions.options}
                series={barChartOptions.series}
                type="bar"
                height="500"
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default BarChart;
