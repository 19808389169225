import moment from "moment";
import { emailRegex, urlRegex } from "./commons";

export const sortData = (a, b, orderBy) => {
  if (a[orderBy] === null) {
    return 1;
  }
  if (b[orderBy] === null) {
    return -1;
  }
  a = typeof a[orderBy] === "string" ? a[orderBy]?.toUpperCase() : a[orderBy];
  b = typeof b[orderBy] === "string" ? b[orderBy]?.toUpperCase() : b[orderBy];
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
};

export const getSortData = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => sortData(a, b, orderBy)
    : (a, b) => -sortData(a, b, orderBy);
};

export const sortTable = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const standardDateFormat = (dateString, format) => {
  return moment(dateString).format(format || "DD MMM YYYY");
};

export const scrollToTop = () => {
  window.scroll({ top: 0, behavior: "smooth" });
};

export const emailValidator = (email) => {
  return emailRegex.test(email);
};

export const urlValidator = (url) => {
  return urlRegex.test(url);
};

export const generateColumns = (id, label, isDate = false) => {
  let obj = { id, label };
  if (isDate) {
    obj["isDate"] = true;
  }
  return obj;
};

export const generateOptions = (id, value) => {
  return { id, value };
};

export const normalizeData = (data) => {
  if (data.some((a) => typeof a === "string")) {
    data = data.map((d) => {
      return { id: d, value: d };
    });
  }
  return data;
};

export const sortObjectArrays = (objArr, key, order = "asc") => {
  let sorted = objArr.sort((a, b) => {
    let A = typeof a[key] === "string" ? a[key].toUpperCase() : +a[key];
    let B = typeof a[key] === "string" ? b[key].toUpperCase() : +b[key];
    if (order == "asc") {
      return A - B;
    } else {
      return B - A;
    }
  });
  return sorted;
};
