import React from "react";
import "./dashboardContentData.scss";
import PeerSetBox from "./PeerSetBox/peerSetBox";
import ReportsBox from "./ReportsBox/ReportsBox";
import CompensationUploadBox from "./CompensationUploadBox/compensationUploadBox";
import DipstickBox from "../DipsticksSurvey/DipstickBox";

const DashboardContentData = () => {
  return (
    <div className="dashboard-content-data">
      <div className="dashboard-content-row">
        <CompensationUploadBox />
        <DipstickBox />
      </div>
      <div className="dashboard-content-row">
        <PeerSetBox />
        <ReportsBox />
      </div>
    </div>
  );
};

export default DashboardContentData;
