import React from "react";
import CommRightContent from "./CommRightContent";
import CommLeftContent from "./CommLeftContent";
import CommHead from "./CommHead";

function Comm4() {
  return (
    <div>
      <CommHead
        commBtnClr={"commBtnBlack"}
        commMyActBtnClr={"commMyActBtnWhite"}
      />
      <div className="commFlexRow">
        <CommLeftContent showCommLeftCont={true} />
        <CommRightContent showCommSearch={true} />
      </div>
    </div>
  );
}

export default Comm4;
