import React, { useState, useEffect } from "react";
import "./assignData.scss";
import MasContainer from "../../domain-component/MasContainer";
import Button from "@mui/material/Button";
import MasDialog from "../../domain-component/MasDialog";
import api from "../../utility/common/api";
import { useLocation } from "react-router-dom";
import { Modal, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MasButton from "../MasButton/masButton";
import successLogo from "../../assets/images/success-logo.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45.3vw",
  height: "48vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  padding: "40px 50px",
  justifyContent: "end",
  alignItems: "center",
  gap: "9px",
};

const AssignData = () => {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  const [proceedOpen, setProceedOpen] = useState(false);

  const [showApprove, setShowApprove] = useState(false);
  const [companyLocation, setCompanyLocation] = useState([]);
  const [companyBusinessDomain, setCompanyBusinessDomain] = useState([]);
  const [userName, setUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [emailID, setEmailID] = useState("");
  const [approverOptions, setApproverOptions] = useState([]);
  const [dataTeamOptions, setDataTeamOptions] = useState([]);
  const [approver, setApprover] = React.useState("");
  const [dataTeam, setDataTeam] = React.useState("");
  const stateData = useLocation().state;

  const [recievedDate, setRecievedDate] = useState("");
  const navigate = useNavigate();

  const getCreatedDate = (d) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    setRecievedDate(
      monthNames[d.getMonth()] +
        " " +
        d.getDate() +
        ", " +
        d.getFullYear() +
        " @ " +
        d.getHours() +
        ":" +
        d.getMinutes()
    );
  };

  const handleProceedClose = () => {
    setProceedOpen(false);
    setShowApprove(false);
    navigate("/partner-dashboard");
  };
  useEffect(() => {
    if (stateData) {
      if (stateData.id) {
        const url = "/api/user/" + stateData.id;
        api
          .get({ url })
          .then(({ data }) => {
            if (data) {
              setUserName(data.userName);
              setCompanyName(data.companyName);
              setContactNo(data.contactNumber);
              setEmailID(data.companyEmailId);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (stateData.date) {
        getCreatedDate(stateData.date);
      }
    }
  }, []);

  const companyhandler = (e, comp, cancelCheck = false) => {
    let value = cancelCheck ? e : e.target.value;
    let arr = comp === "location" ? companyLocation : companyBusinessDomain;
    let index =
      comp === "location"
        ? companyLocation.indexOf(value)
        : companyBusinessDomain.indexOf(value);
    if (index === -1) {
      arr.push(value); //
    } else {
      arr.splice(index, 1);
    }
    comp === "location"
      ? setCompanyLocation([...arr])
      : setCompanyBusinessDomain([...arr]);
    console.log(companyLocation, arr);
    setApprover(e.target.value);
    let cartArr = [...approverOptions];
    cartArr = cartArr.filter((el) => el.value != e.target.value);
    setDataTeamOptions(cartArr);
  };
  const dataTeamhandler = (e) => {
    setDataTeam(e.target.value);
  };
  const handleApprove = () => {
    setShowApprove(true);
    const url = "/api/user/list";
    api
      .get({ url })
      .then(({ data }) => {
        if (data) {
          var d = [];
          data.forEach((el) => {
            if (el.userType != "USER") {
              d.push({ label: el.userName, value: el.id });
            }
          });
          setApproverOptions(d);
          setDataTeamOptions(d);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleDeclineRequest = () => {
    if (stateData) {
      if (stateData.primaryKey) {
        const url = "/api/approval/" + stateData.primaryKey;
        const data = { approvalStatus: "DECLINED" };
        api
          .patch({ url, data })
          .then(({ data }) => {
            if (data.approvalStatus == "DECLINED") {
              navigate("/partner-dashboard");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const handleProceed = () => {
    setProceedOpen(true);
    if (stateData) {
      if (stateData.primaryKey) {
        const url = "/api/approval/" + stateData.primaryKey;
        const data = {
          approvalStatus: "APPROVED",
          manager: approver,
          dataTeams: [dataTeam],
          enabled: true,
        };
        api
          .patch({ url, data })
          .then(({ data }) => {
            if (data) {
              setProceedOpen(true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  return (
    <div className="my-subscription-container">
      <div>
        {stateData && stateData.id && (
          <h1 className="mas-text-h1">Request No. {stateData.id}</h1>
        )}
      </div>
      <div className="frame">
        <div className="sum-head">
          {stateData && stateData.id && (
            <div className="hd font2"> Request No. {stateData.id}</div>
          )}
          <div className="footer-font"> Received on {recievedDate} </div>
        </div>
        <div>
          <hr className="ln" />
        </div>

        <div style={{ margin: "2vw" }}>
          <div className="font5">Summary</div>
          <div className="summary-details">
            <div className="summary-details-row">
              <div className="summary-details-item">
                User Name<p className="font3">{userName}</p>
              </div>
              <div className="summary-details-item">
                Registered Company Name <p className="font3">{companyName}</p>
              </div>
            </div>
            <div className="summary-details-row">
              <div className="summary-details-item">
                Contact No.<p className="font3">{contactNo}</p>
              </div>
              <div className="summary-details-item">
                Company Email ID <p className="font3">{emailID}</p>
              </div>
            </div>
          </div>

          <div className="summary-btn">
            <div className="decline-btn">
              <Button
                variant="outlined"
                size="large"
                sx={{
                  textTransform: "none",
                  color: "#DA291C",
                  margin: "0px",
                  border: "0px",
                  fontSize: "1vw",
                }}
                onClick={handleDeclineRequest}
              >
                Decline Request
              </Button>
            </div>
            <div className="approve-btn">
              <Button
                variant="outlined"
                size="large"
                sx={{
                  textTransform: "none",
                  color: "#000000",
                  margin: "0px",
                  border: "0px",
                  fontSize: "1vw",
                }}
                onClick={handleApprove}
              >
                Approve and Assign Request
              </Button>
            </div>
          </div>
        </div>
      </div>

      <MasDialog
        open={showApprove}
        title={"Assign Onboarding Request"}
        onClose={() => {
          setShowApprove(false);
        }}
      >
        <>
          <div className="onboard-form-label">
            <Typography variant="caption2" className="font4 onboard-label">
              Assign Approver For The Client
            </Typography>
          </div>
          <select
            name="crn"
            id="crn"
            onChange={(e) => companyhandler(e, "BusinessDomains")}
            className="onboard-form-select"
          >
            <option value=" ">Select</option>
            {approverOptions.map((option, i) => (
              <option value={option.value} key={i}>
                {option.label}
              </option>
            ))}
          </select>
          <br />
          <div className="onboard-form-label">
            <Typography variant="caption2" className="font4 onboard-label">
              Assign Data Team For The Client
            </Typography>
          </div>
          <select
            name="crn"
            id="crn"
            onChange={(e) => dataTeamhandler(e)}
            className="onboard-form-select"
          >
            <option value=" ">Select</option>
            {dataTeamOptions.map((option, i) => (
              <option value={option.value} key={i}>
                {option.label}
              </option>
            ))}
          </select>
          <br />
          <div className="summary-btn">
            <div className="cancel-btn1">
              <Button
                variant="outlined"
                size="large"
                sx={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  margin: "0px",
                  border: "0px",
                }}
                onClick={() => {
                  setShowApprove(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="approve-btn1">
              <Button
                variant="outlined"
                size="large"
                sx={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  margin: "0px",
                  border: "0px",
                }}
                onClick={handleProceed}
              >
                Proceed
              </Button>
            </div>
          </div>
        </>
      </MasDialog>

      <Modal open={proceedOpen} onClose={handleProceedClose}>
        <Box sx={style}>
        <img src={successLogo} className="logo"/>
          <div className="onboard-modal-header">
          
            <Typography variant="subtitle3" className="font2">
              Client Successfully Assigned to the Team
            </Typography> 
            
          </div>
          <div
            id="modal-modal-description"
            sx={{ mt: 2 }}
            className="onboard-modal-same-prop"
          >
            <div className="onboard-modal-description-boxes">
           
              <Typography
                variant="body2"
                className="font3 onboard-label"
                style={{ textAlign: "center" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
                aliquam, purus sit amet luctus venenatis, lectus magna fringilla
                urna
              </Typography>
            </div>
            <div className="proceed-modal-button approve-btn1 font3">
              <Button
                variant="outlined"
                size="large"
                sx={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  margin: "0px",
                  border: "0px",
                }}
                onClick={handleProceedClose}
              >
                Back to Dashboard
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AssignData;
