import React, { useEffect } from "react";
import Breadcrum from "../../Breadcrum/breadcrum";
import FirstContent from "../../FirstContent/firstContent";
import MasButton from "../../MasButton/masButton";
import "./FinalReports.scss";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

const FinalReports = () => {
  const reportsArray = useSelector(
    (state) => state.ReportsReducer.reportsArray
  );
  const length = reportsArray.length;

  const navigate = useNavigate();

  useEffect(() => {
    if (length <= 0) {
      return navigate("/reportsView");
    }
  }, [reportsArray]);

  return (
    <div>
      <div className="report-first-content">
        <FirstContent showBack="true" header="Request No. 223" />
        <div className="breadcrum-div">
          <Breadcrum />
        </div>
        <div className="manager-reports-section">
          {reportsArray.map((elem, key) => {
            return (
              <div className="manager-one-reports-section">
                <Typography variant="subtitle3">{key + 1}</Typography>
                <div className="manager-one-report-show">{elem}</div>
              </div>
            );
          })}
          <div className="reports-button-section" style={{ marginTop: "47px" }}>
            <Link to="/dashboard">
              <MasButton
                buttonName="Back to Report Generation"
                type="outlined-button size"
              />
            </Link>
            <Link to="/managerReportGenReq2">
              <MasButton
                buttonName="Send Report to Client"
                type="modal-contained-button-normal"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalReports;
