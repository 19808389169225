import { Typography } from "@mui/material";
import { textAlign } from "@mui/system";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import Chart from "react-apexcharts";
import api from "../../../utility/common/api";
import { showLoader } from "../../../store/actions/global";
import { useDispatch } from "react-redux";

const ScatterPlot = (props) => {
  const [label, setLabel] = useState(null);
  const [seriesData, setSeriesData] = useState(null);
  const [apiRespData, setApiRespData] = useState(null);
  const [options, setOptions] = useState(null);

  const dispatch = useDispatch();

  // const getMaxLength = (data) => {
  //   console.log('data>>>>>>>>', data);
  //   let n = [],
  //     lab = [];
  //   data.forEach((el) => {
  //     lab.push(el.cellValues["Job ID"]);
  //     n.push(el.cellValues["data"].length);
  //   });
  //  // setLabel(lab);

  //   const dataLen = Math.max.apply(0,data.map((obj)=>obj.cellValues.data.length));
  //   //console.log('dataLen>>>>>>>>>>>>>>',dataLen);
  //   const labData = data.map((obj)=>obj.cellValues["Job ID"]);
  //  // console.log('labData>>>>>>>>>>>>>>',labData);
  //   return [dataLen, lab.length];
  // };


  /* const getSeries = useCallback((apiRes) => {
     if (apiRes && apiRes.length > 0) {
       //let [maxLength, labelLength] = getMaxLength(apiRes);
       //console.log(maxLength, labelLength)
       const maxLength = Math.max.apply(0, data.map((obj) => obj.cellValues.data.length));
       const labData = data.map((obj) => obj.cellValues["Job ID"]);
       setLabel(labData);
       const labelLength = labData.length;
       let series = [];
       for (var i = 0; i < maxLength; i++) {
         let d = [];
         for (var j = 0; j < labelLength; j++) {
           if (apiRes[j].cellValues["data"][i] == undefined) {
             d.push(" ");
           } else {
             d.push(apiRes[j].cellValues["data"][i]);
           }
         }
         series.push({ data: d });
         if (i === maxLength - 1) {
           console.log('series>>>>>>>>>>>>>>', series);
           setData(series);
         }
       }
 
     }
   }, []);*/

  useEffect(() => {
    if (props.primKey) {
      dispatch(showLoader(true));
      console.log('props.primKey>>>>>>>>', props.primKey);
      const url = `/api/report/${props.primKey}?chartType=scattered`;
      api
        .get({ url })
        .then(({ data }) => {
          console.log('data>>>>>>>>', data);
          if (data.rows) {
            setApiRespData(data);
            dispatch(showLoader(false));
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(showLoader(false));
        });

      // const userToken = localStorage.getItem("authentication-token");
      // fetch(url, {
      //   headers: { Authorization: `Bearer ${userToken}` }
      // })
      //   .then(resp => resp.json())
      //   .then(json => {
      //     console.log('json>>>>>', json.rows);
      //     setApiRespData(json)
      //   })

    }
  }, []);

  const getSetOptions = () => {
    setOptions({
      colors: ["#000000"],
      chart: {
        type: "scatter",
        height: 350,
        zoom: {
          enabled: false,
          type: "xy",
        },
        toolbar: {
          show: false,
        },
      },

      xaxis: {
        type: "category",
        categories: label,
        labels: {
          style: {},
        },
      },
      yaxis: {},
      markers: {
        strokeWidth: 0,
      },
      legend: {
        height: 30,
        show: false,
      },
    });
  }

  const getSetLabel = () => {
    const rows = apiRespData.rows;
    const labData = rows.map((obj) => obj.cellValues["Job ID"]);
    setLabel(labData);
  };

  const getSetSeriesData = () => {
    const rows = apiRespData.rows;
    const maxLength = Math.max.apply(0, rows.map((obj) => obj.cellValues.data.length));
    const labelLength = label.length;
    let series = [];
    for (var i = 0; i < maxLength; i++) {
      let d = [];
      for (var j = 0; j < labelLength; j++) {
        if (rows[j].cellValues["data"][i] == undefined) {
          d.push(" ");
        } else {
          d.push(rows[j].cellValues["data"][i]);
        }
      }
      series.push({ data: d });
      if (i === maxLength - 1) {
        console.log('series>>>>>>>>>>>>>>', series);
        setSeriesData(series);
        //setSeriesData(series.slice(0, 1));
      }
    }
  };

  useEffect(() => {
    if (seriesData) {
      getSetOptions();
    }
  }, [seriesData]);

  useEffect(() => {
    if (label && label.length > 0) {
      getSetSeriesData();
    }
  }, [label]);

  useEffect(() => {
    if (apiRespData && apiRespData.rows && apiRespData.rows.length > 0) {
      getSetLabel();
    }
  }, [apiRespData]);



  return (
    <div>
      {seriesData && seriesData.length > 0 && options && (
        <div>
          {/* <Chart options={options} series={seriesData} type="scatter" height="500" /> */}
        </div>
      )}
    </div>
  );
};

export default ScatterPlot;
