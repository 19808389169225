import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import api from "../../../utility/common/api";
import { showLoader } from "../../../store/actions/global";
import { useDispatch } from "react-redux";

const LineChart = (props) => {
  // const [label, setLabel] = useState([]);
  // const [data1, setData1] = useState([]);
  // const [data2, setData2] = useState([]);
  // const [data3, setData3] = useState([]);

  const dispatch = useDispatch();

  const [seriesData, setSeriesData] = useState(null)
  const [chartData, setChartData] = useState(null);
  const getSeries = (apiRes) => {
    // let d1 = [],
    //   d2 = [],
    //   d3 = [],
    //   lab = [];
    const seriesArray = {
      d1: [],
      d2: [],
      d3: [],
      label: []
    }
    apiRes.forEach((el, i) => {
      // d1.push(el.cellValues["percentile50"]);
      // d2.push(el.cellValues["average"]);
      // d3.push(el.cellValues["sponsor_percentile50"]);
      // lab.push(el.cellValues["Job ID"]);

      seriesArray.d1.push(el.cellValues["percentile50"]);
      seriesArray.d2.push(el.cellValues["average"]);
      seriesArray.d3.push(el.cellValues["sponsor_percentile50"]);
      seriesArray.label.push(el.cellValues["Job ID"]);

      if (i === apiRes.length - 1) {
        // setData1(d1);
        // setData2(d2);
        // setData3(d3);
        // setLabel(lab);
        setSeriesData(seriesArray);
      }
    });
  };
  const prepareChartData = () => {
    setChartData({
      options: {
        chart: {
          foreColor: "#000000",
        },
        title: {
          text: "Performance Pay as a % of Total Cost to Company",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 3],
          textAnchor: "middle",
          offsetX: 0,
          offsetY: -7,
          style: {
            border: "0px",
            colors: ["none"],
            fontWeight: "600",
            fontSize: "0.62vw",
            lineHeight: "100%",
          },
          background: {
            borderWidth: 0,
            padding: 4,
            borderRadius: 1,
            foreColor: "#000000",
          },
        },
        xaxis: {
          categories: seriesData.label,
          title: {
            text: "Roles or grades",
            offsetY: 90,
          },
        },
        yaxis: [
          {
            title: {
              text: "Payment (in %)",
            },
          },
        ],
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "right",
        },
      },
      series: [
        {
          name: "Perc",

          data: seriesData.d1,
          color: "#005059",
        },
        {
          name: "Average",

          data: seriesData.d2,
          color: "#C4D600",
        },
        {
          name: "S Perc",

          data: seriesData.d3,
          color: "#EC8B01",
        },
      ]
    })
  };

  useEffect(() => {
    if (seriesData) {
      prepareChartData()
    }
  }, [seriesData]);

  useEffect(() => {
    if (props.primKey) {
      dispatch(showLoader(true));
      const url = `/api/report/${props.primKey}?chartType=line&percentile=50`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data.rows) {
            getSeries(data.rows);
            dispatch(showLoader(false));
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(showLoader(false));
        });
    }
  }, []);


  // const options = {
  //   chart: {
  //     foreColor: "#000000",
  //   },
  //   title: {
  //     text: "Performance Pay as a % of Total Cost to Company",
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     enabledOnSeries: [0, 3],
  //     textAnchor: "middle",
  //     offsetX: 0,
  //     offsetY: -7,
  //     style: {
  //       border: "0px",
  //       colors: ["none"],
  //       fontWeight: "600",
  //       fontSize: "0.62vw",
  //       lineHeight: "100%",
  //     },
  //     background: {
  //       borderWidth: 0,
  //       padding: 4,
  //       borderRadius: 1,
  //       foreColor: "#000000",
  //     },
  //   },
  //   xaxis: {
  //     categories: label,
  //     title: {
  //       text: "Roles or grades",
  //       offsetY: 90,
  //     },
  //   },
  //   yaxis: [
  //     {
  //       title: {
  //         text: "Payment (in %)",
  //       },
  //     },
  //   ],
  //   legend: {
  //     show: true,
  //     position: "top",
  //     horizontalAlign: "right",
  //   },
  // };

  // const series = [
  //   {
  //     name: "Perc",

  //     data: data1,
  //     color: "#005059",
  //   },
  //   {
  //     name: "Average",

  //     data: data2,
  //     color: "#C4D600",
  //   },
  //   {
  //     name: "S Perc",

  //     data: data3,
  //     color: "#EC8B01",
  //   },
  // ];


  return (
    <div>
      <div>
        {chartData && chartData.options && chartData.series &&  <Chart options={chartData.options} series={chartData.series} type="line" height="500" />}
      </div>
    </div>
  );
};

export default LineChart;
