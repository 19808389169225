import React from "react";
import "./masButton.scss";
import Download from "../../assets/images/greenDownload.png";

const MasButton = (props) => {
  return (
    <button type="Submit" name="Submit" className={props.type}>
      {props.buttonName}
      {props.download && <img src={Download} className="image"></img>}
    </button>
  );
};

export default MasButton;
