import React from "react";
import greyLine from "./Images/greyLine.svg";
import greenLine from "./Images/greenLine.svg";
import "./compensation.scss";

function CompensationRightCont() {
  return (
    <div>
      <div className="flexRow">
        <img src={greyLine} id="compGreyLine" />
        <div>
          <div className="flexRow">
            <div id="compRCone">01</div>
            <div id="compRCupdate">Update Basic Details</div>
          </div>
          <div className="compRCpara">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam
          </div>
        </div>
      </div>
      <div className="flexRow">
        <img src={greenLine} id="compGreenLine" />
        <div>
          <div className="flexRow">
            <div id="compRCtwo">02</div>
            <div id="compRCupload">Upload Compensation Files</div>
          </div>
          <div className="compRCpara">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompensationRightCont;
