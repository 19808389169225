import React, { useState, useEffect } from "react";
import dw1 from "./images/dw1.svg";
import dw2 from "./images/dw2.svg";
import "../PartnerManager/PartnerManagerMisc.scss";
import { Link } from "react-router-dom";
import api from "../../utility/common/api";
import { useNavigate, useParams } from "react-router-dom";

function ReportBoxContent(props) {
  const [reportGenReqData, setReportGenReqData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (props.id) {
      const url = `/api/approvaldisplay/${props.id}`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data) {
            setReportGenReqData(data);
            sessionStorage.setItem("id", props.id);
            sessionStorage.setItem("parentID", data.approvalRest.parentId);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <div>
      {reportGenReqData && (
        <div id="partnerManagerCompBox">
          <div className="repFlexRow">
            <div id="repBoxHead">
              <div id="repName2">
                {reportGenReqData.peersetRest.peerSetGroupName}{" "}
                <div id="repNameYr">(Year 2022-23)</div>
              </div>
              <div id="repRec2" className="font7">
                Received on 4th Sep'22 @3:45 PM
              </div>
            </div>
            <button id="dwBtn">
              <img src={dw1} id="dw1" />
              <img src={dw2} id="dw2" />
            </button>
          </div>
          <div id="partnerManagerCompLine" />
          <div id="repSumm" className="font11">
            Summary
          </div>
          <div className="repFlexRow" id="repBoxStuff">
            <div className="repFlexRow" id="repLeft">
              <div id="repSpon">
                <div className="repSponName font7">Sponser Name</div>
                <div className="repSponNameText font8">
                  {" "}
                  {reportGenReqData.userRest.companyName}
                </div>
              </div>
              <div id="repSect">
                <div className="repSect font7">Sector</div>
                <div className="repSectText font8">Bank Non Restricted</div>
              </div>
              <div id="repSamp">
                <div className="repSamp font7">Sampling Required</div>
                <div className="repSampText font8">Not Required</div>
              </div>
              <div id="repMainAnc">
                <div className="repMainAnc font7">Main Anchor for Review</div>
                <div className="repMainAncText font8">Total Fixed Pay</div>
              </div>
              <div id="repFiles">
                <button
                  id="repViewAll"
                  className="font8"
                  onClick={() => {
                    navigate(`/reports`);
                  }}
                >
                  View All
                </button>
              </div>
            </div>
            <div id="repPeer2">
              <div className="repPeer font7">Peer Set</div>
              <div className="repPeer2Text font8">
                {reportGenReqData.peersetCompanyRests.map((e) => (
                  <span key={e.comapanyName}> {e.comapanyName}, </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReportBoxContent;
