import { Typography } from "@mui/material";
import React, { useState } from "react";
import "./dipstick.scss";
import RightArrowHead from "../../assets/images/rightArrowHead.png";
import { useNavigate } from "react-router-dom";
import { getTranslation } from "../../utility/i18n/i18n";
const DipstickBox = () => {
  const navigate = useNavigate();
  return (
    <div className="content-upload">
      <Typography variant="subtitle3" className="content-upload-header font13">
        Dipstick Survey
      </Typography>
      <Typography variant="body2" className="content-upload-desc font3">
      {getTranslation("dashboard")["description2"]}
      </Typography>
      <div className="content-upload-button">
        <Typography
          variant="body2"
          className="green-button-font"
          onClick={() => navigate("/dipstick-surveyq1")}
        >
          Start the survey
        </Typography>
      </div>
    </div>
  );
};

export default DipstickBox;
