export const translationObject = {
  peerset1: {
    noPeer: "No Peer Sets Available",
    noPeerDesc:
      "Update new peer set to utilize the same in generation reports faster",
  },
  "login-external": {
    description:
      ["CompScale is a compensation management software application designed to help organizations manage their employee compensation programs. This tool typically includes features such as job analysis, salary surveys, salary benchmarking, salary structure creation, reward structuring and  employee compensation planning.",
      "With CompScale, organizations can ensure that their compensation plans are fair, competitive, and compliant with relevant laws and regulations. These tools can also help organizations attract and retain top talent by providing competitive compensation packages.",
      "CompScale is widely used to Streamline compensation processes, Ensure fairness and consistency , Attract and retain top talent, Manage costs and Ensure compliance"],
    
  },
  "dashboard":{
    description:"This is your solution for managing compensation that provides multiple streams of fresh, transparently curated, and validated salary data. Combined with modeling engines that learn continuously and generate recommendations and insight. ",
    description1:"Get market insights benchmarked to your company pay data. Use this section to upload your firm pay data.",
    description2:"Help us serve you better by answering a quick 1 minute poll. This survey is used to better curate the benchmarking data for you.",
    description3:"Avail a customized compensation data report benchmarked for the companies selected by you.",
    description4:"Data is power, make confident decisions with market insights marked against your own firm data.CompScale empowers you to price jobs and adjust compensation to reflect near real-time changes in the market — all on one trusted data platform",
  },
  "incumbent":{
    description1:"Get market insights benchmarked to your company pay data. Use this section to upload your firm pay data.",
    description2:"Data is templatized and uploaded in an excel file.",
  },
  "peerset":{
    description:"Avail a customized compensation data report benchmarked for the companies selected by you.",
  },
  "reports1":{
    description:"Data is power, make confident decisions with market insights marked against your own firm data. CompScale empowers you to price jobs and adjust compensation to reflect near real-time changes in the market — all on one trusted data platform",
  },
  "dashboard-compUpload-modal":{
    incubment:{
                header: "Incumbent Wise Data Submission",
                desc: "Data is templatized and uploaded in an excel file."},
    salary:{
      header: "Upload Salary Structure by Levels and Calculate Elements",
      desc: "Data is manually filled by percentage and fixed amount out of the total compensation",
    },

  }
};

export const getTranslation = (key) => {
  return translationObject[key];
};
