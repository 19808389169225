import React, { useState, useEffect } from "react";
import PartnerManagerTemplate from "../PartnerManager/PartnerManagerTemplate/PartnerManagerTemplate";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utility/common/api";

function ManagerPeerReq() {
  const params = useParams();
  const [managerPeerReqData, setManagerPeerReqData] = useState(null);
  useEffect(() => {
    if (params.id) {
      const url = `/api/approvaldisplay/${params.id}`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data) {
            setManagerPeerReqData(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  return (
    <div>
      {managerPeerReqData && (
        <PartnerManagerTemplate
          showBlackHeadArrow={true}
          heading={`Request No. ${params.id}`}
          subHead={"Peer Request"}
          nav1={"Dashboard"}
          nav2={`Request No. ${params.id}`}
          nav1col={"navGreen"}
          nav2col={"navBlack"}
          showPeerSet={true}
          showExecuting={true}
          showVertLine3={true}
          showPeerHis={true}
          partnerPeerReqTimeline={true}
          show2Btns={true}
          data={managerPeerReqData}
          showApprover={false}
          showDownload={false}
          showPeersetDownload={true}
          reqNo={params.id}
        />
      )}
    </div>
  );
}

export default ManagerPeerReq;
