import React, { useState, useEffect } from "react";
import PartnerManagerTemplate from "../PartnerManager/PartnerManagerTemplate/PartnerManagerTemplate.jsx";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utility/common/api";

function PartnerPeerReq() {
  const params = useParams();
  const [partnerPeerReqData, setPartnerPeerReqData] = useState(null);
  useEffect(() => {
    if (params.id) {
      const url = `/api/approvaldisplay/${params.id}`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data) {
            setPartnerPeerReqData(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  return (
    <div>
      {partnerPeerReqData && (
        <PartnerManagerTemplate
          showBlackHeadArrow={true}
          heading={`Request No. ${params.id}`}
          subHead={"Peer Request"}
          nav1={"Dashboard"}
          nav2={`Request No. ${params.id}`}
          nav1col={"navGreen"}
          nav2col={"navBlack"}
          showPeerSet={true}
          showExecuting={true}
          showVertLine3={true}
          vertLineMargin={"70px"}
          showPeerHis={true}
          partnerPeerReqTimeline={true}
          data={partnerPeerReqData}
          showApprover={false}
          showDownload={false}
          reqNo={params.id}
          showPeersetDownload={false}
        />
      )}
    </div>
  );
}

export default PartnerPeerReq;
