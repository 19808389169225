import React,{useState} from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import './Search.css';

export default function Search(props) {
   
  return (
    <div >
        {props.type==="SEARCH" ?
            (<Autocomplete
                id={props.label}
                freeSolo
                className="styleclass"
                sx={{ width: '15vw'}}
                value={props.optionSelected}
                onChange={(event, value) => {
                    props.setOptionSelected(value);
                  }}
                options={props.data.map((option) => option)}
                renderInput={(params) => <TextField {...params} label={props.label} className="styleclass"   />}
            />):(
                <Autocomplete
                id={props.label}
                disablePortal
                sx={{ width: '15vw'}}
                value={props.optionSelected}
                onChange={(event, value) => {
                    props.setOptionSelected(value);
                  }}
                options={props.data.map((option) => option)}
                renderInput={(params) => <TextField {...params} label={props.label}   />}
            />
            )
        }
    </div>
  );
}

