import React from "react";
import CommHead from "./CommHead";
import CommPostQue from "./CommPostQue";

function Comm3() {
  return (
    <div>
      <CommHead
        commBtnClr={"commBtnWhite"}
        commMyActBtnClr={"commMyActBtnBlack"}
      />
      <CommPostQue showSkSe={true} />
    </div>
  );
}

export default Comm3;
