import React from "react";
import { Typography } from "@mui/material";
import ddsicon1 from "./Images/dds-icon1.svg";
import "./Peerset.scss";
import { getTranslation } from "../../utility/i18n/i18n";
import { useNavigate } from "react-router-dom";
function PeerHead(props) {
  const navigate=useNavigate();
  return (
    <div>
      <div className="flexRow">
        <img src={ddsicon1} id="peerBackBtn" onClick={()=>{navigate('/dashboard')}}/>
        <Typography variant="subtitle1" id="peerHead" className="header-font">
          {props.peerHeadCont}
        </Typography>
      </div>
      <Typography variant="body2" id="peerHeadDesc" className="font8">
      {getTranslation("peerset")["description"]}
      </Typography>
    </div>
  );
}

export default PeerHead;
