import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import store from "./store/index";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import axios from "axios";
import { Navigate } from "react-router";

// axios.defaults.withCredentials = true;
// axios.defaults.baseURL = "";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

axios.interceptors.request.use(
  (request) => {
    const userToken = localStorage.getItem("authentication-token");
    if (userToken) {
      request.headers.Authorization = "Bearer " + userToken;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    sessionStorage.removeItem("error-code");
    if (response.headers.token) {
      sessionStorage.setItem("token", response.headers.token);
    }

    if (response.config.parse) {
      //perform the manipulation here and change the response object
    }
    if (response.status === 401) {
      localStorage.removeItem("authentication-token");
      localStorage.removeItem("userType");
      sessionStorage.clear();
    }
    return response;
  },
  (error) => {
    console.log("Error",error);
    if (error.response && error.response.data) {
      alert(error.response.data.userMessage);
    } else {
      localStorage.removeItem("authentication-token");
      localStorage.removeItem("userType");
      sessionStorage.clear();
      alert("Service Unavailable");
    }
  }
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#4A4A4A",
    },
    secondary: {
      main: "#8D7249",
    },
    tertiary: {
      main: "#FFF000",
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#8D7249",
      tertiary: "#FFF000",
    },
  },
  typography: {
    // fontFamily: "open-sans",
    fontStyle: "normal",
    subtitle1: {
      fontWeight: "600",
      fontSize: "40px",
      lineHeight: "52px",
      color: "#1A202C",
      letterSpacing: "0px",
    },
    subtitle2: {
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "16px",
      color: "#000000",
      letterSpacing: "0px",
    },
    subtitle3: {
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "35px",
      letterSpacing: "1px",
      // fontFamily: "sans-serif",
    },
    subheader: {
      fontWeight: "600",
      fontSize: "96px",
      lineHeight: "131px",
      letterSpacing: "0px",
    },
    body1: {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0px",
    },
    body2: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0px",
    },
    body3: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "15px",
      letterSpacing: "0px",
    },
    caption: {
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0px",
    },
    caption2: {
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0px",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
