import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Breadcrum from "../../Breadcrum/breadcrum";
import MasButton from "../../MasButton/masButton";
import MainReportView from "./MainReportView/mainReportView";
import "./oneReportTemplate.scss";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link, useNavigate, useParams } from "react-router-dom";
import MasLoader from "../../../domain-component/MasLoader";
import { useSelector } from "react-redux";

const OneReportTemplate = () => {
  const [arrow, setArrow] = useState(0);
  // const params = useParams();
  const primKey = sessionStorage.getItem("parentID");
  const reportsArray = useSelector(
    (state) => state.ReportsReducer.reportsArray
  );

  const showLoader = useSelector((state) => state.ReportsReducer.showLoader);

  const length = reportsArray.length;
  const navigate = useNavigate();

  useEffect(() => {
    if (length <= 0) {
      alert("Select checkbox before proceeding");
      if (localStorage.getItem("userType") == "USER") {
        return navigate(`/reports`);
      } else {
        return navigate(`/reportsView`);
      }
    }
  }, [reportsArray]);

  const clickHandler = (direct) => {
    if (direct === "left" && arrow > 0) {
      setArrow(arrow - 1);
    }
    if (direct === "right" && arrow <= length - 2) {
      setArrow(arrow + 1);
    }
  };

  return (
    <div>
      <div className="onereport-first-content">
        <div className="oneReport-header-section">
          {localStorage.getItem("userType") != "MANAGER" && (
            <div
              className={arrow === 0 ? "icon-section" : "green-icon-section"}
            >
              {arrow === 0 ? (
                <KeyboardArrowLeftIcon
                  onClick={() => clickHandler("left")}
                  disabled={true}
                />
              ) : (
                <KeyboardArrowLeftIcon
                  sx={{ color: "#26890D" }}
                  onClick={() => clickHandler("left")}
                />
              )}
            </div>
          )}

          <Typography variant="subtitle3" color={"#000000"} fontSize={"1.8vw"}>
            Summary of Competitive Positioning (2022)
          </Typography>
          {localStorage.getItem("userType") != "MANAGER" && (
            <div
              className={
                arrow === length - 1 ? "icon-section" : "green-icon-section"
              }
            >
              {arrow === length - 1 ? (
                <KeyboardArrowRightIcon
                  onClick={() => clickHandler("right")}
                  disabled={true}
                />
              ) : (
                <KeyboardArrowRightIcon
                  sx={{ color: "#26890D" }}
                  onClick={() => clickHandler("right")}
                />
              )}
            </div>
          )}
          {localStorage.getItem("userType") == "MANAGER" ? (
            <span
              onClick={() => {
                navigate(`/reportsView`);
              }}
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon sx={{ color: "#000000" }} fontSize="large" />
            </span>
          ) : (
            <span
              onClick={() => {
                navigate(`/reports`);
              }}
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon sx={{ color: "#000000" }} fontSize="large" />
            </span>
          )}
        </div>
        <div className="onereport-breadcrum-div">
          <Breadcrum />
          {/* <div className='onereport-subheader'>
                    <Typography variant='body2'>Click on the guide to understand terms and abbrevations used in the reports.</Typography>
                    <MasButton buttonName="Deloitte Guide" type="outlined-button size greenColor" />
                </div> */}
          <div className="oneReport-data-section">
            {reportsArray && reportsArray.length > 0 && (
              <MainReportView
                arrow={arrow}
                reportsArray={reportsArray}
                primKey={primKey}
              />
            )}
            <MasLoader open={showLoader} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneReportTemplate;
