import React, { useState, useEffect } from "react";
import "./peerset2.scss";
import ddsicon2 from "./Images/dds-icon2.svg";
import { useNavigate } from "react-router-dom";
import PeerHead from "./PeerHead";
import PeersetRC from "./PeersetRC";
import Cross from "../../assets/images/crossIconDash.png";
import api from "../../utility/common/api";

function Peerset2() {
  const navigate = useNavigate();
  const navigateToCreatePeer3 = () => {
    const url = "/api/peerset";
    const data = {
      peerSetGroupName: gpName,
      peerCompaniesIds: compIds,
    };
    if (gpName && gpName !== "" && compIds && compIds.length > 0) {
      api
        .post({ url, data })
        .then(({ data }) => {
          if (data) {
            navigate(`/Peerset3/${data.id}`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Enter the Peer Group Name & Select the company");
    }
  };

  const [companyName, setCompanyName] = useState([]);
  const [compList, setCompList] = useState([]);
  const [gpName, setGpName] = useState("");
  const [compIds, setCompIds] = useState([]);

  useEffect(() => {
    const url = "/api/peersetcompany/list";
    api
      .get({ url })
      .then(({ data }) => {
        console.log(data);
        if (data && data.length > 0) {
          setCompList(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const compHandler = (e, cancelCheck = false) => {
    let value = cancelCheck ? e : e.target.value;
    let arr = companyName;
    let compIdArr = compIds;
    let index = companyName.indexOf(value);

    if (!cancelCheck) {
      const index = e.target.selectedIndex;
      const el = e.target.childNodes[index];
      const id = el.getAttribute("id");
      compIdArr.push(parseInt(id));
    }
    if (index === -1) {
      arr.push(value);
    } else {
      arr.splice(index, 1);
      compIdArr.splice(index, 1);
    }
    setCompanyName([...arr]);
    setCompIds([...compIdArr]);
    console.log(compIdArr, arr);
  };

  return (
    <div>
      <PeerHead peerHeadCont={"Create Peer Sets"} />
      <div className="flexRow">
        <div id="peer2box">
          <form>
            <label id="peer2GroupName" className="font7">
              Peer Group Name
            </label>
            <br></br>
            <input
              type="text"
              id="peer2GroupNameInp"
              className="font8"
              placeholder="Finance Competitor Peer Group"
              onChange={(e) => setGpName(e.target.value)}
            />
            <br></br>
            <label id="peer2Search" className="font7">
              Search Peer Companies (atleast 5 companies)
            </label>
            <br></br>
            <select
              name="peerCompNames"
              id="peer2SearchInp"
              className="font8"
              onChange={(e) => compHandler(e)}
            >
              <option value="" disabled selected>
                Select your option
              </option>
              {compList &&
                compList.length > 0 &&
                compList.map((obj, k) => (
                  <option key={k} value={obj.comapanyName} id={obj.id}>
                    {obj.comapanyName}
                  </option>
                ))}
            </select>
            <div
              className={companyName.length > 0 ? "companyName-filters" : ""}
            >
              {companyName.length > 0
                ? companyName.map((elem, k) => {
                    return (
                      <div className="compNameSelected" key={k}>
                        {elem}
                        <img
                          className="compNameSelectedCross"
                          onClick={() => compHandler(elem, true)}
                          src={Cross}
                        />
                      </div>
                    );
                  })
                : null}
            </div>
            <br></br>
          </form>
          <button
            id="createPeerBtn"
            onClick={navigateToCreatePeer3}
            className="font8"
          >
            Create Peer Set
          </button>
        </div>
      </div>
    </div>
  );
}

export default Peerset2;
