import React, { useState, useEffect } from "react";
import "./allReportsTemplate.scss";
import { Typography } from "@mui/material";
import StackedBarChartImage from "../../../assets/images/stackedBarChart.png";
import BarChartImage from "../../../assets/images/barChartImage.PNG";
import PerformancePayMixedChart from "../../../assets/images/PerformancePayMixedChart.PNG";
import PerformancePayLineChart from "../../../assets/images/PerformancePayLineChart.PNG";
import CompensationAnalysis from "../../../assets/images/CompensationAnalysis.png";
import EmployeeWiseAnalysis from "../../../assets/images/EmployeeWiseAnalysis.png";
import CompetitivePositioning from "../../../assets/images/CompetitivePositioning.png";
import ScatterPlotImage from "../../../assets/images/ScatterPlot.PNG";
import Download from "../../../assets/images/greenDownload.png";
import { setReportsNumber } from "../../../store/actions/global";
import { useDispatch } from "react-redux";
import CompensationAnalysisTable from "../CompensationAnalysisTable/compensationAnalysisTable";
import StackedBarChart from "../StackedBarChart/stackedBarChart";
import LineChart from "../LineChart/lineChart";
import MixedBarLineChart from "../MixedBarLineChart/mixedBarLineChart";
import BarChart from "../BarChart/barChart";
import ScatterPlot from "../ScatterPlot/scatterPlot";
import CollapsibleTable from "../CollapsibleTable/CollapsibleTable";
import ReportTables from "../ReportTables/reportTables";
import MasButton from "../../MasButton/masButton";
import { useNavigate, useParams, Link } from "react-router-dom";
import api from "../../../utility/common/api";

const AllReportsTemplate = (props) => {
  // const params = useParams();
  const ID = sessionStorage.getItem("id");
  let primKey = sessionStorage.getItem("parentID");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendArray, setSendArray] = useState([]);

  const [imageMap, setImageMap] = useState([
    {
      image: CompetitivePositioning,
      name: "CompetitivePositioning",
      show: false,
      key: "rawdata",
    },
    {
      image: CompensationAnalysis,
      name: "CompensationAnalysis",
      show: false,
      key: "table",
    },
    {
      image: PerformancePayLineChart,
      name: "PerformancePayLineChart",
      show: false,
      key: "line",
    },
    {
      image: PerformancePayMixedChart,
      name: "PerformancePayMixedChart",
      show: false,
      key: "bar",
    },

    {
      image: ScatterPlotImage,
      name: "ScatterPlot",
      show: false,
      key: "scattered",
    },
  ]);
  // useEffect(() => {
  //   if (!props.showManagerPersona) {
  //     primKey = props.data.primaryKey;
  //     setImageMap((items) =>
  //       items.filter((x) => props.data.chartTypes.includes(x.key))
  //     );
  //   }
  // }, []);

  const showReport = [
    {
      component: <ReportTables primKey={primKey} />,
      name: "CompetitivePositioning",
      id: "1",
      key: "rawdata",
    },
    {
      component: <CompensationAnalysisTable primKey={primKey} />,
      name: "CompensationAnalysis",
      id: "2",
      key: "table",
    },
    {
      component: <LineChart primKey={primKey} />,
      name: "PerformancePayLineChart",
      id: "3",
      key: "line",
    },
    {
      component: <MixedBarLineChart primKey={primKey} />,
      name: "PerformancePayMixedChart",
      id: "4",
      key: "bar",
    },
    {
      component: <ScatterPlot primKey={primKey} />,
      name: "ScatterPlot",
      id: "5",
      key: "scattered",
    },
  ];

  function handleReports(key, keyName) {
    let id;
    showReport.forEach((el) => {
      if (el.key == keyName) {
        id = el.id;
      }
    });
    let arr = imageMap;
    arr[key].show = !arr[key].show;
    setImageMap([...arr]);
    let check = arr[key].show;
    let reportArray = sendArray;
    if (check === true) {
      reportArray.push(showReport[id - 1]);
    } else {
      let index = reportArray.indexOf(showReport[id - 1]);
      reportArray.splice(index, 1);
    }
    setSendArray([...reportArray]);
    dispatch(setReportsNumber({ reportArray }));
    if (reportArray.length > 0) {
      navigate(`/onereport`);
    }
  }
  const clickHandler = (key, keyName) => {
    let id;
    showReport.forEach((el) => {
      if (el.key == keyName) {
        id = el.id;
      }
    });
    let arr = imageMap;
    arr[key].show = !arr[key].show;
    setImageMap([...arr]);

    let check = arr[key].show;
    let reportArray = sendArray;
    if (check === true) {
      reportArray.push(showReport[id - 1]);
    } else {
      let index = reportArray.indexOf(showReport[id - 1]);
      reportArray.splice(index, 1);
    }
    setSendArray([...reportArray]);
    dispatch(setReportsNumber({ reportArray }));
  };

  return (
    <>
      {props.showManagerPersona && (
        <div className="manager-persona-div">
          <div className="manager-persona-template-box">
            <div className="mp-template-header">
              <div id="partnerCompBoxHead">{props.header}</div>
              <div id="partnerCompBoxSub">Report Generation Request</div>
              <div id="partnerCompBoxSub2">Recieved on {props.createdDate}</div>
            </div>
          </div>
          <div id="partnerCompBoxSub">
            Select Types of Reports to be Generated
          </div>
        </div>
      )}
      <div className="all-reports-template-box">
        {imageMap.map((elem, key) => {
          return (
            <div className="single-report-box" key={key}>
              <input
                type="checkbox"
                onClick={() => clickHandler(key, elem.key)}
                className="single-checkbox"
              />
              <div className="single-report-header-box">
                <div className="single-report-header">
                  <div
                    className="font3"
                    onClick={() => clickHandler(key, elem.key)}
                    style={{ cursor: "pointer" }}
                  >
                    {elem.name}
                  </div>
                </div>
                <img
                  src={elem.image}
                  className="single-report-image-box"
                  onClick={() => handleReports(key, elem.key)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AllReportsTemplate;
