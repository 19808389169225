import React, { useState, useEffect } from "react";
import PartnerManagerTemplate from "../PartnerManager/PartnerManagerTemplate/PartnerManagerTemplate.jsx";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utility/common/api";

function ManagerCompDateReq() {
  const params = useParams();
  const [managerCompDateReqData, setManagerCompDateReqData] = useState(null);
  useEffect(() => {
    if (params.id) {
      const url = `/api/approvaldisplay/${params.id}`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data) {
            setManagerCompDateReqData(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <div>
      {managerCompDateReqData && (
        <PartnerManagerTemplate
          heading={`Request No. ${params.id}`}
          subHead={"Onboarding Comp Data Request"}
          nav1={"Dashboard"}
          nav2={`Request No. ${params.id}`}
          nav1col={"navGreen"}
          nav2col={"navBlack"}
          showBlackHeadArrow={true}
          showPeerSet={false}
          showExecuting={true}
          showVertLine5={true}
          vertLineMargin={"116px"}
          partnerTimeline={true}
          showIPField={true}
          data={managerCompDateReqData}
          showApprover={false}
          showDownload={true}
          reqNo={params.id}
          show2Btns={true}
          showPeersetDownload={false}
        />
      )}
    </div>
  );
}

export default ManagerCompDateReq;
