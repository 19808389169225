export const UPLOAD_COMPENSATION_ACTION = "UPLOAD_COMPENSATION_ACTION";

const initialState = {
  open: false,
};

const UploadCompensationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_COMPENSATION_ACTION: {
      return {
        ...state,
        open: action.payload.open,
      };
    }
  }
  return state;
};

export default UploadCompensationReducer;
