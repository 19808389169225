import { Component, useState, useEffect } from "react";
import "./loginExt.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import login from "../../assets/images/Login.svg";
import line from "../../assets/images/Line.svg";
import info from "../../assets/images/info.svg";
import backarrow from "../../assets/images/BackArrow.svg";
import Link from "@mui/material/Link";
import MasTextField from "../../domain-component/MasTextField";
import axios from "axios";
import api from "../../utility/common/api";
import welcomeImage from "../../../src/assets/images/Welcome2.svg";
import { getTranslation } from "../../utility/i18n/i18n";
const LoginExternal = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [messageIndex, setMessageIndex] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const userToken = localStorage.getItem("authentication-token");
    if (userToken) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + userToken;
      getUserDetails();
    }
    const startInterval = setInterval(() => {
        setMessageIndex((t) => {
          if(t>=2){
            return 0
          }
          else{
            return t+1
          }
        });
    }, 5000);
    return () => {
      clearInterval(startInterval);
    };
    
  }, []);


  const getUserDetails = async () => {
    const url = "/api/me";
    await api
      .get({ url })
      .then(({ data }) => {
        localStorage.setItem("userType", data.userType);
        if (data.userType == "USER") {
          navigate("/dashboard");
        } else if (data.userType == "PARTNER") {
          navigate("/partner-dashboard");
        } else if (data.userType == "MANAGER") {
          navigate("/manager-dashboard");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleLogin = async () => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (username && password) {
      if (regEmail.test(username)) {
        const data = {
          username: username,
          password: password,
        };
        const url = "/api/authenticate";
        await api
          .post({ url, data })
          .then((data) => {
            if (data) {
              localStorage.setItem("authentication-token", data.data.token);
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + data.data.token;
              getUserDetails();
            }
          })
          .catch((error) => {
            console.log("Error", error);
          });
      } else {
        setShowError(true);
        setErrorMessage("Invalid Email Address");
      }
    } else {
      setShowError(true);
      setErrorMessage("Enter credentials");
    }
  };
  const handleLoginOption = () => {
    navigate("/login-option");
  };
  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleRegister = () => {
    navigate("/login-request");
  };
  return (
    <div className="container">
      <div className="login-about-container">
        <div className="font1"> Welcome to CompScale !!</div>
        <div className="login-ext-image">
          <img src={welcomeImage} width="100%" height="100%"></img>
        </div>
        <div className="font3 ">
          {getTranslation("login-external")["description"][messageIndex]}
        </div>
        <div className="login-abt-sl">
          <span className={(messageIndex == 0 ? "login-abt-sl1" : "login-abt-sl2")} onClick={()=>{setMessageIndex(0)}}></span>
          <span className={(messageIndex == 1 ? "login-abt-sl1" : "login-abt-sl2")} onClick={()=>{setMessageIndex(1)}}></span>
          <span className={(messageIndex == 2 ? "login-abt-sl1" : "login-abt-sl2")} onClick={()=>{setMessageIndex(2)}}></span>
        </div>
 
      </div>
      <div className="login-input-container">
        <div className="login-input">
          <div className="font2"> Login via External ID</div>
          <div className="font3">Enter your details below</div>
          <div>
            <MasTextField
              label="Username"
              onChange={(e) => {
                setUsername(e.target.value);
                setShowError(false);
              }}
              styledClass="login-name-textfield"
              name="username"
              placeholder="Email id"
              type="input"
            />
          </div>
          <div className="login-name-textfield">
            <MasTextField
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              placeholder="Password"
              type="Password"
            />
          </div>
          {showError && (
            <div className="font3 error-message">{errorMessage}</div>
          )}
          <div className="font3">
            <Link
              sx={{ color: "#0076A8", cursor: "pointer" }}
              underline="none"
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </Link>
          </div>

          <div className="login-ext-external">
            <Button
              variant="primary"
              size="large"
              sx={{
                textTransform: "none",
                color: "white",
                width: "25vw",
                height: "3vw",
                fontWeight: "400",
                fontSize: "1.09vw",
                lineHeight: "2.7vh",
              }}
              onClick={handleLogin}
            >
              Submit
            </Button>
          </div>
          <div className="login-ext-register">
            <div className="font3"> Not an existing user?</div>
            <div className="font3">
              <Link
                sx={{ color: "#26890D", cursor: "pointer" }}
                underline="none"
                onClick={handleRegister}
              >
                Register Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginExternal;
