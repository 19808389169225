import React, { useState, useEffect } from "react";
import "./onboarding.scss";
import { Modal, Typography, Box } from "@mui/material";
import FirstContent from "../FirstContent/firstContent";
import OnboardFormSteps from "./OnboardFormSteps/OnboardFormSteps";
import OnboardFormArea from "./OnboardFormArea/OnboardFormArea";
import MasButton from "../MasButton/masButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "580px",
  height: "350px",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  padding: "40px 50px",
  justifyContent: "end",
  alignItems: "center",
  gap: "9px",
};

const Onboarding = () => {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div className="onboard-Title">
        <FirstContent
          header="Onboarding"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper."
        />
      </div>
      <div className="onboard-form-area">
        <OnboardFormArea />
        <OnboardFormSteps green="01" />
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="onboard-modal-header">
            <Typography variant="subtitle3">Welcome to CompScale</Typography>
          </div>
          <div
            id="modal-modal-description"
            sx={{ mt: 2 }}
            className="onboard-modal-same-prop"
          >
            <div className="onboard-modal-description-boxes">
              <Typography variant="body2" style={{ textAlign: "center" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
                aliquam, purus sit amet luctus venenatis, lectus magna fringilla
                urna
              </Typography>
            </div>
            <div className="onboard-modal-button-group" onClick={handleClose}>
              <MasButton
                buttonName="Proceed with Onboarding"
                type="modal-contained-button-normal"
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Onboarding;
