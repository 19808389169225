import axios from "axios";

const header = {
  Accept: "*/*",
};
const call = async ({ method, url, data, params, baseURL, headers }, type) => {
  baseURL = baseURL ? baseURL : "";
  headers = headers ? { ...header, ...headers } : header;
  const response = await axios.request({
    baseURL,
    url: url,
    method,
    data,
    params,
    headers,
  });
  return response && response.status >= 200 && response.status < 300
    ? response
    : new Error(`API Error!' ${response.statusText}`);
};

const post = ({ url, baseURL, params, data, headers }, type) => {
  if (!baseURL) {
    baseURL = ``;
  }
  return call({ method: "POST", url, baseURL, params, data, headers }, type);
};

const put = ({ url, baseURL, params, data, headers }, type) => {
  if (!baseURL) {
    baseURL = ``;
  }
  return call({ method: "PUT", url, baseURL, params, data, headers }, type);
};

const get = ({ url, baseURL, params, headers }, type) => {
  if (!baseURL) {
    baseURL = ``;
  }
  return call({ method: "GET", url, baseURL, params, headers }, type);
};

const patch = ({ url, baseURL, params, data, headers }, type) => {
  if (!baseURL) {
    baseURL = ``;
  }
  return call({ method: "PATCH", url, baseURL, params, data, headers }, type);
};

const _delete = ({ url, baseURL, params, data, headers }, type) => {
  if (!baseURL) {
    baseURL = ``;
  }
  return call({ method: "DELETE", url, baseURL, params, data, headers }, type);
};

export default {
  call,
  get,
  post,
  put,
  patch,
  delete: _delete,
};
