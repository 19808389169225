import { Typography } from "@mui/material";
import React, { useState } from "react";
import "./peerSetBox.scss";
import RightArrowHead from "../../../assets/images/rightArrowHead.png";
import { useNavigate } from "react-router-dom";
import { getTranslation } from "../../../utility/i18n/i18n";
const PeerSetBox = () => {
  const navigate = useNavigate();
  

  return (
    <div className="peer-set-box">
      <div className="peer-set-box-header">
        <Typography
          variant="subtitle3"
          className="peer-set-box-header-text font13"
        >
          Peer Set
        </Typography>
        <img src={RightArrowHead} className="peer-set-box-image" />
      </div>
      <div className="peer-set-box-data">
        <Typography variant="body2" className="peer-set-box-number font3">
        {getTranslation("dashboard")["description3"]}
        </Typography>
      </div>
      <div className="peer-set-box-button">
        <Typography
          variant="body2"
          className="green-button-font"
          onClick={() => {
            navigate("/peerset1");
          }}
        >
          Request Peer Set
        </Typography>
        <Typography
          variant="body2"
          className="green-button-font peer-prev-act"
          onClick={() => {
            navigate("/peerset4");
          }}
        >
          Previous Action
        </Typography>
      </div>
    </div>
  );
};

export default PeerSetBox;
