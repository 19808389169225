import { Typography } from "@mui/material";
import { textAlign } from "@mui/system";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import api from "../../../utility/common/api";
import { showLoader } from "../../../store/actions/global";
import { useDispatch } from "react-redux";

const MixedBarLineChart = (props) => {
  // const [label, setLabel] = useState([]);
  // const [data1, setData1] = useState([]);
  // const [data2, setData2] = useState([]);
  // const [data3, setData3] = useState([]);

  const dispatch = useDispatch();

  const [seriesData, setSeriesData] = useState(null)
  const [chartData, setChartData] = useState(null);

  const getSeries = (apiRes) => {

    // let d1 = [],
    //   d2 = [],
    //   d3 = [],
    //   lab = [];

    const seriesArray = {
      d1: [],
      d2: [],
      d3: [],
      label: []
    }
    apiRes.forEach((el, i) => {
      // d1.push(el.cellValues["percentile50"]);
      // d2.push(el.cellValues["average"]);
      // d3.push(el.cellValues["sponsor_percentile50"]);
      // lab.push(el.cellValues["Job ID"]);

      seriesArray.d1.push(el.cellValues["percentile50"]);
      seriesArray.d2.push(el.cellValues["average"]);
      seriesArray.d3.push(el.cellValues["sponsor_percentile50"]);
      seriesArray.label.push(el.cellValues["Job ID"]);

      if (i === apiRes.length - 1) {
        // setData1(d1);
        // setData2(d2);
        // setData3(d3);
        // setLabel(lab);

        setSeriesData(seriesArray);
      }
    });
  };

  const prepareChartData = () => {
    setChartData({
      options: {
        chart: {
          height: 350,
          type: "line",
        },
        stroke: {
          width: [0, 0],
        },
        title: {
          text: "Performance Pay as a % of Total Cost to Company",
          offsetY: -5,
          style: {
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0px",
          },
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "right",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 1, 2],
          textAnchor: "middle",
          offsetX: 0,
          offsetY: -7,
          style: {
            border: "0px",
            colors: ["none", "none", "#FFCD00"],
            fontWeight: "400",
            fontSize: "10px",
            lineHeight: "20px",
          },
          background: {
            borderWidth: 0,
            padding: 4,
            borderRadius: 1,
            foreColor: "#000000",
          },
        },
        labels: seriesData.label,
        xaxis: {
          title: {
            text: "Roles or grades",
            offsetY: 90,
            style: {
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0px",
            },
          },
        },
        yaxis: [
          {
            title: {
              text: "Payment (in %)",
              style: {
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0px",
              },
            },
          },
        ],
      },
      series: [
        {
          name: "Perc",
          type: "column",
          data: seriesData.d1,
          color: "#C4D600",
        },
        {
          name: "S Perc",
          type: "column",
          data: seriesData.d3,
          color: "#00ABAB",
        },
        {
          name: "Average",
          type: "line",
          data: seriesData.d2,
          color: "#005059",
        },
      ]
    })
  };

  useEffect(() => {
    if (seriesData) {
      prepareChartData()
    }
  }, [seriesData]);

  useEffect(() => {
    console.log('bar chart>>>>>');
    if (props.primKey) {
      dispatch(showLoader(true));
      const url = `/api/report/${props.primKey}?chartType=bar&percentile=50`;
      api
        .get({ url })
        .then(({ data }) => {
          if (data.rows) {
            getSeries(data.rows);
            dispatch(showLoader(false));
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(showLoader(false));
        });
    }
  }, []);
  // const series = [
  //   {
  //     name: "Perc",
  //     type: "column",
  //     data: data1,
  //     color: "#C4D600",
  //   },
  //   {
  //     name: "S Perc",
  //     type: "column",
  //     data: data3,
  //     color: "#00ABAB",
  //   },
  //   {
  //     name: "Average",
  //     type: "line",
  //     data: data2,
  //     color: "#005059",
  //   },
  // ];

  // const options = {
  //   chart: {
  //     height: 350,
  //     type: "line",
  //   },
  //   stroke: {
  //     width: [0, 0],
  //   },
  //   title: {
  //     text: "Performance Pay as a % of Total Cost to Company",
  //     offsetY: -5,
  //     style: {
  //       fontWeight: "400",
  //       fontSize: "14px",
  //       lineHeight: "20px",
  //       letterSpacing: "0px",
  //     },
  //   },
  //   legend: {
  //     show: true,
  //     position: "top",
  //     horizontalAlign: "right",
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     enabledOnSeries: [0, 1, 2],
  //     textAnchor: "middle",
  //     offsetX: 0,
  //     offsetY: -7,
  //     style: {
  //       border: "0px",
  //       colors: ["none", "none", "#FFCD00"],
  //       fontWeight: "400",
  //       fontSize: "10px",
  //       lineHeight: "20px",
  //     },
  //     background: {
  //       borderWidth: 0,
  //       padding: 4,
  //       borderRadius: 1,
  //       foreColor: "#000000",
  //     },
  //   },
  //   labels: label,
  //   xaxis: {
  //     title: {
  //       text: "Roles or grades",
  //       offsetY: 90,
  //       style: {
  //         fontWeight: "400",
  //         fontSize: "12px",
  //         lineHeight: "16px",
  //         letterSpacing: "0px",
  //       },
  //     },
  //   },
  //   yaxis: [
  //     {
  //       title: {
  //         text: "Payment (in %)",
  //         style: {
  //           fontWeight: "400",
  //           fontSize: "12px",
  //           lineHeight: "16px",
  //           letterSpacing: "0px",
  //         },
  //       },
  //     },
  //   ],
  // };

  return (
    <div>
      <div>
        {/* <Chart options={options} series={series} height="500" /> */}
        {chartData && chartData.options && chartData.series && <Chart options={chartData.options} series={chartData.series} height="500" type="bar" />}
      </div>
    </div>
  );
};

export default MixedBarLineChart;
