import React, { useState } from "react";
import TechnologyIndex from "./TechnologyIndex/technologyIndex";
import FirstContent from "../FirstContent/firstContent";
import "./dashboard.scss";
import DashboardContentData from "./dashboardContentData";
import NewsBox from "./NewsBox/NewsBox";
import { Modal, Typography, Box } from "@mui/material";
import CrossIcon from "../../assets/images/blackCrossIcon.png";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { uploadCompensationAction } from "../../store/actions/global";
import { getTranslation } from "../../utility/i18n/i18n";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "51vw",
  height: "44.1vh",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Dashboard = () => {
  const [chooseOption, setChooseOption] = useState("");
  const dispatch = useDispatch();
  const open = useSelector((state) => state.UploadCompensationReducer.open);
  const [modalSection, setModalSection] = useState([
    {
      path: "incumbent",
      header: getTranslation("dashboard-compUpload-modal")["incubment"]["header"],
      desc: getTranslation("dashboard-compUpload-modal")["incubment"]["desc"],
    },
    {
      path: "uploadSalary",
      header: getTranslation("dashboard-compUpload-modal")["salary"]["header"],
      desc: getTranslation("dashboard-compUpload-modal")["salary"]["desc"],
    },
  ]);
  const navigate = useNavigate();
  const handleChooseOption = (event) => {
    setChooseOption(event);
    handleProceed();
  };
  const handleProceed = () => {
    let value = "";
    if (chooseOption === "") {
      return null;
    } else {
      value = "/" + chooseOption;
      navigate(value);
    }
  };

  return (
    <div>
      <TechnologyIndex />
      <div className="dashboard-content">
        <FirstContent
          header="Welcome, Deloitte"
          description = {getTranslation("dashboard")["description"]}
        />
        <div className="dashboard-content-box">
          <DashboardContentData />
          <NewsBox />
        </div>
      </div>
      <Modal open={open}>
        <Box sx={style}>
          <div className="modal-header">
            <Typography variant="body1" className="font11">
              Select Compensation Uploading.
            </Typography>
            <div className="cross-icon-image">
              <img
                src={CrossIcon}
                onClick={() =>
                  dispatch(uploadCompensationAction({ open: false }))
                }
              />
            </div>
          </div>
          <div
            id="modal-modal-description"
            sx={{ mt: 2 }}
            className="modal-description-area"
          >
            {modalSection.map((elem) => {
              return (
                <div
                  key={elem.path}
                  className="modal-description-boxes"
                  name="incumbent"
                  onClick={(event) => handleChooseOption(elem.path)}
                  style={
                    chooseOption === elem.path
                      ? { border: "1px solid blue" }
                      : {}
                  }
                >
                  <Typography variant="body1" color={"#000"} className="font12">
                    {elem.header}
                  </Typography>
                  <div className="modal-description-boxes-desc">
                    <Typography
                      className="font6"
                      style={{ "fontWeight": "400" }}
                    >
                      {elem.desc}
                    </Typography>
                  </div>
                </div>
              );
            })}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Dashboard;
