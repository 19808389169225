import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function SortingIcon(props) {
  const { createSortHandler, headCell, orderBy, order } = props;
  const isDisabled = orderBy != headCell.id;
  const iconColor = isDisabled ? "#ACACAC" : "#4A4A4A";
  const sortedUpIconColor = order == "asc" ? "#4A4A4A" : "#ACACAC";
  const sortedDownIconColor = order == "desc" ? "#4A4A4A" : "#ACACAC";


    return (
        <div className="sorting-icon-container"
            disabled={isDisabled}
            onClick={createSortHandler(headCell.id)}>
            <ArrowDropUpIcon
                style={{
                    marginBottom: '-20%',
                    color: isDisabled ? iconColor : sortedUpIconColor
                }}
            />
            <ArrowDropDownIcon
                style={{
                    color: isDisabled ? iconColor : sortedDownIconColor,
                    marginLeft:'-45%'
                }}
            />
        </div>
    )
};
