import React from "react";
import "./reports.scss";
import ReportBoxContent from "./ReportBoxContent";
import dwIcon2 from "./images/dwIcon2.svg";
import FirstContent from "../PartnerManager/PartnerManagerFirstContent/FirstContent";
import { useNavigate, useParams } from "react-router-dom";

function Reports3() {
  const params = useParams();

  return (
    <div className="first-content-style">
      <FirstContent
        showGreenHeadArrow={true}
        showNav3={true}
        heading={"Reports"}
        nav1={"Home"}
        nav2={"Reports"}
        nav3={"View"}
        nav1col={"navGreen"}
        nav2col={"navGreen"}
        nav3col={"navBlack"}
      />
      <div id="repDesc" className="font8">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
        purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor
        rhoncus dolor purus non enim praesent elementum facilisis leo, vel
        fringilla est ullamcorper.
      </div>
      <ReportBoxContent id={params.id} />
      <img src={dwIcon2} style={{ float: "right" }} />
    </div>
  );
}

export default Reports3;
