import { Typography } from "@mui/material";
import React, { useState } from "react";
import Chart from "react-apexcharts";

const StackedBarChart = () => {
  const [options, setOptions] = useState({
    chart: {
      stacked: true,
      stackType: "100%",
      foreColor: "#000000",
    },
    title: {
      text: "Performance Pay as a % of Total Cost to Company",
      offsetY: -5,
      style: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0px",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#000000", "#000000", "#FFFFFF", "#FFFFFF"],
      },
    },
    xaxis: {
      categories: ["MG5", "MG4", "MG3", "MG2", "MG1", "EXE", "OG"],
      title: {
        text: "Roles or grades",
        offsetY: 90,
        style: {
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0px",
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Payment (in %)",
          style: {
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "20px",
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      markers: {},
      itemMargin: {},
      labels: {},
    },
  });

  const [series, setSeries] = useState([
    {
      name: "Sales",
      data: [0, 0, 0, 15, 15, 15, 15],
      color: "#e8f0a8",
    },
    {
      name: "Incentives",
      data: [100, 100, 100, 85, 85, 85, 85],
      color: "#C4D600",
    },
    {
      name: "Non Sales",
      data: [76, 80, 83, 83, 83, 83, 83],
      color: "#00ABAB",
    },
    {
      name: "Variable Pay",
      data: [24, 20, 17, 17, 17, 17, 17],
      color: "#c4f2f2",
    },
  ]);

  return (
    <div>
      <div>
        <div>
          <Chart
            options={options}
            series={series}
            type="bar"
            // width="600"
            height="500"
          />
        </div>
      </div>
    </div>
  );
};

export default StackedBarChart;
