import React from "react";
import commGreenArrow from "./images/commGreenArrow.svg";
import "./CommLeftContent.scss";

function CommLeftContent(props) {
  return (
    <div id="commLeftBox">
      {props.showMyActLeftCont ? (
        <>
          <div id="commSumm">Summary</div>
          <div id="commTotPosts" className="commSummItems">
            <div className="commFlexRow">
              <div className="headItems">Total Posts(22)</div>
              <img src={commGreenArrow} id="commGreenArrow" />
            </div>
            <div className="commDescItems">All queries posted</div>
          </div>
          <div className="commSummItems">
            <div className="commFlexRow">
              <div className="headItems">Recent Updates(3)</div>
              <img src={commGreenArrow} id="commGreenArrow" />
            </div>
            <div className="commDescItems">Answers recieved from community</div>
          </div>
          <div className="commSummItems">
            <div className="headItems">Replies(77)</div>
            <div className="commDescItems">My answers</div>
          </div>

          <div id="commList">Listed Items</div>
          <div className="commListItems">
            <div className="commFlexRow commListItemss">
              <div className="headItems">Pinned Posts (21)</div>
              <img src={commGreenArrow} id="commGreenArrow" />
            </div>
          </div>
          <div className="commListItems">
            <div className="commFlexRow commListItemss">
              <div className="headItems">Deleted (14)</div>
              <img src={commGreenArrow} id="commGreenArrow" />
            </div>
          </div>
        </>
      ) : null}
      {props.showCommLeftCont ? (
        <>
          <div id="commSumm">Feeds</div>
          <div id="commTotPosts" className="commSummItems">
            <div className="commFlexRow">
              <div className="headItems">Latest</div>
              <img src={commGreenArrow} id="commGreenArrow" />
            </div>
            <div className="commDescItems">Newest queries first</div>
          </div>
          <div className="commSummItems">
            <div className="commFlexRow">
              <div className="headItems">Trending</div>
              <img src={commGreenArrow} id="commGreenArrow" />
            </div>
            <div className="commDescItems">Maximum replies and views</div>
          </div>

          <div id="commList">Explore by </div>
          <div className="commListItems">
            <div className="commFlexRow commListItemss">
              <div className="headItems">Query Type</div>
              <img src={commGreenArrow} id="commGreenArrowDown" />
            </div>
          </div>
          <div className="commListItems">
            <div className="commFlexRow commListItemss">
              <div className="headItems">Demography</div>
              <img src={commGreenArrow} id="commGreenArrowDown" />
            </div>
          </div>
          <div className="commListItems">
            <div className="commFlexRow commListItemss">
              <div className="headItems">Level</div>
              <img src={commGreenArrow} id="commGreenArrowDown" />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default CommLeftContent;
