import React, { useState } from "react";
import Greendot from "./../../assets/images/greendot.png";
import Logo from "./../../assets/images/logo.png";
import Userpic from "./../../assets/images/Userpic.png";
import Avatar from "@mui/material/Avatar";
import "./header.scss";
import SearchIcon from "./../../assets/images/search-icon.png";
import { Divider } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

const commonNavLinks = ["onereport"];
const managernavItemsBasedonRoute = {
  "onereport": [
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
    { id: 1, menuItem: "Reports", routingURl: "/onereport" },
  ],
};
const usernavItemsBasedonRoute = {
  "onereport": [
    { id: 0, menuItem: "Dashboard", routingURl: "/dashboard" },
    { id: 1, menuItem: "Reports", routingURl: "/onereport" },
  ],
};
const partnernavItemsBasedonRoute = {};
const navItemsBasedonRoute = {
  "onboarding": [
    { id: 0, menuItem: "Dashboard", routingURl: "/dashboard" },
    { id: 1, menuItem: "Reports", routingURl: "/reports" },
    { id: 2, menuItem: "Requests", routingURl: "/requests" },
  ],
  "manager-dashboard": [
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
    { id: 1, menuItem: "Upload Documents", routingURl: "/multiFileUpload" },
    {
      id: 2,
      menuItem: "History of Documents",
      routingURl: "/multiFileUploadHistory",
    },
    {
      id: 3,
      menuItem: "Auto Review",
      routingURl: "/multiFileUploadreview",
    },
  ],
  "assign-data": [
    { id: 0, menuItem: "Dashboard", routingURl: "/partner-dashboard" },
  ],
  "managerPeerReq": [
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
  ],
 " managerCompDateReq": [
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
  ],
  "managerDipstickSurvey": [
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
  ],
  "partner-dashboard": [
    { id: 0, menuItem: "Dashboard", routingURl: "/partner-dashboard" },
  ],
  "partnerPeerReq": [
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
    { id: 1, menuItem: "User Control", routingURl: "/reports" },
    { id: 2, menuItem: "Community", routingURl: "/requests" },
  ],
  "partnerCompDateReq": [
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
    { id: 1, menuItem: "User Control", routingURl: "/reports" },
    { id: 2, menuItem: "Community", routingURl: "/requests" },
  ],
  "reports": [
    { id: 0, menuItem: "Dashboard", routingURl: "/dashboard" },
    { id: 1, menuItem: "Reports", routingURl: "/reports" },

  ],
  "reports1": [
    { id: 0, menuItem: "Dashboard", routingURl: "/dashboard" },
    { id: 1, menuItem: "Reports", routingURl: "/reports1" },

  ],
  "reportsView": [
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
    { id: 1, menuItem: "Reports", routingURl: "/reportsView" },
  ],
  "partnerCompDataReq": [
    { id: 0, menuItem: "Dashboard", routingURl: "/partner-dashboard" },
    { id: 1, menuItem: "Reports", routingURl: "/reports" },

  ],
  "managerReportGenReq": [
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
    { id: 1, menuItem: "Reports", routingURl: "/managerReportGenReq" },
  ],
  "managerReportGenReq2": [
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
    { id: 1, menuItem: "Reports", routingURl: "/reports" },

  ],
  "dashboard": [
    { id: 0, menuItem: "Dashboard", routingURl: "/dashboard" },

  ],
  "multiFileUpload": [
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
    { id: 1, menuItem: "Upload Documents", routingURl: "/multiFileUpload" },
    {
      id: 2,
      menuItem: "History of Documents",
      routingURl: "/multiFileUploadHistory",
    },
    { id: 3,
      menuItem: "Auto Review",
      routingURl: "/multiFileUploadreview",}
  ],
  "multiFileUploadHistory": [
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
    { id: 1, menuItem: "Upload Documents", routingURl: "/multiFileUpload" },
    {
      id: 2,
      menuItem: "History of Documents",
      routingURl: "/multiFileUploadHistory",
    },
    { id: 3,
      menuItem: "Auto Review",
      routingURl: "/multiFileUploadreview",}
  ],
  "multiFileUploadreview":[
    { id: 0, menuItem: "Dashboard", routingURl: "/manager-dashboard" },
    { id: 1, menuItem: "Upload Documents", routingURl: "/multiFileUpload" },
    {
      id: 2,
      menuItem: "History of Documents",
      routingURl: "/multiFileUploadHistory",
    },
    { id: 3,
      menuItem: "Auto Review",
      routingURl: "/multiFileUploadreview",}
  ]
};
const Header = () => {
  let navData;
  const location = useLocation().pathname.split("/")[1];
  let flag = false;
  if (commonNavLinks.includes(location)) {
    if (localStorage.getItem("userType") == "MANAGER") {
      Object.keys(managernavItemsBasedonRoute).forEach((el) => {
        if (location == el) {
          navData = managernavItemsBasedonRoute[el];
        }
      });
    } else if (localStorage.getItem("userType") == "USER") {
      Object.keys(usernavItemsBasedonRoute).forEach((el) => {
        if (location == el) {
          navData = usernavItemsBasedonRoute[el];
        }
      });
    } else {
      Object.keys(partnernavItemsBasedonRoute).forEach((el) => {
        if (location == el) {
          navData = partnernavItemsBasedonRoute[el];
        }
      });
    }
  } else {
    Object.keys(navItemsBasedonRoute).forEach((el) => {
      if (location == el) {
        navData = navItemsBasedonRoute[el];
        flag = true;
      }
    });
    if (flag == false) {
      navData = navItemsBasedonRoute["dashboard"];
    }
  }
  const [userProfileClicked, setUserProfileClicked] = useState(false);
  const navigate = useNavigate();
  const userProfileOnClick = () => {
    setUserProfileClicked((prev) => !prev);
  };
  const logoutOnClick = () => {
    localStorage.removeItem("authentication-token");
    localStorage.removeItem("userType");
    sessionStorage.clear();
    navigate("/login-external");
  };
  return (
    <div className="header-main-div font3">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="header-logo-image">
          <img src={Logo} />
          <img src={Greendot} />
        </div>
        <Divider
          color="#53565A"
          flexItem
          orientation="vertical"
          sx={{ margin: "0 1vw", height: "2vw" }}
        />

        <li style={{ color: "#ffffff", listStyleType: "none" }}>CompScale</li>
      </div>
      <ul className="header-nav-list">
        {navData.map((el) => (
          <NavLink
            key={location + el["menuItem"] + el["id"]}
            style={{ color: "#ffffff" }}
            activeclassname="active"
            to={el["routingURl"]}
            className={({ isActive }) => (isActive ? "activeLink" : "tab")}
          >
            <li className="header-nav-list-item">{el["menuItem"]}</li>
          </NavLink>
        ))}
      </ul>
      <div className="header-user-avatar">
        <li className=" font3 header-nav-list-item" onClick={logoutOnClick}>
          Logout
        </li>
      </div>
    </div>
  );
};

export default Header;
