import React from "react";
import "./FirstContent.scss";
import GreenHeadArrow from "./images/greenHeadArrow.svg";
import BlackHeadArrow from "./images/blackHeadArrow.svg";
import { useParams, useLocation, useNavigate } from "react-router-dom";

function FirstContent(props) {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const onDashboardClick = () => {
    if (localStorage.getItem("userType") == "MANAGER") {
      navigate("/manager-dashboard");
    } else {
      navigate("/dashboard");
    }
  };
  return (
    <div className="first-content">
      <div id="flexRow">
        {props.showGreenHeadArrow ? (
          <>
            <img src={GreenHeadArrow} id="GreenHeadArrow" />
          </>
        ) : null}
        {props.showBlackHeadArrow ? (
          <>
            <img
              src={BlackHeadArrow}
              id="BlackHeadArrow"
              onClick={onDashboardClick}
            />
          </>
        ) : null}
        <div id="firstHead">{props.heading}</div>
      </div>

      <div id="navs">
        <a href="#" id={props.nav1col} onClick={onDashboardClick}>
          {props.nav1}
        </a>
        /
        <a href="" id={props.nav2col}>
          {props.nav2}
        </a>
        {props.showNav3 ? (
          <>
            /
            <a href="" id={props.nav3col}>
              {props.nav3}
            </a>
          </>
        ) : null}
        {props.showNav4 ? (
          <>
            /
            <a href="" id={props.nav4col}>
              {props.nav4}
            </a>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default FirstContent;
