import React from "react";
import { Typography } from "@mui/material";

function CompensationHead() {
  return (
    <div>
      <Typography
        variant="subtitle1"
        id="comp-head"
      >{`Compensation Files Update`}</Typography>
      <Typography variant="body2" id="comp-desc">
        {`Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper.`}
      </Typography>
    </div>
  );
}

export default CompensationHead;
