import React from "react";
import "./CommPostQue.scss";
import commRadBtn from "./images/commRadBtn.svg";
import commToggleBase from "./images/commToggleBase.svg";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { MenuItem, Select } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SubscriptIcon from "@mui/icons-material/Subscript";
import SuperscriptIcon from "@mui/icons-material/Superscript";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import commX from "./images/commX.svg";

function CommPostQue(props) {
  const navigate = useNavigate();
  const navigateToComm1 = () => {
    navigate("/comm1");
  };
  const navigateToComm2 = () => {
    navigate("/comm2");
  };
  const navigateToComm3 = () => {
    navigate("/comm3");
  };
  return (
    <div id="commPostBox">
      <img src={commX} id="commX" onClick={navigateToComm1} />
      <div id="commPostHeading">Post a Query</div>
      <div id="commPostTypeHead">Type</div>
      <div className="commFlexRow">
        {props.showGen ? (
          <>
            <div id="commPostGenActive">General</div>
            <img
              src={commRadBtn}
              id="commRadBtn"
              style={{ "margin-top": "10px" }}
              onClick={navigateToComm3}
            />
            <img
              src={commToggleBase}
              id="commToggleBase"
              style={{ "margin-top": "10px" }}
            />
            <div id="commPostSkSeSpDisabled">Skill Set Specific</div>
          </>
        ) : null}
        {props.showSkSe ? (
          <>
            <div id="commPostGenDisabled">General</div>
            <img
              src={commToggleBase}
              id="commToggleBase"
              style={{ "margin-top": "10px" }}
            />
            <img
              src={commRadBtn}
              id="commRadBtn"
              style={{ "margin-top": "10px" }}
              onClick={navigateToComm2}
            />
            <div id="commPostSkSeSpActive">Skill Set Specific</div>
          </>
        ) : null}
      </div>
      <div className="commFlexRow" style={{ "align-items": "flex-start" }}>
        {props.showGen ? (
          <>
            <div>
              <div className="commPostSlct">Level</div>
              <div
                style={{
                  width: "227px",
                  "margin-left": "48px",
                  "margin-top": "10px",
                }}
              >
                <FormControl fullwidth size="small">
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select label="Select">
                    <MenuItem>Managers</MenuItem>
                    <MenuItem>Partners</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="commPostSlct">Demography</div>
              <div
                style={{
                  width: "227px",
                  "margin-left": "48px",
                  "margin-top": "10px",
                }}
              >
                <FormControl fullwidth size="small">
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select label="Select" />
                </FormControl>
              </div>
            </div>
          </>
        ) : null}
        {props.showSkSe ? (
          <>
            <div>
              <div className="commPostSlct">Skill Set*</div>
              <div
                style={{
                  width: "227px",
                  "margin-left": "48px",
                  "margin-top": "10px",
                }}
              >
                <FormControl fullwidth size="small">
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select label="Select">
                    <MenuItem>HR</MenuItem>
                    <MenuItem>Accounts</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="commPostSlct">Level</div>
              <div
                style={{
                  width: "227px",
                  "margin-left": "48px",
                  "margin-top": "10px",
                }}
              >
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select label="Select">
                    <MenuItem>Managers</MenuItem>
                    <MenuItem>Partners</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="commPostSlct">Demography</div>
              <div
                style={{
                  width: "227px",
                  "margin-left": "48px",
                  "margin-top": "10px",
                }}
              >
                <FormControl fullwidth size="small">
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select label="Select" />
                </FormControl>
              </div>
            </div>
          </>
        ) : null}
        <div>
          <div id="commPostQry">Query *</div>
          <div id="commPostTxtBtns">
            <ToggleButtonGroup size="small">
              <ToggleButton>
                <FormatBoldIcon />
              </ToggleButton>
              <ToggleButton>
                <FormatItalicIcon />
              </ToggleButton>
              <ToggleButton>
                <FormatUnderlinedIcon />
              </ToggleButton>
              <ToggleButton>
                <FormatStrikethroughIcon />
              </ToggleButton>
              <ToggleButton>
                <SuperscriptIcon />
              </ToggleButton>
              <ToggleButton>
                <SubscriptIcon />
              </ToggleButton>
              <ToggleButton>
                <FormatListBulletedIcon />
              </ToggleButton>
              <ToggleButton>
                <FormatListNumberedIcon />
              </ToggleButton>
              <ToggleButton>
                <FormatAlignLeftIcon />
              </ToggleButton>
              <ToggleButton>
                <FormatAlignCenterIcon />
              </ToggleButton>
              <ToggleButton>
                <AddIcon />
              </ToggleButton>
              <ToggleButton>
                <MoreHorizIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div id="commPostTxtBox">
            <form>
              <TextField
                id="outlined-multiline-flexible"
                label=""
                multiline
                fullwidth
                minRows={15}
              />
            </form>
          </div>
        </div>
      </div>
      <button disabled="disabled" id="commPostBtnCncl">
        Cancel
      </button>
      <button disabled="disabled" id="commPostBtnSbmt">
        Submit
      </button>
    </div>
  );
}

export default CommPostQue;
