export const SET_REPORT_NUMBERS = "SET_REPORT_NUMBERS";

const initialState = {
  reportsArray: [],
  showLoader: false
};

const ReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT_NUMBERS: {
      return {
        ...state,
        reportsArray: [...action.payload.reportArray],
      };
    }
    case "SHOW_LOADER":
      return { ...state, showLoader: action.payload };
  };

  return state;
};

export default ReportsReducer;
