import React, { useState, useEffect } from "react";
import "./DataTeamDashboard.scss";
import MasSelect from "../../domain-component/MasSelect";
import MasContainer from "../../domain-component/MasContainer";
import MasTextField from "../../domain-component/MasTextField";
import { Breadcrumbs, Link, Typography, Grid } from "@mui/material";
import MasLinkButton from "../../domain-component/MasLinkButton";
import Table from "../../domain-component/MasTable/Table";
import icon from "../../modules/ManagerPersona/Images/manager-icon.svg";

import { useNavigate } from "react-router-dom";

const DataTeamDashboard = () => {
  const colorProperties = {
    headCellsBackground: "#b7dde2",
    headCellsColor: "#53565A",
    overallBackground: "#E3E4E4",
  };
  const navigate = useNavigate();
  const headCells = [
    {
      id: "requestNo",
      label: "Request No.",
      disableSorting: false,
    },
    {
      id: "requestType",
      label: "Request Type",
      disableSorting: false,
    },
    {
      id: "companyName",
      label: "Company Name",
      disableSorting: false,
    },
    {
      id: "requestDate",
      label: "Request Date",
      disableSorting: false,
    },
    {
      id: "requestAssignedTo",
      label: "Request Assigned To",
      disableSorting: true,
    },
    {
      id: "status",
      label: "Status",
      disableSorting: false,
    },
  ];

  function createData(
    requestNo,
    requestType,
    companyName,
    requestDate,
    requestAssignedTo,
    status
  ) {
    return {
      requestNo,
      requestType,
      companyName,
      requestDate,
      requestAssignedTo,
      status,
    };
  }
  const rows = [
    createData(
      "223",
      "Peer Set",
      "Amazon India Ltd.",
      "August 1,2022",
      "Vikas-Aakash,Kuldeep",
      "Executing"
    ),
    createData(
      "441",
      "Onboarding Comp Data",
      "Zomato Ltd.",
      "August 12,2022",
      "Vikas-Aakash,Dilip,Saumya",
      "Executing"
    ),
    createData(
      "223",
      "Peer Set",
      "Amazon India Ltd.",
      "August 1,2022",
      "Vikas-Aakash,Kuldeep",
      "Executing"
    ),
    createData(
      "441",
      "Report Generation",
      "PayTM technologies Pvt Ltd.",
      "August 12,2022",
      "Vikas-Aakash,Dilip,Saumya",
      "Executing"
    ),
    createData(
      "223",
      "Report Generation",
      "Future Group Ltd.",
      "August 1,2022",
      "Vikas-Aakash,Kuldeep",
      "Executing"
    ),
    createData(
      "441",
      "Onboarding Comp Data",
      "TATA Motors Ltd.",
      "August 12,2022",
      "Vikas-Aakash,Dilip,Saumya",
      "Executing"
    ),
    createData(
      "223",
      "Peer Set",
      "Relaince Agro Ltd.",
      "August 1,2022",
      "Vikas-Aakash,Kuldeep",
      "Executing"
    ),
    createData(
      "441",
      "Report Generation",
      "PayTM technologies Pvt Ltd.",
      "August 12,2022",
      "Vikas-Aakash,Dilip,Saumya",
      "Executing"
    ),
    createData(
      "223",
      "Report Generation",
      "TATA Motors Ltd.",
      "August 1,2022",
      "Vikas-Aakash,Kuldeep",
      "Executing"
    ),
    createData(
      "441",
      "Report Generation",
      "Future Group Ltd.",
      "August 12,2022",
      "Vikas-Aakash,Dilip,Saumya",
      "Executing"
    ),
  ];

  return (
    <div className="my-subscription-container">
      <MasContainer>
        <div>
          <h1 class="mas-text-h1">Dashboard</h1>
          {/* <div>
            View API product subscriptions that you own.
          </div> */}
        </div>

        <div className="filter-section">
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={12} md={3}>
              <div className="manager-cnt1">
                <div className="cnt1-name">
                  Report / Compensation Data Requests
                </div>
                <div className="cnt1-num">171</div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <div className="manager-cnt2">
                <div className="cnt2-name">Report In Execution Queue</div>
                <div className="cnt2-num">159</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className="manager-cnt3">
                <div className="cnt3-name"> Report Sent for Approvals</div>
                <div className="cnt3-num">48</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className="manager-cnt4">
                <div className="cnt4-name">Report Delivered</div>
                <div className="cnt4-num">112</div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="filter-section">
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={12} md={6}>
              <div className="manager-hdn">
                <span className="manager-hdn1">
                  Onboarding Data / Report / Peer Set Requests{" "}
                </span>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <div>
                <MasTextField
                  height="0px"
                  placeholder={
                    "Search via Report No. , User Name, Company Name, Contact No."
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <div className="">
                <img src={icon}></img>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="filter-section">
          <Grid container alignItems="flex-end" justifyContent={"left"}>
            <Grid item xs={12} sm={12} md={12}>
              {/* <Table headCells={mySubscriptionsCols} rows={viewSubscriptionData} returnPageNo={handlePageChange} showPagination /> */}
              <Table
                headCells={headCells}
                rows={rows}
                link={true}
                stickyHeader="true"
                colorProperties={colorProperties}
                showAllWhiteShade={false}
              />
            </Grid>
          </Grid>
        </div>
      </MasContainer>
    </div>
  );
};

export default DataTeamDashboard;
