import React, { useState } from "react";
import MarketUpArrow from "../../../assets/images/marketUpArrow.png";
import MarketDownArrow from "../../../assets/images/marketDownArrow.png";
import { Typography } from "@mui/material";
import "./technologyIndex.scss";

const TechnologyIndex = () => {
  const [techIndex, setTechIndex] = useState([
    {
      name: "PHP",
      value: "9.6",
      status: "high",
    },
    {
      name: "Python",
      value: "18.6",
      status: "high",
    },
    {
      name: "Big Data",
      value: "21.2",
      status: "low",
    },
    {
      name: "Angular",
      value: "19.6",
      status: "high",
    },
    {
      name: "DotNet",
      value: "18.6",
      status: "high",
    },
    {
      name: "React",
      value: "21.8",
      status: "high",
    },
  ]);

  return (
    <div className="technology-index">
      <Typography variant="body1" className="font3">
        Technology Index India (Median) :
      </Typography>
      <div className="technology-index-objects">
        {techIndex.map((elem, index) => {
          return (
            <Typography
              key={index}
              variant="body2"
              className="technology-index-objects-values font3"
            >
              {elem.name}
              <span>{elem.value}</span>
              <img
                src={elem.status === "high" ? MarketUpArrow : MarketDownArrow}
              />
            </Typography>
          );
        })}
      </div>
    </div>
  );
};

export default TechnologyIndex;
