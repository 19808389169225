import React, { useEffect, useState } from "react";
import Breadcrum from "../../Breadcrum/breadcrum";
import FirstContent from "../../FirstContent/firstContent";
import MasButton from "../../MasButton/masButton";
import AllReportsTemplate from "../../Reports/AllReportsTemplate/allReportsTemplate";
import "./ReportsView.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../utility/common/api";
const ReportsView = () => {
  const reportsArray = useSelector(
    (state) => state.ReportsReducer.reportsArray
  );
  const params = useParams();
  const navigate = useNavigate();
  const [reqNo, setReqNo] = useState(null);
  const [reqCreated, setReqCreated] = useState(null);
  const ID = sessionStorage.getItem("id");
  const primKey = sessionStorage.getItem("parentID");
  const getCreatedDate = (d) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    setReqCreated(
      monthNames[d.getMonth()] +
        " " +
        d.getDate() +
        ", " +
        d.getFullYear() +
        " @ " +
        d.getHours() +
        ":" +
        d.getMinutes()
    );
  };
  useEffect(() => {
    if (ID) {
      const url = `/api/approvaldisplay/${ID}`;
      api.get({ url }).then(({ data }) => {
          if (data) {
            console.log("Data", data);
            let s = "Request No " + data.approvalRest.id;
            setReqNo(s);
            getCreatedDate(new Date(data.approvalRest.createdTime));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  const onApproveRequest = () => {
    const url = `/api/approval/${ID}`;
    const data = { approvalStatus: "APPROVED" };
    api.patch({ url, data }).then(({ data }) => {
        if (data.approvalStatus == "APPROVED") {
          navigate("/manager-dashboard");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleProceedButtonClick = () => {
    if (reportsArray.length > 0) {
      let d = [];
      reportsArray.forEach((el) => {
        d.push(el.key);
      });
      const url = `/api/peerset/${primKey}`;
      const data = { chartTypes: d };
      api.patch({ url, data }).then(({ data }) => {
          if (data) {
            onApproveRequest()
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Select checkbox and proceed.");
    }
  };
  return (
    <div>
      <div className="manager-report-first-content">
        <FirstContent showBack="true" header={reqNo} />
        <div className="manager-breadcrum-div">
          <Breadcrum />
        </div>
        <div className="manager-report-box">
          <AllReportsTemplate
            showManagerPersona={true}
            header={reqNo}
            createdDate={reqCreated}
          />
          <div className="manager-reports-button-section">
            <button
              id="dashboard"
              onClick={() => {
                navigate("/manager-dashboard");
              }}
            >
              Back to Dashboard
            </button>
            <button
              id="approvedGreen"
              onClick={() => handleProceedButtonClick()}
            >
              Approved
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsView;
