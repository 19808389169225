import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Box,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SortingIcon from "../../assets/icons/sortIcon";
import TableEditIcon from "../../assets/images/TableEditIcon.png";

const MasTableHead = (props) => {
  const {
    mainHeadCell,
    headCells,
    onSelectAllClick,
    order,
    orderBy,
    colorProperties,
    numSelected,
    rowCount,
    onRequestSort,
    StyledTableCell,
    isCheckbox,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const [edit, setEdit] = useState(false);
  const handleEdit = (e) => {
    console.log(e.target.alt);
    setEdit(e.target.alt);
  };

  return (
    <TableHead>
      {mainHeadCell && (
        <TableRow>
          <StyledTableCell
            sx={{
              background: colorProperties.mainHeadCellBackground,
              color: colorProperties.mainHeadCellColor,
            }}
            colSpan={headCells.length}
            align="center"
          >
            {mainHeadCell[0].label}
          </StyledTableCell>
        </TableRow>
      )}
      <TableRow>
        {isCheckbox && (
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </StyledTableCell>
        )}
        {headCells.map((headCell) => (
          <StyledTableCell
            sx={{
              background: colorProperties
                ? colorProperties.headCellsBackground
                : "#FFFFFF",
              color: colorProperties
                ? colorProperties.headCellsColor
                : "#000000",
            }}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            onClick={
              !headCell.disableSorting ? createSortHandler(headCell.id) : null
            }
            colSpan={headCell.colSpan}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
              }}
            >
              {headCell.headLabelCell ? headCell.headLabelCell() : null}
              <TableSortLabel
                active={orderBy === headCell.id}
                // sx={{width:"100%"}}
                direction={orderBy === headCell.id ? order : "asc"}
                // IconComponent={ArrowDropDownIcon}
                // IconComponent={'none'}
              >
                {edit !== headCell.label ? (
                  headCell.label
                ) : (
                  <input type="number" />
                )}
                {headCell.edit && (
                  <img
                    onClick={(e) => handleEdit(e)}
                    alt={headCell.label}
                    style={{ marginLeft: "15px" }}
                    src={TableEditIcon}
                  />
                )}
                {!headCell.disableSorting && (
                  <SortingIcon
                    orderBy={orderBy}
                    order={order}
                    headCell={headCell}
                    createSortHandler={createSortHandler}
                  />
                )}
                {/* {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null} */}
              </TableSortLabel>
            </div>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

MasTableHead.propTypes = {
  StyledTableCell: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  isCheckbox: PropTypes.bool,
};

MasTableHead.defualtProps = {
  isCheckbox: false,
};

export default MasTableHead;
