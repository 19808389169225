import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./ArrowContent.scss";

const getCreatedDate = (d) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    monthNames[d.getMonth()] +
    " " +
    d.getDate() +
    ", " +
    d.getFullYear() +
    " @ " +
    d.getHours() +
    ":" +
    d.getMinutes()
  );
};
function ArrowContent(props) {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "1vw" }}>
      <div>
        <div id="partnerCompBoxHead">{props.reqNumber}</div>
        <div id="partnerCompBoxSub">{props.reqSubHead}</div>
        <div id="partnerCompBoxSub2">
          Recieved on {getCreatedDate(new Date(props.reqPeersetDate))}
        </div>
      </div>
    </div>
  );
}

export default ArrowContent;
