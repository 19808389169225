import React from "react";
import { Typography } from "@mui/material";
import "./compensationUploadBox.scss";
import { useDispatch } from "react-redux";
import { uploadCompensationAction } from "../../../store/actions/global";
import { useNavigate } from "react-router-dom";
import { getTranslation } from "../../../utility/i18n/i18n";

const CompensationUploadBox = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="content-upload">
      <Typography variant="subtitle3" className="content-upload-header font13">
        Upload Firms Compensation Details
      </Typography>
      <Typography variant="body2" className="content-upload-desc font3">
      {getTranslation("dashboard")["description1"]}
      </Typography>
      <div className="content-upload-button">
        <Typography
          variant="body2"
          onClick={() => dispatch(uploadCompensationAction({ open: true }))}
          className="green-button-font"
        >
          Upload Now
        </Typography>
        <Typography
          variant="body2"
          className="green-button-font"
          onClick={() => {
            navigate("/compDataUpload1");
          }}
        >
          Previous Action
        </Typography>
      </div>
    </div>
  );
};

export default CompensationUploadBox;
