import React from "react";
import "./CommRightContent.scss";
import commFilterIcon from "./images/commFilterIcon.svg";
import commStar from "./images/commStar.svg";
import commCopyIcon from "./images/commCopyIcon.svg";
import commDelIcon from "./images/commDelIcon.svg";
import commEditIcon from "./images/commEditIcon.svg";
import commPinIcon from "./images/commPinIcon.svg";
import commAnsIcon from "./images/commAnsIcon.svg";
import commReplyIcon from "./images/commReplyIcon.svg";
import commViewIcon from "./images/commViewIcon.svg";
import commPP1 from "./images/commPP1.svg";
import commPP2 from "./images/commPP2.svg";
import commPP3 from "./images/commPP3.svg";
import { useNavigate } from "react-router-dom";

function CommRightContent(props) {
  const navigate = useNavigate();
  const navigateToComm3 = () => {
    navigate("/comm3");
  };
  const commPosts = (type, heading, pp, link1, link2) => {
    return (
      <div id="commPost">
        <div className="commFlexRow">
          <img src={commStar} id="commStar" />
          <div id="commPostType">Type: {type} | 7th Aug 2022</div>
          <img src={commPinIcon} id="commPin" />
          <img src={commCopyIcon} id="commCopy" />
          <img src={commEditIcon} id="commEdit" />
          <img src={commDelIcon} id="commDel" />
        </div>
        <div id="commPostHead">{heading}</div>
        <div className="commFlexRow">
          <img src={commAnsIcon} id="commAns" />
          <div id="commAnsTxt">2 Answers</div>
          <img src={commReplyIcon} id="commReply" />
          <div id="commReplyTxt">Reply</div>
          <img src={commViewIcon} id="commView" />
          <div id="commViewTxt">44 Viewers</div>
        </div>
        <hr id="commmHr" />
        <div className="commFlexRow">
          <img src={pp} id="commPP" />
          <div className="commFlexCol">
            <div className="commFlexRow">
              <div id="commPPName">Corcodel, Razvan | Analyst at XYZ</div>
              <div id="commJustNow">Just Now</div>
            </div>
            <div id="commPPLink">
              {link1}
              <a id="commLink">{link2}</a>
            </div>
          </div>
        </div>
        <hr id="commmHr" />
      </div>
    );
  };
  return (
    <div id="commRightBox">
      {props.showCommSearch ? (
        <>
          <div className="commFlexRow">
            <form>
              <input
                placeholder="Search your query here..."
                id="commSearchBar"
              />
            </form>
            <button id="commPostQuery" onClick={navigateToComm3}>
              Post a query
            </button>
          </div>
        </>
      ) : null}
      {props.showMyActSearch ? (
        <>
          <div className="commFlexRow">
            <form>
              <input
                placeholder="Search your query here..."
                id="commMyActSearchBar"
              />
            </form>
            <img src={commFilterIcon} id="commFilter" />
            <button id="commMyActPostQuery" onClick={navigateToComm3}>
              Post a query
            </button>
          </div>
        </>
      ) : null}

      <div>
        {commPosts(
          "Accounts & Finance",
          "What will be the best practises of aligning your accounts and finance team in this agile work environment, also we are going hybrid, please suggest.",
          commPP1,
          "You can follow some basic principles in this link ",
          "www.agileworkforce.com/principles"
        )}
      </div>
      <div>
        {commPosts(
          "Marketing",
          "We want to expand our hybrid structured marketing team, as most of them are on field and some are situated overseas, can anyone suggest some changes for flexibility and scalability.",
          commPP2,
          "You can follow some basic principles in this link",
          "www.marketinggenius.com/principles_for_scale"
        )}
      </div>
      <div>
        {commPosts(
          "Human Resource",
          "Can someone help our company in finding quality resource for our HR team, mostly tech based. Do suggest some good consultation agencies.",
          commPP3,
          "I have few in my network, XYZ corp is working fine for us.",
          ""
        )}
      </div>
      <div className="commFlexRow">
        <button id="commPrev">Previous</button>
        <div id="commPg1" className="commPgs">
          1
        </div>
        <div className="commPgs">2</div>
        <div className="commPgs">3</div>
        <div className="commPgs">4</div>
        <div className="commPgs">5</div>
        <div className="commPgs">...</div>
        <div className="commPgs">999</div>
        <button id="commNxt">Next</button>
      </div>
    </div>
  );
}

export default CommRightContent;
