// actionTypes
export const SET_LOADING = "SET_LOADING";
export const SET_REPORT_NUMBERS = "SET_REPORT_NUMBERS";
export const UPLOAD_COMPENSATION_ACTION = "UPLOAD_COMPENSATION_ACTION";
export const SHOW_LOADER = "SHOW_LOADER";

// actions - dispatch
export const setLoading = (isLoading, id = "global") => {
  return {
    type: SET_LOADING,
    payload: {
      id,
      isLoading,
    },
  };
};

export const setReportsNumber = (payload) => {
  // console.log(payload)
  return {
    type: SET_REPORT_NUMBERS,
    payload: payload,
  };
};

export const uploadCompensationAction = (payload) => {
  // console.log(payload)
  return {
    type: UPLOAD_COMPENSATION_ACTION,
    payload: payload,
  };
};

export const showLoader = (payload) => {
  return {
    type: SHOW_LOADER,
    payload: payload
  }
};

