import React, { useEffect, useState } from "react";
import "./compensation.scss";
import pdfIcon from "./Images/pdf.svg";
import dw1 from "./Images/dw1.svg";
import dw2 from "./Images/dw2.svg";
import BigCirc from "./Images/bigCirc.svg";
import SmallCirc from "./Images/smallCirc.svg";
import VertLine from "./Images/vertLine.svg";
import reviewIcon from "./Images/review.svg";
import CompensationHead from "./CompensationHead";
import api from "../../utility/common/api";

const Compensation = () => {
  const [history, setHistory] = useState(null);
  useEffect(() => {
    const url = `/api/compscale/history`;
    api
      .get({ url })
      .then(({ data }) => {
        console.log(data);
        if (data) {
          setHistory(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const getCreatedDate = (d) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return (
      monthNames[d.getMonth()] +
      " " +
      d.getDate() +
      ", " +
      d.getFullYear() +
      " @ " +
      d.getHours() +
      ":" +
      d.getMinutes()
    );
  };

  const downloadData = (value) => {
    const url = `/api/file/${value.compscaleRest.refName}?storgelocation=CONSUMED`;
    api
      .get({ url })
      .then(({ data }) => {
        if (data) {
          const temp = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = temp;
          link.setAttribute("download", value.compscaleRest.refName.split(/_(.*)/s)[1]);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const empName = (names, d) => {
    return (
      <div className="comp-emp">
        {names}
        <br></br>
        <div className="comp-time">{getCreatedDate(new Date(d))}</div>
      </div>
    );
  };

  return (
    <div>
      {history && (
        <>
          <CompensationHead />
          {history.map((el) => (
            <div className="flexRow" key={"history" + el.approvalRest.id}>
              <div id="comp-box" key={el.approvalRest.id}>
                <div className="flexRow" key={"flexRow" + el.approvalRest.id}>
                  <div className="flexCol" key={"flexCol" + el.approvalRest.id}>
                    <img src={BigCirc} id="compBigCirc1" />
                    <img src={SmallCirc} id="compSmallCirc" />
                    <img src={VertLine} id="compVertLine" />
                    <img src={BigCirc} id="compBigCirc2" />
                    <img src={SmallCirc} id="compSmallCirc" />
                  </div>
                  <div key={"key1" + el.approvalRest.id}>
                    <div id="compEmp1" key={"compEmp1" + el.approvalRest.id}>
                      {empName(el.currentUser, el.compscaleRest.createdTime)}
                    </div>
                    <div id="compEmps" key={"compEmps" + el.approvalRest.id}>
                      {empName(
                        el.approvalRest.assignedNames[0],
                        el.approvalRest.updatedTime
                      )}
                    </div>
                  </div>
                  <div key={"key2" + el.id}>
                    <div
                      id="comp-pdfDownload"
                      key={"comp-pdfDownload" + el.approvalRest.id}
                    >
                      <div
                        className="flexRow"
                        key={"compEmp-pdf" + el.approvalRest.id}
                      >
                        <img id="comp-pdfIcon" src={pdfIcon} />
                        <span id="comp-pdfName">
                          {el.compscaleRest.refName.split(/_(.*)/s)[1]}
                        </span>
                        <div
                          key={"compEmpDow" + el.approvalRest.id}
                          className="flexCol"
                          onClick={() => downloadData(el)}
                        >
                          <img id="comp-dw1" src={dw1} />
                          <img id="comp-dw2" src={dw2} />
                        </div>
                      </div>
                    </div>
                    <div
                      id="comp-review"
                      className="flexRow"
                      key={"comp-review" + el.approvalRest.id}
                    >
                      <img id="comp-reviewIcon" src={reviewIcon} />
                      <div
                        id="comp-reviewText"
                        key={"comp-reviewText" + el.approvalRest.id}
                      >
                        {el.approvalRest.approvalStatus}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Compensation;
