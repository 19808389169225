import React from "react";
import "./ReportGenReq2.scss";
import FirstContent from "../PartnerManager/PartnerManagerFirstContent/FirstContent";

function ReportGenReq2() {
  return (
    <div>
      <FirstContent
        showBlackHeadArrow={true}
        showNav3={true}
        showNav4={true}
        heading={"Request No. 223"}
        nav1={"Dashboard"}
        nav2={"Request No. 223"}
        nav3={"Report Generation"}
        nav4={"Report Preview"}
        nav1col={"navGreen"}
        nav2col={"navGreen"}
        nav3col={"navGreen"}
        nav4col={"navBlack"}
      />
      <div id="repSent">
        Report Has Been Sucessfully Sent To Client For Approvals
      </div>
      <button id="retHome">Return Home</button>
    </div>
  );
}

export default ReportGenReq2;
