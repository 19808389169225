import React, { Component } from "react";
import "./compensation.scss";
import pdfIcon from "./Images/pdf.svg";
import dw1 from "./Images/dw1.svg";
import dw2 from "./Images/dw2.svg";
import BigCirc from "./Images/bigCirc.svg";
import SmallCirc from "./Images/smallCirc.svg";
import VertLine from "./Images/vertLine.svg";
import tick from "./Images/tick.svg";
import CompensationHead from "./CompensationHead";
import CompensationRightCont from "./CompensationRightCont";
import CompensationThreeBtns from "./CompensationThreeBtns";

const Compensation2 = () => {
  const desc =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam";

  const pdf = (
    <div className="flexRow">
      <img id="comp-pdfIcon" src={pdfIcon} />
      <span id="comp-pdfName">Firm Compensation_files...xls</span>
      <div className="flexCol">
        <img id="comp-dw1" src={dw1} />
        <img id="comp-dw2" src={dw2} />
      </div>
    </div>
  );

  const empName = (names) => {
    return (
      <div className="comp-emp">
        {names}
        <br></br>
        11 Aug'22 @ <p className="time">14:45AM</p>
      </div>
    );
  };

  return (
    <div>
      <CompensationHead />
      <div className="flexRow">
        <div id="comp-box">
          <div id="comp2-update">
            It's been 6 months since last file was uploaded
          </div>
          <div className="flexRow" style={{ "margin-top": "23px" }}>
            <div className="flexCol">
              <img src={BigCirc} id="compBigCirc1" />
              <img src={SmallCirc} id="compSmallCirc" />
              <img src={VertLine} id="compVertLine" />
              <img src={BigCirc} id="compBigCirc2" />
              <img src={SmallCirc} id="compSmallCirc" />
            </div>
            <div>
              <div id="compEmp1">{empName("You")}</div>
              <div id="compEmps">{empName("Sunita Marwah")}</div>
            </div>
            <div>
              <div id="comp-pdfDownload">{pdf}</div>
              <div id="comp-approval">
                <img id="comp-tick" src={tick} />
                <span id="comp-approved">Approved</span>
              </div>
            </div>
          </div>

          <CompensationThreeBtns />
        </div>
        <CompensationRightCont />
      </div>
    </div>
  );
};

export default Compensation2;
