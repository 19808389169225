import { Typography } from "@mui/material";
import React from "react";
import "./OnboardFormSteps.scss";

const OnboardFormSteps = (props) => {
  let step = [
    {
      stepNum: "01",
      header: "Basic Details",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam",
    },
    {
      stepNum: "02",
      header: "Upload Compensation",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam",
    },
  ];
  return (
    <div>
      {step.map((elem) => {
        return (
          <div
            className="onboard-steps-section"
            style={
              props.green === elem.stepNum
                ? {}
                : { borderLeft: "5px solid #D0D0CE" }
            }
          >
            <div className="onboard-steps-section-header">
              <span
                className="onboard-steps-section-count"
                style={props.green === elem.stepNum ? {} : { color: "#53565A" }}
              >
                {elem.stepNum}
              </span>
              <Typography variant="body2" className="onboard-steps-section-msg">
                {elem.header}
              </Typography>
            </div>
            <Typography variant="body2" className="onboard-steps-section-desc">
              {elem.desc}
            </Typography>
          </div>
        );
      })}

      {/* <div className='onboard-steps-section' style={{top:"327px", borderLeft: "5px solid #D0D0CE"}}>
            <div className='onboard-steps-section-header'>
                <span className='onboard-steps-section-count' style={{color:"#53565A"}}>02</span>
                <Typography variant="body2" className='onboard-steps-section-msg'>Upload Compensation</Typography>
            </div>
            <Typography variant="body2" className='onboard-steps-section-desc'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam
            </Typography>
        </div> */}
    </div>
  );
};

export default OnboardFormSteps;
