import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import DropdownIcon from "../../assets/icons/dropdownIcon";
import { styled } from "@mui/material/styles";
import { menuItemClasses } from "@mui/material/MenuItem";
import "./styles.scss";

const MasSelect = ({
  label,
  selected,
  data,
  onChange,
  minWidth,
  styleContainer,
  styleSelect,
  isRequiredLabel,
  subLabel,
  isWidth,
  width,
  name,
  IsMaxWidth,
  maxWidth,
  disabled,
  errorMessage,
  defaultValue,
}) => {
  const menuItems = data;
  const MasMenuItem = styled(MenuItem)(({ theme }) => ({
    [`&.${menuItemClasses.root}:hover, &.${menuItemClasses.selected}`]: {
      background: "#ebe8e4 ",
    },
  }));

  function renderValue(option) {
    if (option == null) {
      return;
    }
    let selectedOption = {};
    for (let obj of menuItems) {
      if (obj.id === option) {
        selectedOption = obj;
      }
    }
    return <span>{selectedOption.value}</span>;
  }

  return (
    <div className={styleContainer}>
      {label && !isRequiredLabel && (
        <div className="mas-select-label">{label}</div>
      )}
      {label && isRequiredLabel && (
        <span className="mas-select-label">
          {label}
          <span className="mas-required">*</span>
        </span>
      )}
      {subLabel && <div className="mas-select-sub-label">{subLabel}</div>}
      <FormControl
        sx={{ m: 1, minWidth: minWidth, width: "100%", margin: "0" }}
      >
        <Select
          labelId={"mas-select" + label}
          id={"mas-select" + label}
          value={selected}
          renderValue={renderValue}
          onChange={onChange}
          className={styleSelect}
          size={"small"}
          sx={
            isWidth
              ? {
                  width: width,
                  background: "#FFF",
                  maxWidth: IsMaxWidth ? maxWidth : width,
                }
              : { background: "#FFF" }
          }
          name={name}
          IconComponent={DropdownIcon}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {menuItems &&
            menuItems.map((dt, i) => (
              <MasMenuItem key={dt.id + i} value={dt.id}>
                <div style={{ maxWidth: width }}>
                  {dt.value}
                  {dt?.discription && (
                    <span className="mas-select-discription-content-break ">
                      {dt?.discription}
                    </span>
                  )}
                </div>
              </MasMenuItem>
            ))}
        </Select>
      </FormControl>
      {errorMessage && <span className="error">{errorMessage}</span>}
    </div>
  );
};

MasSelect.defaultProps = {
  styleContainer: "mas-select",
  styleSelect: "",
  isRequiredLabel: false,
  subLabel: "",
  isWidth: false,
  width: "auto",
  name: "",
  maxWidth: "auto",
  disabled: false,
};

export default MasSelect;
