import { Button } from "@mui/material";
import "./button.scss";

const MasButton = ({
  label,
  size,
  variant,
  color,
  startIcon,
  onClick,
  disabled,
  customcss,
  margin,
  additionalSxProperties,
}) => {
  return (
    <Button
      variant={variant}
      color={color}
      onClick={onClick}
      className={customcss}
      size={size}
      startIcon={startIcon}
      sx={{
        fontFamily: "SourceSansProSemiBold",
        textTransform: "none",
        margin: margin,
        padding: "8px 17px",
        boxShadow: "none",
        borderRadius: "2px",
        border: "1px solid #705A3A",
        ...additionalSxProperties,
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

MasButton.defaultProps = {
  label: "",
  size: "medium",
  variant: "contained",
  color: "secondary",
  margin: "0 6px",
  additionalSxProperties: {},
};

export default MasButton;
