import { Typography } from "@mui/material";
import React, { useState } from "react";
import "./firstContent.scss";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const FirstContent = (props) => {
  return (
    <div>
      <div className="displayHead">
        {props.showBack && <KeyboardArrowLeftIcon sx={{ color: "#26890D" }} />}
        <Typography variant="subtitle1" className="header-font">
          {props.header}
        </Typography>
      </div>
      {props.description && (
        <Typography variant="body2" className="first-content-desc font3">
          {props.description}
        </Typography>
      )}
    </div>
  );
};

export default FirstContent;
