import React from "react";
import FormUpload from "./FormUpload/FormUpload";
import './Final.scss';
const Final = () => {
  return (
    <>
      <div className="main_container">
          <div className="font2">Upload Company Data</div>
          <div className="main_description font3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
              aliquam, purus sit amet luctus venenatis, lectus magna fringilla
              urna, porttitor rhoncus dolor purus non enim praesent elementum
              facilisis leo, vel fringilla est ullamcorper.
          </div>
          <FormUpload />
      </div>
    </>
  );
};

export default Final;
