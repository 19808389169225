import thunk from "redux-thunk";
import { combineReducers, createStore, applyMiddleware } from "redux";

// import reducers
// import global from './reducers/global';
import ReportsReducer from "./reducers/reportsReducer";
import UploadCompensationReducer from "./reducers/uploadCompensationReducer";

const rootReducer = combineReducers({
  ReportsReducer,
  UploadCompensationReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
