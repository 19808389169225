import { useState,useEffect } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import './FormUpload.scss';

import api from '../../../../utility/common/api';
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import MasTable2 from "../../../../domain-component/MasTable/Table2";



function FormUpload_new() {
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([
    {
      compName: "",
      
      fileUploaded: null,
      
    },
  ]);
  
  
  // handle input change
  const handleInputChange = (element, index) => {
    const { name, value, files } = element.target;
    const newInputs = [...dataList];
    if (name === "fileUploaded") {
      const url = "/api/file";
      let data = new FormData();
      data.append("file",files[0]);
      api.post({ url, data }).then(({ data }) => {
          if (data) {
            newInputs[index]["refName"]=data.refName;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      newInputs[index][name] = files[0];
    }else if(name==="theDate"){
      newInputs[index][name] = value;
    }
     else {
      newInputs[index][name] = value;
    }
    setDataList(newInputs);
  };

  // handle click event of the Add button
  const handleAddInput = () => {
    setDataList([
      ...dataList,
      {
        compName: "",
        
        fileUploaded: "",
      },
    ]);
  };

  // handle click event of the Remove button
  
  const handleSubmit = async(event) => {
    event.preventDefault();
  let data = {};
  const url = "/api/autocalculate";

  dataList.forEach((el) => {
    // Assuming el["refName"] and "constant" are dynamic values, you can modify accordingly
    data = {
      fileName: el["refName"],
      peersetName: el["compName"],
    };
  });

  try {
    // First API call
    const response1 = await api.post({ url, data });

    if (response1.data) {
      // First API call successful, now make the second API call
      const url2 = "/api/autocalculate/search?size=10&offSet=0";
      const body = { type: "batch" };

      const response2 = await api.post({ url: url2, data: body });
      console.log("response--->",response2)
      // Assuming the response from the second API call contains an 'isCompleted' flag
      const isCompleted = response2.data.completed;

      // Now you can use the 'isCompleted' flag as needed
      console.log("isCompleted:", isCompleted);

      // If needed, navigate to "/multiFileUploadreview"
      navigate("/multiFileUploadreview");
    }
  } catch (error) {
    console.error(error);
    // Handle errors as needed
  }
  };
  
  

  const canAddInput = dataList.every((input) =>
    Object.values(input).every((value) => value !== "")
  );
  const [rows,setRows] = useState([]);
  const [dataCount,setDataCount]=useState(0);
  const colorProperties = {
      headCellsBackground:"#b7dde2",
      headCellsColor:"#53565A",
      overallBackground: "#E3E4E4",
      
  }
  
  const createData = (companyName, completed,filename) => {
    return { companyName, completed, filename };
  };

    const [options, setOptions] = useState({
      companyName:  null,
      
    });
    const getOptionData=()=>{
      let data;
      if(Object.values(options).filter(el=> el!=null).length==1){
        Object.keys(options).forEach((el)=>{
          if(options[el]!=null){
            data={
              "type": "relational",
              "paramName": el,
              "value": options[el],
              "operator": "Equal"
              }
          }
        })
      }
      else{
        let qualSet=[];
        Object.keys(options).forEach((el)=>{
         
          if(options[el]!=null){
            let d={
              "type": "relational",
              "paramName": el,
              "value": options[el],
              "operator": "Equal"
              }
            qualSet.push(d)
          }
          
        })
        data ={
          "type": "batch",
          "qualSet": qualSet,
          "binaryOperator": "And"
          }
      };
      return data;
    }
  const handlePageChange = (p) => {
    let data;
    if(Object.values(options).filter(el=> el!=null).length!=0){
      data = getOptionData()
    }
    

    const url = `/api/bulkupload/search?size=10&offSet=${p - 1}`;
    api.post({ url,data})
      .then(({ data }) => {
        if (data) {
          var dataApprovals=[];
            
          if(data.data.length>0){
              data.data.forEach(el=>{
                  dataApprovals.push(
                  createData(el.companyName, el.refname)
                  )
              })
          }
          setRows(dataApprovals);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await api.post({
          url: 'http://localhost:3000/api/autocalculate/search?size=10&offSet=0',
          data: { type: 'batch' },
        });

        const responseData = response.data;
        console.log(responseData)
        if (responseData) {
          setDataCount(responseData.total);

          const dataApprovals = responseData.map((el) => createData(el.peersetName, el.completed,el.fileName));

          setRows(dataApprovals);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();  
},[]);
  const canRemoveInput = dataList.length > 1;
  const headCells = [
    {
      id: 'companyName',
      label: 'Company Name',
      disableSorting:true,
 

    },
    
    {
      id: 'file',
      label: 'Download File',
      disableSorting:true

    }


  ];

  return (
    <div className="main_form">
      <form className="form-style" onSubmit={handleSubmit}>
        {dataList.map((input, idx) => {
          return (
            <div key={idx} className="sub_form">
              <div>
                <label for="compName">Company Name: </label> <br />
                <input
                  type="text"
                  maxLength={150}
                  name="compName"
                  placeholder="Tesla"
                  value={input.compName}
                  onChange={(input) => handleInputChange(input, idx)}
                  required
                />{" "}
                <br />
              </div>
              
              <div className="control_btn">
                <div className="uploadFile">
                  <label className="upload_btn">
                    Upload
                    <input
                      type="file"
                      id={`fileUploaded-${idx}`}
                      name="fileUploaded"
                      accept=".csv"
                      style={{ display: "none" }}
                      required
                      onChange={(input) => handleInputChange(input, idx)}
                    />
                  </label>
                  {/*Display the name of the uploaded file*/}
                  <span className="file_name">
                    {input.fileUploaded
                      ? input.fileUploaded.name
                      : "No file chosen"}
                  </span>
                </div>
                
                  <div>
                    {canAddInput && idx === dataList.length - 1 && (
                      <AddCircleIcon
                        onClick={handleAddInput}
                        style={{
                          fontSize: "2rem",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            
          );
        })}
        <button className="submit_btn">Submit</button>
      </form>
     <div style={{marginTop:"10px"}}>
      <Grid
              container
              alignItems="flex-end"
              justifyContent={"left"}
            >
              <Grid item xs={12} sm={12} md={12}>
                <MasTable2
                headCells={headCells}
                rows={rows}
                link={true}
                rowCount={dataCount}
                stickyHeader="true"
                colorProperties={colorProperties} 
                showAllWhiteShade={false}
                isManagerDashBoard={true}
                returnPageNo={handlePageChange}
                showPagination={true}
                />
              </Grid>
            </Grid>
            </div>
    </div>
  );
}

export default FormUpload_new;
