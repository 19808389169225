import { Component,useState,useEffect } from "react";
import "./loginExt.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import login from "../../assets/images/Login.svg";
import line from "../../assets/images/Line.svg";
import info from "../../assets/images/info.svg";
import Link from "@mui/material/Link";
import MasTextField from "../../domain-component/MasTextField";
import backarrow from "../../assets/images/BackArrow.svg";
import welcomeImage from "../../../src/assets/images/Welcome2.svg";
import { getTranslation } from "../../utility/i18n/i18n";
import successLogo from "../../assets/images/success-logo.svg";
const RequestLoginAcknowledge = () => {
  const navigate = useNavigate();
  const [messageIndex, setMessageIndex] = useState(0);
  useEffect(() => {
    const startInterval = setInterval(() => {
        setMessageIndex((t) => {
          if(t>=2){
            return 0
          }
          else{
            return t+1
          }
        });
    }, 5000);
    return () => {
      clearInterval(startInterval);
    };
  }, []);
  const handleLoginAcknowledge = () => {
    navigate("/login-external");
  };
  return (
    <div className="container">
      <div className="login-about-container">
        <div className="font1"> Welcome to CompScale !!</div>
        <div className="login-ext-image">
          <img src={welcomeImage} width="100%" height="100%"></img>
        </div>
        <div className="font3 ">
          {getTranslation("login-external")["description"][messageIndex]}
        </div>
        <div className="login-abt-sl">
          <span className={(messageIndex == 0 ? "login-abt-sl1" : "login-abt-sl2")} onClick={()=>{setMessageIndex(0)}}></span>
          <span className={(messageIndex == 1 ? "login-abt-sl1" : "login-abt-sl2")} onClick={()=>{setMessageIndex(1)}}></span>
          <span className={(messageIndex == 2 ? "login-abt-sl1" : "login-abt-sl2")} onClick={()=>{setMessageIndex(2)}}></span>
        </div>
      </div>
      <div className="login-input-container">
        <div className="login-input">
          <img
            className="login-ext-arrow"
            onClick={handleLoginAcknowledge}
            src={backarrow}
          ></img>
          <div className="font2">Request Early Access</div>
          <img src={successLogo} className="success-logo"/>
          <div className="login-reqack-text1 font3">
            We have sent your request to create new account, you will get a
            confirmation mail on your registered email id within 1-2 working
            days for the same. aaa@xyz.com.
          </div>
          <div className="login-ext-external">
            <Button
              variant="primary"
              size="large"
              sx={{
                textTransform: "none",
                color: "white",
                width: "25vw",
                height: "3vw",
                fontWeight: "400",
                fontSize: "1.09vw",
                lineHeight: "2.7vh",
              }}
              onClick={handleLoginAcknowledge}
            >
              Submit
            </Button>
          </div>

          <div className="reqack-footer">
            <img className="login-ext-footer-img" src={info}></img>
            <span className="footer-font">
              Trouble loggin in? Please contact our admin on
              <span> </span>
              <Link sx={{ color: "#0076A8", cursor: "pointer" }}>
                admin@compscale.com
              </Link>
              to help you.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestLoginAcknowledge;
