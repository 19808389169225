import MainFont from "./SourceSansPro-Regular.ttf";
import BoldFont from "./SourceSansPro-Bold.ttf";
import SemiBoldFont from "./SourceSansPro-SemiBold.ttf";
import LightFont from "./SourceSansPro-SemiBold.ttf";
import ItalicFont from "./SourceSansPro-Italic.ttf";

export const fontFamily = {
  main: {
    name: "SourceSansPro-Regular",
    url: MainFont,
  },
  bold: {
    name: "SourceSansPro-Bold",
    url: BoldFont,
  },
  semibold: {
    name: "SourceSansPro-SemiBold",
    url: SemiBoldFont,
  },
  light: {
    name: "SourceSansPro-Light",
    url: LightFont,
  },
  italic: {
    name: "SourceSansPro-Italic",
    url: ItalicFont,
  },
  fallback: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
};

export const fontSize = {
  main: 16,
  xl: 18,
  lg: 16,
  md: 14,
  sm: 12,
  xs: 10,
};

const typography = {
  fontFamily,
  fontSize,
  lineHeight: 1.25,
  letterSpacing: 0.2,
};

export default typography;
