import React, { useState, useEffect } from "react";
import "./reports.scss";
import info from "./images/info.svg";
import extLink from "./images/extLink.svg";
import repVertLine from "./images/repVertLine.svg";
import repDW from "./images/repDW.svg";
import repRightArrow from "./images/rightArrow.svg";
import FirstContent from "../PartnerManager/PartnerManagerFirstContent/FirstContent";
import { useNavigate } from "react-router-dom";
import api from "../../utility/common/api";
import { getTranslation } from "../../utility/i18n/i18n";


function Reports1() {
  const navigate = useNavigate();
  const navigateToRep3 = (d) => {
    navigate(`/reports3/${d}`);
  };
  const [peerGroupData, setPeerGroupData] = useState([]);
  useEffect(() => {
    const url = "/api/peerset/bycurrentUser?isForReport=true";
    api
      .get({ url })
      .then(({ data }) => {
        if (data && data.length > 0) {
          setPeerGroupData(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const rep = (el) => {
    return (
      <div className="repFlexRow">
        <div>
          <img src={repVertLine} id="repVertLine" />
        </div>
        <div >
          <div >
            <div id="repName" className="font8">
              {el.peerSetGroupName}
            </div>
          </div>
          <div id="repAnc" className="font7">
            Anchor For Review:{" "}
            <div id="repAncInner" className="font7">
              Total fixed Pay
            </div>
          </div>
          <div id="repPeer" className="font7">
            Peer Set:{" "}
            <div id="repPeerInner" className="font7">
              {el.peersetComapnyRest.map((e,i) => (
                <>
                  {i<5 &&
                    <span key={e.comapanyName}> {e.comapanyName}, </span>
                  }
                </>
              ))}
            </div>
            <div id="rep6more">More{"  "}</div>
            <img src={repRightArrow} id="repRightArrow" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="first-content-style">
      <FirstContent
        showBack={true}
        heading={"Reports"}
        nav1={"Home"}
        nav2={"Reports"}
        nav1col={"navGreen"}
        nav2col={"navBlack"}
      />
      <div id="repDesc" className="font8">
      {getTranslation("reports1")["description"]}
      </div>
      <div className="repFlexRow" id="repCont">
        {peerGroupData.map(
          (elem, k) =>
            elem.approvalRest.approvalStatus == "APPROVED" && (
              <div
                onClick={() => {
                  navigateToRep3(elem.approvalRest.id);
                }}
              >
                {rep(elem)}
              </div>
            )
        )}
      </div>
    </div>
  );
}

export default Reports1;
