import React, { useEffect, useState } from "react";
import "./OnboardFormArea.scss";
import Typography from "@mui/material/Typography";
import Cross from "../../../assets/images/crossIconDash.png";
import { Link } from "react-router-dom";

const OnboardFormArea = () => {
  const [companyLocation, setCompanyLocation] = useState([]);
  const [companyBusinessDomain, setCompanyBusinessDomain] = useState([]);

  const companyhandler = (e, comp, cancelCheck = false) => {
    let value = cancelCheck ? e : e.target.value;
    let arr = comp === "location" ? companyLocation : companyBusinessDomain;
    let index =
      comp === "location"
        ? companyLocation.indexOf(value)
        : companyBusinessDomain.indexOf(value);
    if (index === -1) {
      arr.push(value); //
    } else {
      arr.splice(index, 1);
    }
    comp === "location"
      ? setCompanyLocation([...arr])
      : setCompanyBusinessDomain([...arr]);
    console.log(companyLocation, arr);
  };

  const onSubmit = () => {};

  return (
    <div className="Onboard-form">
      <Typography variant="subtitle3">Step 1</Typography>
      <div className="Onboard-form-setup">
        <div>
          <div className="onboard-form-label">
            <Typography variant="caption2">Company Registered Name</Typography>
          </div>
          <select name="crn" id="crn" className="onboard-form-select">
            <option value="India">India</option>
            <option value="United States">United States</option>
            <option value="United Kingdom">United Kingdom</option>
          </select>
        </div>
        <div>
          <div className="onboard-form-label">
            <Typography variant="caption2">
              Current Organization Designation
            </Typography>
          </div>
          <select name="crn" id="crn" className="onboard-form-select">
            <option value="HR - Operations">HR - Operations</option>
            <option value="Manager">Manager</option>
            <option value="Software Engineer">Software Engineer</option>
          </select>
        </div>
      </div>
      <div className="onboard-form-label">
        <Typography variant="caption2">Company’s Locations</Typography>
      </div>
      <select
        name="crn"
        id="crn"
        onChange={(e) => companyhandler(e, "location")}
        className="onboard-form-select"
        style={{ top: "159px" }}
      >
        <option value="India-Mumbai">India-Mumbai</option>
        <option value="India-Bangalore">India-Bangalore</option>
        <option value="India-Gurgaon">India-Gurgaon</option>
      </select>

      <div
        className={companyLocation.length > 0 ? "companyLocation-filters" : ""}
      >
        {companyLocation.length > 0
          ? companyLocation.map((elem) => {
              return (
                <div className="companyLocation-filters-sub">
                  {elem}
                  <img
                    className="companyLocation-filters-sub-cross"
                    onClick={() => companyhandler(elem, "location", true)}
                    src={Cross}
                  />
                </div>
              );
            })
          : null}
      </div>
      <div className="onboard-form-label">
        <Typography variant="caption2">Company’s Business Domains</Typography>
      </div>
      <select
        name="crn"
        id="crn"
        onChange={(e) => companyhandler(e, "BusinessDomains")}
        className="onboard-form-select"
      >
        <option value="Banking and Finance">Banking and Finance</option>
        <option value="Finance-Audit">Finance-Audit</option>
        <option value="Consulting">Consulting</option>
      </select>

      <div
        className={
          companyBusinessDomain.length > 0 ? "companyLocation-filters" : ""
        }
      >
        {companyBusinessDomain.length > 0
          ? companyBusinessDomain.map((elem) => {
              return (
                <div className="companyLocation-filters-sub">
                  {elem}
                  <img
                    className="companyLocation-filters-sub-cross"
                    onClick={() =>
                      companyhandler(elem, "BusinessDomains", true)
                    }
                    src={Cross}
                  />
                </div>
              );
            })
          : null}
      </div>
      <Link to="/dashboard">
        <button
          type="Submit"
          name="Submit"
          className="onboard-form-button"
          onClick={onSubmit}
        >
          Submit
        </button>
      </Link>
    </div>
  );
};

export default OnboardFormArea;
