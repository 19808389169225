import { Dialog, DialogContent, DialogTitle, SvgIcon } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { margin } from "@mui/system";
import MasSpinner from "../MasSpinner";
import "./styles.scss";

export default (props) => {
  const {
    maxWidth = "32vw",
    open = false,
    scroll = "paper",
    ariaLabelledBy = "dialog",
    children = <div></div>,
    fullwidth = false,
    disableEscapeKeyDown = true,
    title,
    onClose,
    customizeClass,
    loadingState,
    isLoading = false,
    iconColor,
    paperProps,
    ...additionalDialogProps
  } = props;

  return (
    <Dialog
      {...additionalDialogProps}
      aria-labelledby={ariaLabelledBy}
      fullwidth={fullwidth}
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth={maxWidth}
      open={open}
      children={children}
      scroll={scroll}
      PaperProps={paperProps}
    >
      <DialogTitle>
        <p className="dialog-title font2">{title}</p>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => (iconColor ? iconColor : theme.palette.grey[500]),
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {isLoading ? (
        <div style={{ margin: "auto" }}>
          <MasSpinner />
        </div>
      ) : (
        <DialogContent>
          <div
            className={`${customizeClass ? "custom-css" : ""} ${
              loadingState ? "min-height" : ""
            }`}
          >
            {children}
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};
{
  /* <SvgIcon aria-label="close"
          onClick={onClose}
          sx={{position: "absolute",right: 8,top: 12,color: (theme) => theme.palette.grey[500],}}>
        <path xmlns="http://www.w3.org/2000/svg" d="M10.7242 9.13262C10.689 9.09745 10.6691 9.04969 10.6691 8.99988C10.6691 8.95007 10.689 8.90231 10.7242 8.86713L17.6709 1.92092C18.1101 1.48104 18.1096 0.768367 17.6698 0.329112C17.2299 -0.110143 16.5173 -0.109639 16.078 0.330237L9.13211 7.27345C9.09694 7.30871 9.04918 7.32853 8.99937 7.32853C8.94957 7.32853 8.90181 7.30871 8.86663 7.27345L1.92071 0.330237C1.48147 -0.109225 0.769163 -0.109393 0.329719 0.329862C-0.109726 0.769117 -0.109893 1.48146 0.329344 1.92092L7.27452 8.86713C7.30978 8.90231 7.3296 8.95007 7.3296 8.99988C7.3296 9.04969 7.30978 9.09745 7.27452 9.13262L0.329344 16.0796C0.045207 16.3639 -0.0656894 16.7781 0.0384278 17.1664C0.142545 17.5546 0.445858 17.8578 0.834112 17.9617C1.22237 18.0656 1.63657 17.9545 1.92071 17.6703L8.86663 10.7233C8.90181 10.688 8.94957 10.6682 8.99937 10.6682C9.04918 10.6682 9.09694 10.688 9.13211 10.7233L16.078 17.6703C16.5173 18.1097 17.2296 18.1099 17.669 17.6706C18.1085 17.2314 18.1086 16.519 17.6694 16.0796L10.7242 9.13262Z" fill="#8D7249"/>
        </SvgIcon> */
}
