import { useState,useEffect } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import './FormUpload.scss';
import api from '../../../../utility/common/api';
import { useNavigate } from "react-router-dom";



function FormUpload() {
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([
    {
      compName: "",
      userName: "",
      theDate: "",
      sector: "",
      subSector: "",
      fileUploaded: null,
      refName:null,
    },
  ]);
  const [sectorOptions,setSectorOptions]=useState([]);
  const [subSectorOptions, setSubSectorOptions]=useState([]);

  useEffect(()=>{
    getSector()
    getSubsector()

    
  },[])
  const getSector=()=>{
    const url='/api/sector';
    api.get({ url }).then(({ data }) => {
      if(data){
        setSectorOptions(Object.values(data.data))
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  const getSubsector=()=>{
    const url='/api/subsector';
    api.get({ url }).then(({ data }) => {
      if(data){
        setSubSectorOptions(Object.values(data.data))
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  // handle input change
  const handleInputChange = (element, index) => {
    const { name, value, files } = element.target;
    const newInputs = [...dataList];
    if (name === "fileUploaded") {
      const url = "/api/file";
      let data = new FormData();
      data.append("file",files[0]);
      api.post({ url, data }).then(({ data }) => {
          if (data) {
            newInputs[index]["refName"]=data.refName;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      newInputs[index][name] = files[0];
    }else if(name==="theDate"){
      newInputs[index][name] = value;
    }
     else {
      newInputs[index][name] = value;
    }
    setDataList(newInputs);
  };

  // handle click event of the Add button
  const handleAddInput = () => {
    setDataList([
      ...dataList,
      {
        compName: "",
        userName: "",
        theDate: "",
        sector: "",
        subSector: "",
        fileUploaded: "",
      },
    ]);
  };

  // handle click event of the Remove button
  const handleRemoveInput = (index) => {
    const newInputs = [...dataList];
    newInputs.splice(index, 1);
    setDataList(newInputs);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = [];
    const url="/api/bulkupload";
    dataList.forEach(el=>{
      let ob={};
      var date = new Date(el["theDate"]);  
      var longFormat = date*1; 

      ob["companyName"]=el["compName"];
      ob["userName"]=el["userName"];
      ob["date"]=longFormat;
      ob["sector"]=el["sector"];
      ob["subSector"]=el["subSector"];
      ob["refname"]=el["refName"];
      data.push(ob)

    })
    api.post({ url,data }).then(({ data }) => {
      if(data){
        navigate("/manager-dashboard")
      }
    }).catch(error=>{
            console.log(error)
        })
  };

  const canAddInput = dataList.every((input) =>
    Object.values(input).every((value) => value !== "")
  );

  const canRemoveInput = dataList.length > 1;

  return (
    <div className="main_form">
      <form className="form-style" onSubmit={handleSubmit}>
        {dataList.map((input, idx) => {
          return (
            <div key={idx} className="sub_form">
              <div>
                <label for="compName">Company Name: </label> <br />
                <input
                  type="text"
                  maxLength={150}
                  name="compName"
                  placeholder="Tesla"
                  value={input.compName}
                  onChange={(input) => handleInputChange(input, idx)}
                  required
                />{" "}
                <br />
              </div>
              <div>
                <label for="userName">Username: </label> <br />
                <input
                  type="text"
                  maxLength={150}
                  placeholder="T35LA"
                  name="userName"
                  required
                  value={input.userName}
                  onChange={(input) => handleInputChange(input, idx)}
                />
                <br />
              </div>
              <div>
                <label for="theDate">Date: </label> <br />
                <input
                  type="date"
                  name="theDate"
                  required
                  value={input.theDate}
                  onChange={(input) => handleInputChange(input, idx)}
                />
                <br />
              </div>
              <div>
                <label for="sector">Sector: </label> <br />
                <select
                  name="sector"
                  required
                  value={input.sector}
                  onChange={(input) => handleInputChange(input, idx)}
                >
                  <option value="">Select Sector</option>
                  {sectorOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <br />
              </div>
              <div>
                <label for="subSector">Sub-Sector: </label> <br />
                <select
                  name="subSector"
                  required
                  value={input.subSector}
                  onChange={(input) => handleInputChange(input, idx)}
                >
                  <option value="">Select Subsector</option>
                  {subSectorOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <br />
              </div>
              <div className="control_btn">
                <div className="uploadFile">
                  <label className="upload_btn">
                    Upload
                    <input
                      type="file"
                      id={`fileUploaded-${idx}`}
                      name="fileUploaded"
                      accept=".csv"
                      style={{ display: "none" }}
                      required
                      onChange={(input) => handleInputChange(input, idx)}
                    />
                  </label>
                  {/*Display the name of the uploaded file*/}
                  <span className="file_name">
                    {input.fileUploaded
                      ? input.fileUploaded.name
                      : "No file chosen"}
                  </span>
                </div>
                <div className="add_remove_btn">
                  <div>
                    {canRemoveInput && (
                      <DeleteForeverIcon
                        onClick={() => handleRemoveInput(idx)}
                        style={{
                          color: "#DA291C",
                          fontSize: "2.4rem",
                          cursor: "pointer",
                          marginRight: "1.2rem",
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {canAddInput && idx === dataList.length - 1 && (
                      <AddCircleIcon
                        onClick={handleAddInput}
                        style={{
                          fontSize: "2rem",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <button className="submit_btn">Submit</button>
      </form>
    </div>
  );
}

export default FormUpload;
