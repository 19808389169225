import React, { useState, useEffect } from "react";
import Table from "../../../domain-component/MasTable/Table";
import { Grid } from "@mui/material";
import './DocumentHistory.scss';
import api from '../../../utility/common/api';
import Search from '../../../domain-component/MasSearch/Search';
import { getDefaultOptions } from "date-fns";
import SearchIcon from "../../../assets/images/search-icon.png";
const getCreatedDate=(d)=>{
    const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
    return monthNames[d.getMonth()]+" "+d.getDate()+", "+d.getFullYear()
  }
const DocumentHistory=()=>{
    const [rows,setRows] = useState([]);
    const [dataCount,setDataCount]=useState(0);
    const colorProperties = {
        headCellsBackground:"#b7dde2",
        headCellsColor:"#53565A",
        overallBackground: "#E3E4E4",
        
    }
  
    const headCells = [
        {
          id: 'companyName',
          label: 'Company Name',
          disableSorting:true,
     
    
        },
        {
          id: 'userName',
          label: 'Username',
          disableSorting:true
    
        },
        {
          id: 'date',
          label: 'Date',
          disableSorting:true
    
        },
        {
          id: 'sector',
          label: 'Sector',
          disableSorting:true
    
        },
        {
          id: 'subSector',
          label: 'Sub Sector',
          disableSorting:true
    
        },
        {
          id: 'file',
          label: 'Download File',
          disableSorting:true
    
        }
    
    
      ];
    const [sectorOptions,setSectorOptions]=useState([]);
    const [subSectorOptions, setSubSectorOptions]=useState([]);
    const [companyName, setCompanyName]=useState([]);

    const [options, setOptions] = useState({
      companyName:  null,
      sector:  null,
      subSector:null,
    });

    useEffect(()=>{
      getSector();
      getSubsector();
      getCompanyName();
      const url = '/api/bulkupload?size=10&offSet=0';
      api.get({ url }).then(({ data }) => {
          if(data){
            setDataCount(data.total);
              var dataApprovals=[];
            
              if(data.data.length>0){
                  data.data.forEach(el=>{
                      dataApprovals.push(
                      createData(el.companyName, el.userName,el.sector,el.subSector,getCreatedDate(new Date(el.createdTime)),el.refname)
                      )
                  })
              }
              setRows(dataApprovals);
          }
          
      }).catch(error=>{
              console.log(error)
          })   
  },[]);
  const handlePageChange = (p) => {
    let data;
    if(Object.values(options).filter(el=> el!=null).length!=0){
      data = getOptionData()
    }
    const url = `/api/bulkupload/search?size=10&offSet=${p - 1}`;
    api.post({ url,data})
      .then(({ data }) => {
        if (data) {
          var dataApprovals=[];
            
          if(data.data.length>0){
              data.data.forEach(el=>{
                  dataApprovals.push(
                  createData(el.companyName, el.userName,el.sector,el.subSector,getCreatedDate(new Date(el.createdTime)),el.refname)
                  )
              })
          }
          setRows(dataApprovals);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSector=()=>{
    const url='/api/sector';
    api.get({ url }).then(({ data }) => {
      if(data){
        setSectorOptions(Object.values(data.data))
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  const getSubsector=()=>{
    const url='/api/subsector';
    api.get({ url }).then(({ data }) => {
      if(data){
        setSubSectorOptions(Object.values(data.data))
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  const getCompanyName=()=>{
    const url='/api/bulkupload/search?isPagation=false';
    api.post({ url }).then(({ data }) => {
      if(data){
        let cName=[]
        data.data.forEach((el)=>{
          if(!cName.includes(el.companyName)){
            cName.push(el.companyName)
          }
        })
        setCompanyName(cName)
      }
    }).catch((error) => {
      console.log(error);
    });
  }
    function createData(companyName,userName,sector,subSector,date,file ) {
    return {
        companyName,userName,sector,subSector,date,file
    };
    }

  
    const handleSetOptions = (area, value) => {
      setOptions({
        ...options,
        [area]: value
      });
    };
    const getOptionData=()=>{
      let data;
      if(Object.values(options).filter(el=> el!=null).length==1){
        Object.keys(options).forEach((el)=>{
          if(options[el]!=null){
            data={
              "type": "relational",
              "paramName": el,
              "value": options[el],
              "operator": "Equal"
              }
          }
        })
      }
      else{
        let qualSet=[];
        Object.keys(options).forEach((el)=>{
         
          if(options[el]!=null){
            let d={
              "type": "relational",
              "paramName": el,
              "value": options[el],
              "operator": "Equal"
              }
            qualSet.push(d)
          }
          
        })
        data ={
          "type": "batch",
          "qualSet": qualSet,
          "binaryOperator": "And"
          }
      };
      return data;
    }
    const onSearchButtonClick=()=>{
      let data=getOptionData();
      const url="/api/bulkupload/search?size=10&offSet=0";
      api.post({url,data}).then(({ data }) => {
        if(data){
            var dataApprovals=[];
            setDataCount(data.total);
            if(data.data.length>0){
                data.data.forEach(el=>{
                    dataApprovals.push(
                    createData(el.companyName, el.userName,el.sector,el.subSector,getCreatedDate(new Date(el.createdTime)),el.refname)
                    )
                })
            }
            setRows(dataApprovals);
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  
   
    return(
        <div className="doc-history">
          <div className="font2">Onboarding Data / Report / Peer Set Requests </div>
          <div className="search-elem">
            <Search optionSelected={options.companyName}
            setOptionSelected={(value) => handleSetOptions("companyName", value)}
             data={companyName} type={"SEARCH"} label={"Company Name"}/>
            <Search 
            optionSelected={options.sector}
            setOptionSelected={(value) => handleSetOptions("sector", value)}
            data={sectorOptions} type={"SECTOR"} label={"Sector"}/>
            <Search 
            optionSelected={options.subSector}
            setOptionSelected={(value) => handleSetOptions("subSector", value)}
             data={subSectorOptions} type={"SUBSECTOR"} label={"SubSector"}/>
            <img src={SearchIcon} className="search-img" onClick={onSearchButtonClick}></img>

            
          </div>
          <div className="filter-section2">
            <Grid
              container
              alignItems="flex-end"
              justifyContent={"left"}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Table
                headCells={headCells}
                rows={rows}
                link={true}
                rowCount={dataCount}
                stickyHeader="true"
                colorProperties={colorProperties} 
                showAllWhiteShade={false}
                isManagerDashBoard={true}
                returnPageNo={handlePageChange}
                showPagination={true}
                />
              </Grid>
            </Grid>
          </div>
        </div>
    )
}
export default DocumentHistory;